const moment = require('moment')

const mergeSystemFields = require('../../utils/schema/mergeSystemFields')

const discountParser = require('../modules/discountParser')


const schema = mergeSystemFields({
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      label: '訂單編號',
      counter: {
        key: () => `SOR${moment().format('YYMMDD')}`,
        value: (key, value) => `${key}${value.toString().padStart(3, 0)}`,
      },
    },
    date: { type: 'Date', label: '日期' },
    status: {
      type: 'String',
      label: '狀態',
      options: ['新增', '部份交貨', '完成', '取消'],
    },
    stafferRef: {
      type: 'ObjectId',
      label: '負責人',
      reference: 'staffers',
      refKeys: ['key'],
    },
    staffer: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String', label: '負責人姓名' },
        phone: { type: 'String', label: '負責人電話' },
        email: { type: 'String', label: '負責人 Email' },
      },
    },
    buyerRef: {
      type: 'ObjectId',
      label: '客戶',
      reference: 'customers',
      refKeys: ['key'],
      initFocus: true,
    },
    buyer: {
      type: 'Object',
      contents: {
        _id: { type: 'ObjectId' },
        key: { type: 'String', label: '客戶名稱' },
        contact: { type: 'String', label: '客戶聯絡人' },
        phone: { type: 'String', label: '客戶電話' },
        email: { type: 'String', label: '客戶 Email' },
        fax: { type: 'String', label: '客戶傳真' },
      },
    },
    pricebookRef: {
      type: 'ObjectId',
      reference: 'pricebooks',
      refKeys: ['key'],
      label: '使用價格表',
    },
    discount: {
      type: 'Number',
      label: '價格折扣',
      format: '0%',
      parser: discountParser,
    },
    note: { type: 'String', label: '備註' },
    custOrderNo: { type: 'String', label: '客戶採購單號' },
    amount: { type: 'Number', label: '總金額' },
    summary: { type: 'String', label: '總數量' },
    undeliveredQty: { type: 'Number', label: '總未出' },
    items: {
      type: 'Array',
      label: '項目',
      contents: [{ type: 'ObjectId', reference: 'salesReqItems' }],
    },
  },
})

module.exports = {
  title: '客戶訂購單',
  schema,
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
}
