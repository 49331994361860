import React from 'react'

class UploadTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
  }

  uploadFile () {
    console.log('upload', this.fileInput.files[0], this.select.value)
    fetch(
      `api/print/${this.select.value}/template/xlsx`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: this.fileInput.files[0],
      },
    )
      .then(response => (response.ok ? response : Promise.reject(response)))
      .then(() => {
        console.log('upload done')
      }).catch((response) => {
        console.log('upload error', response)
      })
  }

  handleSelect () {
    console.log('select', this.select.value)
  }

  render () {
    return (
      <div>
        {console.log('Render', this instanceof React.Component)}
        <select
          ref={(ref) => { this.select = ref }}
          onChange={this.handleSelect}
        >
          <option value=""> ---- choose ---- </option>
          <option value="salesReqForm">客戶訂購單</option>
          <option value="salesOrderForm">銷貨單</option>
          <option value="purchaseReqForm">採購單</option>
          <option value="purchaseOrderForm">進貨單</option>
          <option value="transferOrderForm">調貨單</option>
          <option value="recStatement">應收對帳單</option>
        </select>
        <br />
        <input
          ref={(ref) => { this.fileInput = ref }}
          type="file"
          id="upload_input"
          onChange={this.uploadFile}
        />
      </div>
    )
  }
}

export default UploadTemplate
