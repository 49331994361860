const R = require('ramda')

const schemaAt = require('coact/utils/schema/schemaAt')
const mergeDeep = require('coact/utils/mergeDeep')

const schemaModule = require('../../utils/schema')

const $purchaseReq = require('../purchaseReq')
const $arrivalProcessData = require('../arrivalProcess/dataAccess')


const schema = R.pipe(
  reqSchema => schemaAt(['items', 0], reqSchema),
  mergeDeep({
    contents: {
      unarrivedQty: $arrivalProcessData.schema.unarrivedQty,
      onTheWayQty: $arrivalProcessData.schema.onTheWayQty,
      stockInItemRefs: {
        type: 'Array',
        contents: [$arrivalProcessData.schema.stockInItemRef],
      },
    },
  }),
  schemaModule.create,
)($purchaseReq.reqData.schema)

const index = {
  unarrivedQty: {
    key: { unarrivedQty: 1 },
  },
}


module.exports = {
  schema,
  index,
}
