module.exports = {
  ui: {
    fields: [
      'invoice.yearmonth',
      'invoice.no',
      'invoice.date',
      'invoice.amount.total',
      'invoice.amount.taxSales',
      'invoice.amount.tax',
      'invoice.canceledAt',
      'invoice.buyer.id',
      'invoice.carrier.id1',
      'src',
      'meta.note',
    ],
  },
}
