const R = require('ramda')

const schemaModule = require('../../utils/schema')

const { reqData } = require('../purchaseReq')


const schema = R.pipe(
  R.dissocPath(['contents', 'items']),
  R.assocPath(
    ['contents', 'items'],
    {
      type: 'Array',
      contents: [{ type: 'ObjectId', reference: 'purchaseItems' }],
    },
  ),
  schemaModule.create,
)(reqData.schema)

const index = {
  items: {
    key: { items: 1 },
  },
  sellerRef: {
    key: { sellerRef: 1, status: 1 },
  },
}


module.exports = {
  schema,
  index,
}
