const R = require('ramda')

const schema = require('../schema')

const schemaOfCollection = R.pipe(
  R.assocPath(
    ['contents', 'items'],
    {
      type: 'Array',
      contents: [{ type: 'ObjectId', reference: 'retailOrderItems' }],
    },
  ),
)(schema.header)

const index = {
}


module.exports = {
  schema: schemaOfCollection,
  index,
}
