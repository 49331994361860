const R = require('ramda')

module.exports = function autoSelect ({ value }, ctx) {
  if (R.path(['pricebook', 'id'], value) && R.path(['warehouse', 'id'], value)) return

  const { $options, store } = ctx
  const { getState } = store

  const pricebookOptions = $options.select.options('pricebooks')(getState())
  const warehouseOptions = $options.select.options('warehouses')(getState())

  if (!pricebookOptions || !warehouseOptions) return

  const pricebookRef = R.find(R.propEq('key', '台幣建議售價'), pricebookOptions)
  const warehouseRef = R.find(R.propEq('key', 'HH'), warehouseOptions)

  if (pricebookRef && warehouseRef) {
    ctx.mergePath({
      path: [],
      value: { pricebook: { id: pricebookRef }, warehouse: { id: warehouseRef } },
    })
  }
}
