import { withState, withHandlers, compose } from 'coact/recompose'

import moment from 'coact/moment'

import View from './ShoplineStocks'


const upload = ({ file }) => async () => {
  try {
    const url = '/api/route/shoplineStocks'
    const response = await fetch(
      url,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        body: file,
      },
    )

    if (!response.ok) throw response

    return response.json()
  } catch (error) {
    // const json = await error.json()
    // throw new Error(json)
    throw error
  }
}


const download = () => (files) => {
  files.forEach((f, index) => {
    const elem = window.document.createElement('a')
    elem.href = `data:;base64,${f}`
    elem.download = `${moment().format('YYYYMMDD')}-${index + 1}.xlsx`
    document.body.appendChild(elem)
    elem.click()
    document.body.removeChild(elem)
  })
}

const ShoplineStocks = compose(
  withState('file', 'setFile', null),
  // withState('processId', 'setProcessId', null),
  withState('result', 'setResult', { }),
  withHandlers({
    onDropAccepted: ({ setFile }) => (files) => {
      setFile(files[0])
    },
    upload,
    download,
  }),
  withHandlers({
    // eslint-disable-next-line no-shadow
    go: ({ upload, download, setResult }) => async () => {
      const result = await upload()
      // console.log(result)
      setResult(result)
      if (result.files) download(result.files)
    },
  }),
)(View)

ShoplineStocks.displayName = 'ShoplineStocks'

export default ShoplineStocks
