import S from 'coact/saga/utils'

import * as R from 'coact/ramda'

import shallowEqual from 'coact/utils/shallowEqual'

import * as $editor from 'coact/store/editor'
import * as $message from 'coact/store/message'
import * as $editorSaga from 'coact/saga/editorSaga'
import pageAct from 'coact/pages/modules/pageAction/act'


import { act as $act } from '../components/StocksEditor'


const watch = (keys, a, b) => !shallowEqual(R.pick(keys, a), R.pick(keys, b))

const getColor = R.path(['spec', 'skuColorRef', 'key'])
const addDisplayColor = R.mapAccum(
  (aboveColor, sku) => {
    const color = getColor(sku)

    const displayColor = (color === aboveColor) ? null : color

    return [color, { ...sku, displayColor }]
  },
  null,
)

function* findStocks (payload) {
  const { value, prevValue } = payload
  const { productRef, warehouseRef } = value

  if (!productRef || !warehouseRef) return
  if (!watch(['productRef', 'warehouseRef'], value, prevValue)) return

  const api = yield S.getContext('api')
  const skus = yield S.call(
    api.get,
    {
      url: '/api/route/stocksEditor',
      search: {
        query: {
          productRef: productRef._id,
          warehouseRef: warehouseRef._id,
        },
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: ['stocks'],
    value: addDisplayColor(skus)[1],
  }))
}

function* saveStocks () {
  const { stocks, productRef, warehouseRef } = yield S.select($editor.select.value)

  const api = yield S.getContext('api')
  const skus = yield S.call(
    api.post,
    {
      url: '/api/route/stocksEditor',
      data: { stocks },
      search: {
        query: {
          productRef: productRef._id,
          warehouseRef: warehouseRef._id,
        },
      },
    },
  )

  yield S.put($message.act.send({ type: 'success', message: '庫存已修改' }))

  yield S.put($editorSaga.act.editPath({
    path: ['stocks'],
    value: addDisplayColor(skus)[1],
  }))
}

function* handleEffect ({ payload }) {
  const { name } = payload
  if (name === 'findStocks') yield S.call(findStocks, payload)
}

export default function* editStocksSaga () {
  yield S.takeEvery(pageAct.everyEffect, handleEffect)
  yield S.takeFirst($act.saveStocks, saveStocks)
}
