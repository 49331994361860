const buildSkuName = require('../../modules/buildSkuName')

const SalesReqEditor = require('./SalesReqEditor').default

const schema = require('./schema')
const autofill = require('./autofill')
const computer = require('./computer')
const watcherActionsEffect = require('./watcherActionsEffect')


module.exports = {
  title: '客戶訂購單',
  schema,
  edit: {
    creator: {
      date: '$$NOW',
      'items.0.qty': 1,
    },
    autofill,
    computer,
    ...watcherActionsEffect,
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date'],
          ['stafferRef', 'staffer.phone', 'staffer.email'],
          ['buyerRef', 'buyer.contact', 'buyer.phone', 'buyer.fax', 'buyer.email'],
          [{ path: 'note', span: 3, multiline: true }],
          ['amount', 'summary', 'undeliveredQty', 'pricebookRef', 'discount'],
        ],
      },
      productReader: {
        url: '/api/model/skus',
        queryKey: 'barcode',
        formatter: buildSkuName,
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        fields: [
          'skuRef', 'name',
          'qty', 'undeliveredQty',
          { path: 'currentStockQty', label: '現有', width: 50 },
          'price', 'subtotal', 'discountRate',
          { path: 'priceDoc.price', label: '表價', width: 70 },
          'retailPrice', 'note',
          { path: 'skuRef.barcode', label: '條碼', disabled: true },
        ],
        deletable: true,
        copyable: false,
      },
      Components: {
        PageForm: SalesReqEditor,
      },
    },
    disabled: {
      key: true,
      // status: true,
      amount: true,
      summary: true,
      undeliveredQty: true,
      'items.0.subtotal': true,
      'items.0.retailPrice': true,
      'items.0.undeliveredQty': true,
      'items.0.currentStockQty': true,
      'items.0.priceDoc.price': true,
    },
    // populations: '*',
    // populationdepth: '2',
  },
  list: {
    ui: {
      fields: [
        'key', 'date', 'stafferRef',
        'buyerRef', 'summary', 'undeliveredQty', 'amount', 'note',
      ],
    },
    populations: 'staffers,customers',
  },
}
