const R = require('ramda')

const assocPath = require('coact/utils/assocPath')

const invoiceSchema = require('~/src/invoice/data/invoices/schema')

const mergeClientRequiredChanges = require('../../../utils/schema/mergeClientRequiredChanges')

const salesOrders = require('../salesOrders')
const salesOrderItems = require('../salesOrderItems')
const receivables = require('../../receivables')


const itemSchema = mergeClientRequiredChanges(salesOrderItems.schema)
const paymentSchema = mergeClientRequiredChanges(receivables.schema)

const schema = R.pipe(
  assocPath(['contents', 'items', 'contents', 0], itemSchema),
  assocPath(['contents', 'payments', 'contents', 0], paymentSchema),
  assocPath(['contents', 'action'], { type: 'String' }), // WORKAROUND for confirmPayments
  assocPath(['contents', 'invoices', 'contents', 0], invoiceSchema),
  assocPath(['contents', 'issueAmount'], { type: 'Number', label: '此次開立金額' }), // for issueInvoice
)(mergeClientRequiredChanges(salesOrders.schema))


module.exports = schema
