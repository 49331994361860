const R = require('ramda')

const schema = require('../schema')

const index = {
}

const schemaOfCollection = R.pipe(
  R.assocPath(
    ['contents', 'order'],
    {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '零售單', reference: 'retailOrders', refKeys: ['key'] },
        key: { type: 'String', label: '零售單號' },
      },
    },
  ),
)(schema.item)

module.exports = {
  schema: schemaOfCollection,
  index,
}
