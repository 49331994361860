const mergeConfig = require('coact/config/mergeConfig')

const ArrivalProcessPage = require('./ArrivalProcessPage')

const config = {
  routes: {
    arrivalProcess: {
      title: '採購進貨處理',
      path: '/route/arrivalProcess',
      component: ArrivalProcessPage,
    },
  },
  sagas: {
    arrivalProcessSaga: require('./saga').default,
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
