module.exports = {
  title: '價格表資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        type: 'String',
        unique: true,
        required: true,
        name: '價格表名稱',
        ragicField: 1000026,
      },
      '@order': {
        type: 'Number',
        name: '排序',
      },
      currency: {
        type: 'String',
        name: '幣別',
        options: ['TWD', 'EUR', 'USD', 'RMB'],
        ragicField: 1000029,
      },
      buyOrSale: {
        type: 'String',
        name: '進或售',
        // required: true,
        options: ['進價', '售價'],
      },
      multiplier: {
        type: 'Number',
        name: '乘數',
      },
      note: {
        type: 'String',
        input: 'textarea',
        name: '價格表說明',
        ragicField: 1000027,
      },
      syncAt: {
        name: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        name: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 120 },
        { path: 'currency' },
        { path: 'multiplier' },
        { path: 'buyOrSale' },
        { path: '@order' },
      ],
    },
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['key'],
          ['@order', 'currency'],
          ['multiplier', 'buyOrSale'],
          ['note'],
        ],
      },
    },
  },
}
