const R = require('ramda')

function go (acc, node) {
  acc.current.path.push(node)
  acc.current.render.push(node)
}

function back (acc) {
  if (acc.current.render.length > 0) {
    acc.rows.push(acc.current)
  }

  acc.current = {
    path: R.dropLast(1, acc.current.path),
    render: [],
  }
}

function goStock (acc, stock) {
  const node = { stock }
  go(acc, node)

  // no next

  back(acc)
}

function goColor (acc, color) {
  const node = { color }
  go(acc, node)

  R.forEach(
    stock => goStock(acc, stock),
    color.stocks || [],
  )

  // no next
  back(acc)
}

function goProduct (acc, product) {
  const node = { product }
  go(acc, node)

  R.forEach(
    color => goColor(acc, color),
    product.colors || [],
  )

  // no next
  back(acc)
}


module.exports = function buildPropsOfRows (spec) {
  // const { product, showModal, hideEmpty, showStockFlags } = props
  const { product } = spec

  const acc = { rows: [], current: { path: [], render: [] } }
  goProduct(acc, product)

  return R.map(
    row => ({
      path: R.mergeAll(row.path),
      render: R.mergeAll(row.render),
    }),
    acc.rows,
  )
}
