const schema = {
  type: 'Object',
  contents: {
    name: { type: 'String', label: '姓名' },
    phone: { type: 'String', label: '電話' },
    mobileBarcode: { type: 'String', label: '手機載具號碼' },
    email: { type: 'String', label: 'Email' },
    birthday: { type: 'Date', label: '生日', format: 'YYYY/MM/DD' },
    secPhone: { type: 'String', label: '備用電話' },
  },
}

module.exports = schema
