const mergeSystemFields = require('../../utils/schema/mergeSystemFields')

const discountParser = require('../modules/discountParser')


const schema = mergeSystemFields({
  type: 'Object',
  contents: {
    orderRef: {
      type: 'ObjectId',
      reference: 'salesReqs',
      view: 'main',
      refKeys: ['key'],
      label: '訂購單號',
    },
    skuRef: {
      type: 'ObjectId',
      reference: 'skus',
      refKeys: ['key'],
      label: '單品',
      width: 350,
    },
    sku: {
      type: 'Object',
      contents: {
        key: { type: 'String' },
        barcode: { type: 'String' },
      },
    },
    name: { type: 'String', label: '名稱', width: 350 },
    qty: { type: 'Number', label: '數量', width: 50 },
    undeliveredQty: { type: 'Number', label: '未出', width: 50 },
    price: { type: 'Number', label: '單價', width: 70 },
    subtotal: { type: 'Number', label: '小計', width: 70 },
    note: { type: 'String', label: '項目備註' },
    retailPrice: { type: 'Number', label: '建議售價', width: 70 },
    discountRate: {
      type: 'Number', label: '折扣', format: '0%', width: 70, parser: discountParser,
    },
    priceDoc: { type: 'Object' },
  },
})

module.exports = {
  title: '客戶訂購項目',
  schema,
  list: {
    ui: {
      fields: [
        'orderRef',
        // { path: 'orderRef.key', model: 'salesReqs', queryPath: 'orderRef' },
        { path: 'orderRef.date', model: 'salesReqs', queryPath: 'orderRef' },
        { path: 'orderRef.buyerRef', model: 'salesReqs', queryPath: 'orderRef' },
        'skuRef', 'name', 'qty', 'undeliveredQty', 'price', 'subtotal', 'note',
      ],
    },
    populations: 'salesReqs,skus,customers',
    populationdepth: 2,
  },
  options: {
    customers: { },
  },
  export: {
    populations: 'salesReqs,skus,customers,products,pdtSeries,pdtTypes,skuSizes,skuColors',
    populationdepth: 3,
    fields: [
      '_id',
      'orderRef.key',
      'orderRef.date',
      'orderRef.buyerRef.key',
      'skuRef.key',
      'skuRef.productRef.key',
      'skuRef.productRef.pdtSerieRef.key',
      'skuRef.productRef.pdtTypeRef.key',
      'skuRef.spec.skuSizeRef.key',
      'skuRef.spec.skuColorRef.key',
      'name',
      'qty',
      'undeliveredQty',
      'price',
      'subtotal',
      'note',
    ],
  },
}
