const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const exportPriceStock = require('./exportPriceStock')

const Editor = require('./Editor').default


const clientConfig = {
  title: '商品資料',
  data: {
    bundle: {
      skus: {
        path: ['skus'],
        quantity: 'many',
      },
      prices: {
        path: ['prices'],
        quantity: 'many',
      },
    },
  },
  viewSchema: {
    contents: {
      addSizeSetRef: {
        name: '新增尺寸組合',
        type: 'ObjectId',
        reference: 'sizeSets',
        refKeys: ['key'],
        formatter: (set) => {
          if (!set) return set

          return `${set.key} - ${set.skuSizeRefs.map(s => s.key).join(', ')}`
        },
      },
      addSkuColorRefs: {
        name: '新增色碼',
        type: 'Array',
        contents: [{
          type: 'ObjectId',
          reference: 'skuColors',
          refKeys: ['key', 'name'],
        }],
      },
      addSkuSizeRefs: {
        name: '新增尺寸',
        type: 'Array',
        contents: [{
          type: 'ObjectId',
          reference: 'skuSizes',
          refKeys: ['key'],
        }],
      },
      virtualSkuSizeRefs: {
        name: '新增尺寸',
        type: 'Array',
        contents: [{
          type: 'ObjectId',
          reference: 'skuSizes',
          refKeys: ['key'],
        }],
      },
      specs: {
        type: 'Object',
        contents: {
          skuColorRefs: {
            name: '原始色碼',
            type: 'Array',
            disabled: true,
            contents: [{
              type: 'ObjectId',
              reference: 'skuColors',
              refKeys: ['key', 'name'],
            }],
          },
          skuSizeRefs: {
            name: '原始尺寸',
            type: 'Array',
            disabled: true,
            contents: [{
              type: 'ObjectId',
              reference: 'skuSizes',
              refKeys: ['key'],
            }],
          },
        },
      },
      pricesByPricebooks: {
        type: 'Object',
        contents: {
          sales: {
            type: 'Array',
            contents: [{
              type: 'Object',
              contents: {
                _id: { type: 'ObjectId' },
                key: { label: '售價', disabled: true },
                multiplier: { label: '乘數', disabled: true },
                price: { label: '價格', type: 'Number' },
              },
            }],
          },
          buys: {
            type: 'Array',
            contents: [{
              type: 'Object',
              contents: {
                _id: { type: 'ObjectId' },
                key: { label: '進價', disabled: true },
                multiplier: { label: '乘數', disabled: true },
                price: { label: '價格', type: 'Number' },
              },
            }],
          },
        },
      },
    },
    computer: [
      ({ formattedKey }) => ({
        key: formattedKey && formattedKey.replace(/\s/g, ''),
      }),
      ({ addSizeSetRef, addSkuSizeRefs }) => {
        if (!addSizeSetRef) return { addSkuSizeRefs }
        return {
          addSkuSizeRefs: R.uniqWith(R.eqProps('key'))([
            ...addSkuSizeRefs,
            ...addSizeSetRef.skuSizeRefs,
          ]),
          addSizeSetRef: null,
        }
      },
    ],
  },
  edit: {
    effect: {
      countPrice: (payload, ctx) => {
        const { doc: { basePrice, pricesByPricebooks: { buys, sales } }, mergePath } = ctx
        const countPrice = price => ({
          ...price,
          price: (price.multiplier && basePrice)
            ? Math.round(price.multiplier * basePrice)
            : price.price,
        })
        mergePath({ path: 'pricesByPricebooks.buys', value: buys.map(countPrice) })
        mergePath({ path: 'pricesByPricebooks.sales', value: sales.map(countPrice) })
      },
    },
    Components: {
      PageForm: Editor,
    },
    noclone: {
      'specs.skuSizeRefs': true,
      'specs.skuColorRefs': true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 210 },
        { path: 'name', width: 300 },
        { path: 'pdtSerieRef', width: 150 },
        { path: 'pdtTypeRef', width: 120 },
        { path: 'circulationLevel' },
        { path: 'weightRatio' },
      ],
    },
    effect: {
      exportPriceStock,
    },
    actions: {
      exportPriceStock: {
        text: '匯出價格庫存至 Sheets',
        effect: { run: 'first', name: 'exportPriceStock' },
        props: { variant: 'contained', color: 'primary' },
        authChecker: (viewAuth, userAuth) => (
          R.path(['authority', 'routes', 'exportPriceStock', 'get'], userAuth)
        ),
      },
    },
  },
}

const config = {
  models: {
    products: {
      views: {
        main: clientConfig,
      },
    },
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
