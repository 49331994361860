module.exports = function setup (deps) {
  const my = { }
  const that = { }

  const privateMethod = (name) => {
    // eslint-disable-next-line import/no-dynamic-require
    const method = require(`./${name}`)(deps, my)
    my[name] = method
  }
  const publicMethod = (name) => {
    // eslint-disable-next-line import/no-dynamic-require
    const method = require(`./${name}`)(deps, my)
    my[name] = method
    that[name] = method
  }

  privateMethod('checkUniqueFields')

  publicMethod('insertWithPhoneOrMBarcode')
  publicMethod('updateNullFields')

  return that
}
