const R = require('ramda')

const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const buildSkuName = require('~/src/models/modules/buildSkuName')

const mobileBarcodeValidator = require('~/src/invoice/twInvoice/validator/mobileBarcode')
const { validator: { mobilePhone: mobilePhoneValidator } } = require('~/src/consumers/')

const findProductPrice = require('./findProductPrice')
const findConsumer = require('./findConsumer')
const autoSelect = require('./autoSelect')
const invalidInvoice = require('./invalidInvoice')
const saveAndPrintInvoice = require('./saveAndPrintInvoice')
const saveAndPrintInvoiceOnPos = require('./saveAndPrintInvoiceOnPos')
const shareAmount = require('./shareAmount')
const Cashier = require('./Cashier').default

module.exports = {
  autofill: {
    pricebook: {
      src: 'id',
      fill: ({ key }) => ({ key }),
    },
    warehouse: {
      src: 'id',
      fill: ({ key, name }) => ({ key, name }),
    },
    '': [
      {
        src: 'consumer.id',
        fill: (doc) => {
          if (R.isNil(doc)) {
            return { mobileBarcode: null, consumer: { name: null, phone: null } }
          }

          const { name, phone, mobileBarcode } = doc
          return { mobileBarcode, consumer: { name, phone } }
        },
      },
    ],
    // consumer: {
    //   src: 'id',
    //   fill: ({ name, phone, mobileBarcode }) => ({ name, phone, mobileBarcode }),
    // },
    'items.0': {
      src: 'sku.id',
      fill: sku => ({
        sku,
        name: buildSkuName(sku),
      }),
    },
  },
  computer: {
    '': [
      (value) => {
        const { items } = value
        if (R.isNil(items)) return { }

        const total = R.reduce(
          (acc, { subtotal }) => (acc + subtotal),
          0,
          items,
        )

        const discount = value.discount || 0

        const subtotals = R.map(R.propOr(0, 'subtotal'), items)
        const shares = shareAmount(discount, subtotals)

        const subAmounts = R.addIndex(R.map)(
          ({ subtotal }, index) => ({ amount: subtotal - shares[index] }),
          items,
        )

        return { amount: total - discount, items: subAmounts }
      },
      (value) => {
        const total = R.reduce(
          (acc, { qty }) => acc + qty,
          0,
          value.items || [],
        )
        return { summary: `共 ${total} 個` }
      },
    ],
    'items.0': (value) => {
      const qty = value.qty || 0
      const price = value.price || 0
      const discountRate = value.discountRate || 1

      return { subtotal: Math.round(qty * price * discountRate) }
    },
  },
  watcher: {
    '': { name: 'autoSelect' },
    'consumer.phone': { name: 'findConsumerByPhone' },
    mobileBarcode: { name: 'findConsumerByBarcode' },
    'items.0.sku.id': { name: 'findProductPrice' },
  },
  creator: {
    date: '$$NOW',
    status: '新增',
    'items.0.qty': 1,
    'items.0.discountRate': 1,
  },
  validator: {
    mobileBarcode: mobileBarcodeValidator,
    'consumer.phone': (value) => {
      return mobilePhoneValidator(value)
    },
    discount: (value) => {
      if (Number.isInteger(value)) return null

      return '整單折扣為折扣一個整數金額'
    },
  },
  required: {
    'warehouse.id': true,
    'pricebook.id': true,
    payType: true,
    'items.0.qty': true,
    'items.0.price': true,
    'items.0.name': true,
    'items.0.amount': true,
  },
  disabled: {
    key: true,
    date: true,
    // status: true,
    summary: true,
    amount: true,
    // 'consumer.id': true,
    'warehouse.id': true,
    'pricebook.id': true,
    'items.0.bookPrice.price': true,
    'items.0.subtotal': true,
    'items.0.amount': true,
  },
  actions: {
    saveAndCreate: {
      authChecker: () => null,
    },
    saveAndCopy: {
      authChecker: () => null,
    },
  },
  effect: {
    autoSelect,
    findProductPrice,
    findConsumerByPhone: function* findConsumerByPhone (payload, ctx) {
      if (R.path(['consumer', 'id'], ctx.getDoc())) return
      if (mobilePhoneValidator(payload.value) === null) {
        yield ctx.S.call(findConsumer, { key: 'phone', value: payload.value }, ctx)
      }
    },
    findConsumerByBarcode: function* findConsumerByBarcode (payload, ctx) {
      if (R.path(['consumer', 'id'], ctx.getDoc())) return
      if (mobileBarcodeValidator(payload.value) === null) {
        yield ctx.S.call(findConsumer, { key: 'mobileBarcode', value: payload.value }, ctx)
      }
    },
    saveAndPrintInvoice,
    saveAndPrintInvoiceOnPos,
    invalidInvoice: function* invalidInvoiceEffect (payload, ctx) {
      yield ctx.S.call(invalidInvoice, payload.id)
      yield ctx.S.call(editEffects.save)
    },
  },
  ui: {
    head: {
      name: 'formEditor',
      fields: [
        ['key', 'date', 'status', 'salesPerson', 'pricebook.id', 'warehouse.id'],
        [{ path: 'note', span: 2 }, 'summary', 'amount', 'discount'],
        [{ path: 'consumer.id', span: 2 }, 'consumer.phone', 'mobileBarcode', 'consumer.name'],
        ['citizenBarcode', 'donation', 'buyerId'],
      ],
    },
    productReader: {
      url: '/api/route/querySku',
      queryKey: 'barcode',
      formatter: buildSkuName,
      initFocus: true,
      getQueryCode: c => c.toUpperCase(),
      buildItem: (spec) => {
        const { code, sku, nextQty } = spec

        const item = {
          barcodeOnObject: code,
          qty: nextQty,
          discountRate: 1,
        }
        if (sku) item.sku = { id: sku }

        return item
      },
      eqSku: () => false,
    },
    items: {
      name: 'tableEditor',
      path: 'items',
      fields: [
        { path: 'barcodeOnObject', width: 300 },
        { path: 'sku.id', width: 350 },
        { path: 'name', width: 350 },
        { path: 'qty', width: 50 },
        { path: 'price', width: 70 },
        { path: 'discountRate', width: 70 },
        { path: 'bookPrice.price', width: 70 },
        { path: 'subtotal', width: 70 },
        { path: 'amount', width: 70 },
      ],
      deletable: true,
      copyable: false,
    },
    invoices: {
      name: 'tableEditor',
      path: 'invoices',
      fields: [
        { path: 'invoice.yearmonth', label: '期別', width: 50 },
        { path: 'invoice.no', label: '號碼', width: 100 },
        { path: 'invoice.date', label: '日期', width: 200 },
        { path: 'invoice.amount.total', label: '金額', width: 70 },
      ],
      deletable: false,
      copyable: false,
      appendEmpty: false,
    },
  },
  Components: {
    PageForm: Cashier,
    PageActions: () => null,
  },
}
