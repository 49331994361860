module.exports = {
  title: '尺寸組合資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        name: '組合名稱',
        type: 'String',
        unique: true,
        required: true,
      },
      skuSizeRefs: {
        name: '包含尺寸',
        type: 'Array',
        input: 'select',
        multi: true,
        contents: [{
          type: 'ObjectId',
          reference: 'skuSizes',
          refKeys: ['key'],
        }],
      },
      sortOrder: {
        name: '排序',
        type: 'Number',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key' },
        { path: 'sortOrder' },
      ],
    },
  },
  ui: {
    editor: {
      config: { nameWidth: 120 },
      groups: [
        {
          name: '',
          rows: [
            [{ key: 'key' }, { key: 'sortOrder' }],
            [{ key: 'skuSizeRefs' }],
          ],
        },
      ],
    },
  },
}
