module.exports = function reqDataInterface (impl) {
  async function getId (spec) {
    return impl.getId(spec)
  }
  function eqIds (a, b) {
    return impl.eqIds(a, b)
  }

  async function insertOrder (orderId, orderDoc) {
    return impl.insertOrder(orderId, orderDoc)
  }
  async function updateOrder (orderId, orderDoc) {
    return impl.updateOrder(orderId, orderDoc)
  }

  async function insertItem (itemId, itemDoc) {
    return impl.insertItem(itemId, itemDoc)
  }
  async function updateItem (itemId, itemDoc) {
    return impl.updateItem(itemId, itemDoc)
  }
  async function removeItem (itemId, itemDoc) {
    return impl.removeItem(itemId, itemDoc)
  }

  async function assocItemIds (orderId, itemIds) {
    return impl.assocItemIds(orderId, itemIds)
  }
  async function concatItemIds (orderId, itemIds) {
    return impl.concatItemIds(orderId, itemIds)
  }

  async function getOrder (orderId) {
    return impl.getOrder(orderId)
  }
  async function getOrderAndItems (orderId) {
    return impl.getOrderAndItems(orderId)
  }
  async function getItemDocs (itemIds) {
    return impl.getItemDocs(itemIds)
  }
  async function getLinkedItemIds (orderId) {
    return impl.getLinkedItemIds(orderId)
  }
  async function sumQtyByStatus (itemIds) {
    return impl.sumQtyByStatus(itemIds)
  }

  // TODO use supplier entity
  async function getSupplierById (orderId) {
    return impl.getSupplierById(orderId)
  }


  return {
    getId,
    eqIds,

    insertOrder,
    updateOrder,

    insertItem,
    updateItem,
    removeItem,

    assocItemIds,
    concatItemIds,

    getOrder,
    getOrderAndItems,
    getItemDocs,
    getLinkedItemIds,
    sumQtyByStatus,
    getSupplierById,
  }
}
