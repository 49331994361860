module.exports = async function findConsumer ({ key, value }, ctx) {
  // console.log('findConsumer', value, mobilePhoneValidator(value))

  const { api } = ctx
  const { data: consumers } = await api.get({
    url: api.url('consumers'),
    search: {
      query: { [key]: value },
      populations: 'consumers',
      populationdepth: 1,
    },
  })
  const counts = consumers.length
  if (counts === 0) {
    ctx.sendMessage({ type: 'info', message: `${key}: ${value} 查無消費者：儲存後新增` })

    ctx.mergePath({ path: ['consumer'], value: { id: null } })
  } else if (counts === 1) {
    const consumer = consumers[0]
    ctx.sendMessage({ type: 'success', message: `連結消費者：${consumer.phone}` })

    ctx.editPath({ path: ['consumer'], value: { id: consumer, ...consumer } })
    ctx.editPath({ path: ['mobileBarcode'], value: consumer.mobileBarcode })
  } else if (counts > 1) {
    ctx.sendMessage({ type: 'error', message: '找到不只一位消費者' })
  }
}
