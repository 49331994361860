const R = require('ramda')

const invoiceSchema = require('~/src/invoice/data/invoices/schema')

const schema = require('../retailOrder/schema')


const viewSchema = R.pipe(
  R.assocPath(
    ['contents', 'items'],
    {
      type: 'Array',
      contents: [schema.item],
    },
  ),
  R.assocPath(
    ['contents', 'invoices'],
    {
      type: 'Array',
      contents: [invoiceSchema],
    },
  ),
)(schema.header)


module.exports = viewSchema
