const { compose, withState, withHandlers, lifecycle } = require('recompose')

const { connect } = require('react-redux')
const { get } = require('coact/utils/api')

const $message = require('coact/store/message')

const R = require('ramda')


function countSizeMap (oriSizes) {
  const fullMapData = [
    [null],
    ['UNI'],
    ['3XS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL'],
    ['EU36', 'EU37', 'EU38', 'EU39', 'EU40', 'EU41', 'EU42', 'EU43', 'EU44', 'EU45', 'EU46'],
    ['3/4Y', '4/5Y', '5/6Y', '6/7Y', '7/8Y', '8/9Y', '9/10Y', '10/11Y', '11/12Y',
      '12/13Y', '13/14Y'],
    ['26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
    ['26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46'],
    ['70mm', '80mm', '90mm', '100mm', '110mm', '120mm'],
    ['145mm', '155mm', '160mm', '165mm'],
    [null, null, null, 'JS', 'JM', 'JL'],
  ]
  const fullMap = R.transpose(fullMapData)
  const sizes = R.uniq(oriSizes || [null])
  const sizeHead = new Array(12)
  const noPosAssignedSizes = []
  sizes.forEach((s) => {
    const pos = fullMap.findIndex(map => (map.includes(s) || map.includes(s.split('/')[0])))
    if (pos === -1) noPosAssignedSizes.push(s)
    else sizeHead[pos] = s
  })
  noPosAssignedSizes.forEach((s) => {
    const pos = sizeHead.findIndex(e => R.isNil(e))
    if (pos === -1) throw new Error('沒位置放 size 啦')
    sizeHead[pos] = s
  })
  const result = {}
  sizeHead.forEach((s, i) => {
    if (s) result[i] = s
  })
  return result
}


const StocksList = compose(
  connect(),

  withState('seriesData', 'setSeriesData', []),
  withState('productsData', 'setProductsData', []),
  withState('stocksData', 'setStocksData', []),
  withState('curSeries', 'setCurSeries', '全部系列'),
  withState('search', 'setSearch', ''),
  withState('hideEmpty', 'setHideEmpty', false),
  withState('sortBy', 'setSortBy', null),
  withState('isLoading', 'setIsLoading', true),

  withHandlers({
    setSortBy: ({ setSortBy, setStocksData }) => (sortBy) => {
      setSortBy(sortBy)
      setStocksData(data => R.sortBy(R.prop(sortBy))(data))
    },
  }),

  withHandlers({
    getStocks: props => async (query) => {
      const {
        setStocksData,
        setSortBy,
        setSearch,
        setIsLoading,
        dispatch,
      } = props
      if (!query || R.isEmpty(query) || (!query.products && !query.series)) return setStocksData([])

      setIsLoading(true)

      try {
        const { data } = await get({
          url: '/api/route/stockState',
          search: {
            query,
          },
        })

        // console.log(data)

        const stocksData = R.map(
          product => ({
            ...product,
            sizeMap: countSizeMap(product.sizeSet),
          }),
          data,
        )

        setStocksData(stocksData)
        setSearch('')
        setSortBy('type')
      } catch (response) {
        console.log(response)
        dispatch($message.act.send({ type: 'error', message: response || '讀取失敗' }))
      }

      return setIsLoading(false)
    },
  }),

  lifecycle({
    async componentDidMount () {
      const { setSeriesData, setProductsData, setIsLoading, dispatch } = this.props
      try {
        const { pdtSeries: series, products } = await get({ url: '/api/route/publicProducts' })
        // const mergeSeries = series.filter(s => s.mergeInPublicStock).map(s => s._id)

        setSeriesData(
          [
            { key: '全部系列', hideEmpty: false },
            ...series.filter(s => !s.mergeInPublicStock).map(s => ({
              ...s,
              // products: products.filter(p => p.pdtSerieId === s._id).map(p => p._id),
              series: [s._id],
              hideEmpty: false,
            })),
            {
              key: '清倉商品',
              // products: products.filter(p => mergeSeries.includes(p.pdtSerieId)).map(p => p._id),
              series: series.filter(s => s.mergeInPublicStock).map(s => s._id),
              hideEmpty: true,
            },
          ],
        )
        setProductsData(products)

        setIsLoading(false)
      } catch (response) {
        console.log(response)
        setIsLoading(false)
        dispatch($message.act.send({ type: 'error', message: '讀取失敗' }))
      }
    },
  }),
)

module.exports = StocksList
