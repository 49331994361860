const mergeConfig = require('coact/config/mergeConfig')

const clientConfig = {
  title: '單品資料',
  list: {
    ui: {
      fields: [
        { path: 'key', width: 200 },
        { path: ['spec', 'skuSizeRef'] },
        { path: ['spec', 'skuColorRef'] },
        { path: 'barcode', width: 200 },
        { path: 'productRef', width: 200 },
      ],
    },
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['key', 'barcode', '@suspended'],
          ['productRef'],
          ['spec.skuSizeRef', 'spec.skuColorRef'],
        ],
      },
    },
  },
}

const config = {
  models: {
    skus: {
      views: {
        main: clientConfig,
      },
    },
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
