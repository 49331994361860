const R = require('ramda')

const safeN = require('coact/utils/safeN')

module.exports = {
  '': [
    (value) => {
      const amount = R.reduce(
        (acc, { subtotal }) => (acc + subtotal),
        0,
        value.items || [],
      )
      return { amount }
    },
    (value) => {
      const total = R.reduce(
        (acc, { qty }) => acc + qty,
        0,
        value.items || [],
      )
      return { summary: `共 ${total} 個` }
    },
    (value) => {
      const amount = value.amount || 0
      const shippingCost = value.shippingCost || 0
      const customsDuty = value.customsDuty || 0
      return { payable: amount + shippingCost + customsDuty }
    },
  ],
  'items.0': (value) => {
    const qty = safeN(value.qty)
    const price = safeN(value.price)

    return { subtotal: qty * price }
  },
}
