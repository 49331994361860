import withHandlers from 'recompose/withHandlers'

import createEffectAction from 'coact/pages/modules/pageAction/createEffectAction'

import View from './PageForm'


const PageForm = withHandlers({
  setPrice: ({ dispatch }) => () => {
    dispatch(createEffectAction({ run: 'every', name: 'countPrice' }))
  },
})(View)

export default PageForm
