const R = require('coact/ramda')

module.exports = {
  title: '進出庫資料',
  schema: {
    type: 'Object',
    contents: {
      stockRef: {
        name: '庫存品項',
        type: 'ObjectId',
        reference: 'stocks',
        required: true,
        formatter: (stock) => {
          const sku = R.pathOr('', ['skuRef', 'key'], stock)
          const warehouse = R.pathOr('', ['warehouseRef', 'key'], stock)

          return `${sku}@${warehouse}`
        },
      },
      content: { name: '內容', type: 'String' },
      date: { name: '時間', type: 'Date', required: true },
      qty: { name: '庫存變化量', type: 'Number', required: true },
      srcDBRef: {
        type: 'DBRef',
        label: '來源',
        formatter: (doc) => {
          if (!doc) return ''
          const key1 = R.path(['$id', 'orderRef', 'key'], doc)
          const key2 = R.path(['$id', 'order', 'id', 'key'], doc)
          return `${key1 || key2}`
        },
      },
      syncAt: {
        name: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        name: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        stockRef: 1,
      },
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'stockRef', width: 200 },
        { path: 'date', width: 150 },
        { path: 'qty' },
      ],
    },
  },
  export: {
    // populations: 'stocks,skus,warehouses,pdtSeries,pdtTypes,skuSizes,skuColors',
    populations: '*',
    populationdepth: 2,
    fields: [
      '_id',
      'date',
      'content',
      'stockRef.skuRef.key',
      'stockRef.warehouseRef.key',
      'qty',
      'srcDBRef.$id.orderRef.key',
    ],
  },
}
