const R = require('ramda')
const RA = require('ramda-adjunct')

module.exports = ({ data }) => async function complementDoc (doc) {
  let result = doc
  if (!R.isNil(doc.sellerRef)) {
    const supplierDoc = await data.getSupplierById(doc.sellerRef)

    const paths = ['key', 'contact', 'phone', 'email', 'fax', 'address']
    const values = R.map(
      (key) => {
        if (RA.isNilOrEmpty(R.path(['seller', key], result))) return supplierDoc[key]
        return result.seller[key]
      },
      paths,
    )

    result = R.mergeDeepRight(
      doc,
      { seller: R.zipObj(paths, values) },
    )
  }

  return result
}
