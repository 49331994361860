const mergeConfig = require('coact/config/mergeConfig')

const retailOrders = require('./retailOrders')
const retailOrderItems = require('./retailOrderItems')


const config = {
  models: {
    retailOrders,
    retailOrderItems,
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
