const R = require('ramda')
const schemaAt = require('coact/utils/schema/schemaAt')

const schemaModule = require('../../../../utils/schema')

const { dataAccess } = require('../../../purchaseOrder')


// for populate schema
const orderSchema = R.pipe(
  R.dissocPath(['contents', 'items']),
  schemaModule.create,
)(dataAccess.schema)

const schema = R.pipe(
  R.assocPath(['contents', 'orderRef'], orderSchema),
  schemaModule.create,
)(schemaAt(['items', 0], dataAccess.schema)) // itemSchema

module.exports = schema
