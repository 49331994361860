const R = require('ramda')
const createTempId = require('coact/utils/schema/createTempId')

module.exports = function createProductReceivable (doc) {
  return {
    _id: R.path(['payments', 0, '_id'], doc) || createTempId(),
    date: doc.date,
    account: '銷貨收入',
    amount: doc.amount,
    content: '商品總計',
  }
}
