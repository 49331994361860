const buildQueryString = require('coact/utils/api/buildQueryString')

const printExcel = (spec) => {
  const { doc, filename, template } = spec
  const queryStirng = buildQueryString({ id: doc._id, fullname: filename })
  const url = `/api/print/${template}/xlsx?${queryStirng}`
  window.open(url)
}

module.exports = printExcel
