const R = require('ramda')

const safeN = require('coact/utils/safeN')

module.exports = {
  '': [
    (value) => {
      const amount = R.reduce(
        (acc, { subtotal }) => (acc + subtotal),
        0,
        value.items || [],
      )
      return { amount }
    },
  ],
  'items.0': (value) => {
    const qty = safeN(value.qty)
    const price = safeN(value.price)

    return { subtotal: qty * price }
  },
}
