const R = require('ramda')

const fetchSkuPrices = require('./fetchSkuPrices')

module.exports = async function fetchProductPrice ({ skuDoc, pricebookDoc }, ctx) {
  const result = { retailPriceDoc: null, bookPriceDoc: null }
  try {
    if (!skuDoc) return result

    const prices = await fetchSkuPrices({ skuRef: skuDoc }, ctx)

    prices.forEach((p) => {
      if (p.pricebookRef.key === '台幣建議售價') result.retailPriceDoc = p
      if (p.pricebookRef._id === R.prop('_id', pricebookDoc)) result.bookPriceDoc = p
    })

    if (pricebookDoc && result.bookPriceDoc === null) {
      ctx.sendMessage({ type: 'error', message: `${skuDoc.key} 查無 ${pricebookDoc.key}` })
    }
    if (result.retailPrice === null) {
      ctx.sendMessage({ type: 'error', message: `${skuDoc.key} 查無 台幣建議售價` })
    }
  } catch (error) {
    ctx.sendMessage({ type: 'error', message: `${error.message}` })
  }

  return result
}
