const R = require('ramda')
const moment = require('moment')

module.exports = function getDefaultDueDate (doc) {
  const end = R.path(['end'], doc)

  if (R.isNil(end)) return null
  if (!moment(end).isValid()) return null

  return moment(end).add('months', 1).date(8).toDate()
}
