import React from 'react'
import { object, func } from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Editor from 'coact/editors/Editor'
import FormEditor from 'coact/editors/FormEditor'
import TableEditor from 'coact/editors/TableEditor'

import Field from 'coact/editors/Field'

const ui = [
  {
    name: 'formEditor',
    container: { width: 800 },
    fields: [
      ['formattedKey', 'key', 'keyOfSupplierSide', '@suspended'],
    ],
  },
  {
    name: 'formEditor',
    container: { width: 800 },
    fields: [
      [{ path: 'name', span: 2 }],
    ],
  },
  {
    name: 'formEditor',
    container: { width: 800 },
    fields: [
      ['supplierRef'],
      ['pdtSerieRef', 'circulationLevel'],
      ['pdtTypeRef', 'weightRatio'],
    ],
  },
  {
    name: 'formEditor',
    container: { width: 800 },
    fields: [
      ['addSizeSetRef'],
      ['addSkuSizeRefs', 'addSkuColorRefs'],
      ['specs.skuSizeRefs', 'specs.skuColorRefs'],
    ],
  },
  {
    name: 'tableEditor',
    path: 'pricesByPricebooks.buys',
    fields: ['key', 'multiplier', 'price'],
  },
  {
    name: 'tableEditor',
    path: 'pricesByPricebooks.sales',
    fields: ['key', 'multiplier', 'price'],
  },
]

function PageForm (props) {
  const { page: { schema }, setPrice } = props

  return (
    <Editor schema={schema}>
      <FormEditor schema={schema} ui={ui[0]} />
      <br />
      <FormEditor schema={schema} ui={ui[1]} />
      <br />
      <FormEditor schema={schema} ui={ui[2]} />
      <br />
      <FormEditor schema={schema} ui={ui[3]} />
      <br />
      <div style={{ display: 'inline-block', width: 200 }}>
        <Field path="basePrice" schema={{ label: '基準價格', type: 'Number' }} />
      </div>
      <Button variant="contained" color="primary" onClick={setPrice}>自動計算價格</Button>
      <Grid container spacing={16}>
        <Grid item>
          <TableEditor ui={ui[4]} appendEmpty={false} />
        </Grid>
        <Grid item>
          <TableEditor ui={ui[5]} appendEmpty={false} />
        </Grid>
      </Grid>
    </Editor>
  )
}
PageForm.propTypes = {
  page: object.isRequired,
  setPrice: func.isRequired,
}

export default PageForm
