/* eslint-disable quotes */
module.exports = {
  consumer: {
    id: "390dcfe0-0c8f-427f-bbb7-4c35fe3786fa",
    createdAt: "2019-12-23T02:44:37.870Z",
    updatedAt: null,
    clientId: "tmp_k4hu7km3_8mr5lml9",
    date: "2019-12-23T02:44:38.043Z",
    yearmonth: "10812",
    buyer: {
      id: "0000000000",
      name: "e364",
    },
    type: "normal",
    amount: {
      type: "tax",
      rate: "0.05",
      taxSales: "3073",
      freeTaxSales: "0",
      zeroTaxSales: "0",
      tax: "0",
      total: "3073",
    },
    items: [
      {
        quantity: "1",
        price: "3073",
        amount: "3073",
        description: "自行車服",
      },
    ],
    account: "12345678",
    randomNo: "0176",
    no: "AA99999999",
    exportedAt: null,
    uploadedAt: null,
    canceledAt: null,
    cancelExportedAt: null,
    cancelUploadedAt: null,
    voidedAt: null,
    voidExportedAt: null,
    voidUploadedAt: null,
    QRCode1: "AA000010211081223017600000c0100000c010000000012345678tfxW63rPhNA3ITGUIK0JOQ==:**********:1:1:1:車衣:1:3073",
    QRCode2: "**",
  },
  party: {
    id: "390dcfe0-0c8f-427f-bbb7-4c35fe3786fa",
    createdAt: "2019-12-23T02:44:37.870Z",
    updatedAt: null,
    clientId: "tmp_k4hu7km3_8mr5lml9",
    date: "2019-12-23T02:44:38.043Z",
    yearmonth: "10812",
    buyer: {
      id: "42639570",
      name: "e364",
    },
    type: "normal",
    amount: {
      type: "tax",
      rate: "0.05",
      taxSales: "2927",
      freeTaxSales: "0",
      zeroTaxSales: "0",
      tax: "146",
      total: "3073",
    },
    items: [
      {
        quantity: "1",
        price: "3073",
        amount: "3073",
        description: "自行車服",
      },
    ],
    account: "12345678",
    randomNo: "0176",
    no: "AA99999999",
    exportedAt: null,
    uploadedAt: null,
    canceledAt: null,
    cancelExportedAt: null,
    cancelUploadedAt: null,
    voidedAt: null,
    voidExportedAt: null,
    voidUploadedAt: null,
    QRCode1: "AA000010211081223017600000c0100000c010000000012345678tfxW63rPhNA3ITGUIK0JOQ==:**********:1:1:1:車衣:1:3073",
    QRCode2: "**",
  },
}
/* eslint-enable quotes */
