import React from 'react'
import PropTypes from 'prop-types'

// import * as R from 'coact/ramda'

// import Button from '@material-ui/core/Button'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

import Editor from 'coact/editors/Editor'
import Field from 'coact/editors/Field'

import mapData from './mapData'

const { object, array, bool, any } = PropTypes

function Cell (props) {
  const { children, header, style, ...others } = props
  const styles = {
    border: '1px solid gray',
    width: 80,
    textAlign: 'center',
    padding: 0,
    backgroundColor: header ? '#EAF2D3' : 'none',
  }
  return header
    ? <th style={styles} {...others}>{children}</th>
    : <td style={styles} {...others}>{children}</td>
}
Cell.propTypes = {
  children: any,
  header: bool,
}

const Row = withStyles({
  root: {
    '&:hover td': {
      backgroundColor: '#FFCCFF',
    },
    height: 40,
  },
})(TableRow)
const TBody = withStyles({
  root: {
    '&:hover td[rowspan]': {
      backgroundColor: '#FFCCFF',
    },
  },
})(TableBody)

function SizeRow (props) {
  const { sizeList } = props
  return (
    <TBody>
      <Row>
        <Cell header>色碼</Cell>
        <Cell header>色名</Cell>
        <Cell header />
        {sizeList.map(size => (
          <Cell header key={size.key}>{size.key}</Cell>
        ))}
        <Cell header>合計</Cell>
      </Row>
    </TBody>
  )
}
SizeRow.propTypes = {
  sizeList: array.isRequired,
}

function ColorRow (props) {
  const { color, sizeQtyList } = props
  return (
    <TBody>
      <Row>
        <Cell rowSpan="6">{color.key}</Cell>
        <Cell rowSpan="6">{color.name}</Cell>
        <Cell>已訂</Cell>
        {sizeQtyList.map(sizeQty => (
          <Cell key={sizeQty.skuId}>{sizeQty.unarrivedQty}</Cell>
        ))}
      </Row>
      <Row>
        <Cell>在途</Cell>
        {sizeQtyList.map(sizeQty => (
          <Cell key={sizeQty.skuId}>{sizeQty.onTheWayQty}</Cell>
        ))}
      </Row>
      <Row>
        <Cell>庫存</Cell>
        {sizeQtyList.map(sizeQty => (
          <Cell key={sizeQty.skuId}>{sizeQty.stockQty}</Cell>
        ))}
      </Row>
      <Row>
        <Cell>已售</Cell>
        {sizeQtyList.map(sizeQty => (
          <Cell key={sizeQty.skuId}>{sizeQty.salesQty}</Cell>
        ))}
      </Row>
      <Row>
        <Cell>零售</Cell>
        {sizeQtyList.map(sizeQty => (
          <Cell key={sizeQty.skuId}>{sizeQty.retailQty}</Cell>
        ))}
      </Row>
      <Row>
        <Cell>合計</Cell>
        {sizeQtyList.map(sizeQty => (
          <Cell key={sizeQty.skuId}>{sizeQty.sumQty}</Cell>
        ))}
      </Row>
    </TBody>
  )
}
ColorRow.propTypes = {
  color: object.isRequired,
  sizeQtyList: array.isRequired,
}

function ReferenceQueryPage (props) {
  const { schema } = props
  return (
    <Editor schema={schema}>
      {(editor) => {
        const { value } = editor
        const { sizeList, colorList, qtyDict } = mapData(value.referenceData)

        return (
          <React.Fragment>
            <h2>採購參考資料查詢</h2>
            <div>
              <Field path="product" />
            </div>
            <table style={{ border: '1px solid #CCC', borderCollapse: 'collapse' }}>
              {colorList.map((color, index) => (
                <React.Fragment key={color.key}>
                  <SizeRow sizeList={sizeList} />
                  <ColorRow color={color} sizeQtyList={qtyDict[index]} />
                </React.Fragment>
              ))}
            </table>
          </React.Fragment>
        )
      }}
    </Editor>
  )
}
ReferenceQueryPage.propTypes = {
  schema: object.isRequired,
}

export default ReferenceQueryPage
