const schema = require('./schema')

module.exports = {
  title: '進貨項目',
  schema,
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['orderRef.key', 'orderRef.date', 'orderRef.sellerRef'],
          ['sku.key', 'name', 'qty', 'price', 'subtotal'],
          ['note'],
        ],
      },
    },
    // populations: 'purchaseOrders,skus,suppliers',
    // populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        { path: 'orderRef.key', model: 'purchaseOrders', queryPath: 'orderRef' },
        { path: 'orderRef.date', model: 'purchaseOrders', queryPath: 'orderRef' },
        { path: 'orderRef.status', model: 'purchaseOrders', queryPath: 'orderRef' },
        { path: 'orderRef.sellerRef', model: 'purchaseOrders', queryPath: 'orderRef' },
        'sku.key',
        'name',
        'qty',
        { path: 'price' },
        { path: 'subtotal' },
        'note',
      ],
    },
    populations: 'purchaseOrders,skus,suppliers',
    populationdepth: 2,
  },
}
