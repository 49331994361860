const R = require('coact/ramda')
const mergeConfig = require('coact/config/mergeConfig')

const basicModels = {
  // staffers: require('./staffers'),
  customers: require('./customers'),
  suppliers: require('./suppliers'),
  pdtSeries: require('./pdtSeries'),
  pdtTypes: require('./pdtTypes'),
  skuColors: require('./skuColors'),
  skuSizes: require('./skuSizes'),
  sizeSets: require('./sizeSets'),
  // skus: require('./skus'),
  pricebooks: require('./pricebooks'),
  prices: require('./prices'),
  warehouses: require('./warehouses'),
  stocks: require('./stocks'),
  stockRecords: require('./stockRecords'),
  receivables: require('./receivables'),
}

const { models } = R.reduce(
  mergeConfig,
  { },
  [
    { models: basicModels },
    require('./recStatements'),
    require('./salesOrders'),
    require('./transferOrders'),
    require('./salesReqs'),
  ],
)

module.exports = models
