import React from 'react'
// import { func } from 'prop-types'

// import { connect } from 'react-redux'

// import * as $message from 'coact/store/message'
import Button from '@material-ui/core/Button'

import Invoice from '~/src/invoice/printer/Invoice'

import PagePrinter from 'coact/pages/EditPage/PagePrinter'

import testData from './invoiceTestData'


class TestInvoicePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = { invoiceOfPrinting: testData.consumer }

    this.printConsumerInvoice = this.printConsumerInvoice.bind(this)
    this.printPartyInvoice = this.printPartyInvoice.bind(this)
  }

  printConsumerInvoice () {
    this.setState(
      { invoiceOfPrinting: testData.consumer },
      () => setTimeout(window.print, 100),
    )
  }

  printPartyInvoice () {
    this.setState(
      { invoiceOfPrinting: testData.party },
      () => setTimeout(window.print, 100),
    )
  }

  render () {
    const doc = { invoiceOfPrinting: this.state.invoiceOfPrinting }
    const Printer = () => <Invoice doc={doc} />

    return (
      <div>
        <Printer />
        <PagePrinter page={{ Printer }} />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={this.printConsumerInvoice}
        >
          消費者發票
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={this.printPartyInvoice}
        >
          公司組織發票
        </Button>
      </div>
    )
  }
}

// TestInvoicePage.propTypes = {
//   dispatch: func,
// }

export default TestInvoicePage
