const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const orderViewClientConfig = require('./orderView/clientConfig')
const itemsClientConfig = require('./items/clientConfig')

const config = mergeConfig(
  R.assocPath(
    ['models', 'purchaseOrders', 'views', 'order'],
    orderViewClientConfig,
    { },
  ),
  R.assocPath(
    ['models', 'purchaseOrderItems'],
    itemsClientConfig,
    { },
  ),
)

module.exports = fullConfig => mergeConfig(fullConfig, config)
