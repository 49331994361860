const R = require('ramda')

const schemaModule = require('../../utils/schema')

const { dataAccess } = require('../purchaseOrder')


const schema = R.pipe(
  R.dissocPath(['contents', 'items']),
  R.assocPath(
    ['contents', 'items'],
    {
      type: 'Array',
      contents: [{ type: 'ObjectId', reference: 'purchaseOrderItems' }],
    },
  ),
  schemaModule.create,
)(dataAccess.schema)


module.exports = {
  schema,
}
