const mergeConfig = require('coact/config/mergeConfig')

const ReferenceQueryPage = require('./ReferenceQueryPage')

const config = {
  routes: {
    referenceQuery: {
      title: '採購參考資料查詢',
      path: '/route/referenceQuery',
      component: ReferenceQueryPage,
    },
  },
  sagas: {
    referenceQuerySaga: require('./saga').default,
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
