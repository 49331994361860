const mergeConfig = require('coact/config/mergeConfig')

const clientConfig = require('./clientConfig')


const config = {
  models: {
    retailOrders: {
      views: {
        cashier: clientConfig,
      },
    },
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
