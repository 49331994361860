module.exports = {
  title: '商品類別資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        name: '商品類別',
        type: 'String',
        unique: true,
        required: true,
        ragicField: 1000016,
      },
      code: {
        name: '類別代碼',
        type: 'String',
      },
      weightRatio: {
        name: '運費分攤比',
        type: 'Number',
        ragicField: 1000017,
      },
      sortOrder: {
        name: '排序',
        type: 'Number',
        ragicField: 1000249,
      },
      hideInOptions: {
        name: '在選項隱藏',
        type: 'Boolean',
      },
      syncAt: {
        name: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        name: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 150 },
        { path: 'code', width: 150 },
        { path: 'weightRatio' },
        { path: 'sortOrder' },
        { path: 'hideInOptions' },
      ],
    },
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['key', 'code'],
          ['weightRatio', 'sortOrder'],
          ['hideInOptions'],
        ],
      },
    },
  },
}
