module.exports = {
  title: '商品系列資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        name: '系列名稱',
        type: 'String',
        unique: true,
        required: true,
        ragicField: 1000146,
      },
      code: {
        name: '系列代碼',
        type: 'String',
      },
      releaseTime: {
        name: '推出時間',
        type: 'Date',
        format: 'YYYY-MM-DD',
      },
      sortOrder: {
        name: '排序',
        type: 'Number',
        ragicField: 1000248,
      },
      hideInOptions: {
        name: '在選項隱藏',
        type: 'Boolean',
      },
      hideInPublicStocks: {
        name: '在公開庫存隱藏',
        type: 'Boolean',
      },
      mergeInPublicStock: {
        name: '促銷系列',
        type: 'Boolean',
      },
      description: {
        name: '系列說明',
        type: 'String',
        input: 'textarea',
        ragicField: 1000147,
      },
      syncAt: {
        name: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        name: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 150 },
        { path: 'code', width: 150 },
        { path: 'releaseTime' },
        { path: 'sortOrder' },
        { path: 'hideInOptions' },
        { path: 'hideInPublicStocks', width: 130 },
        { path: 'mergeInPublicStock' },
      ],
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [
          ['key', 'code'],
          ['releaseTime', 'sortOrder'],
          ['hideInOptions', 'hideInPublicStocks'],
          ['mergeInPublicStock', 'description'],
        ],
      },
    ],
  },
}
