const schema = require('./schema')

module.exports = {
  title: '零售項目',
  schema,
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['order.id.key', 'order.id.date'],
          ['sku.id.key', 'name', 'qty', 'price', 'subtotal', 'amount'],
        ],
      },
    },
    populations: 'retailOrders,skus',
    populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        { path: 'order.id.key', model: 'retailOrders', queryPath: 'order.id', label: '零售單號', type: 'String' },
        { path: 'order.id.date', model: 'retailOrders', queryPath: 'order.id', label: '日期', type: 'Date' },
        'sku.key',
        'name',
        'qty',
        'price',
        'subtotal',
        'amount',
      ],
    },
    populations: 'retailOrders,skus',
    populationdepth: 2,
  },
}
