const R = require('ramda')

const safeN = require('coact/utils/safeN')

module.exports = function computer (order, items) {
  const itemDocs = R.map(
    (value) => {
      const qty = safeN(value.qty)
      const price = safeN(value.price)

      return { ...value, subtotal: qty * price }
    },
    items || [],
  )

  const amount = R.reduce(
    (acc, { subtotal }) => (acc + subtotal),
    0,
    itemDocs,
  )

  const total = R.reduce(
    (acc, { qty }) => acc + qty,
    0,
    itemDocs,
  )

  const shippingCost = order.shippingCost || 0
  const customsDuty = order.customsDuty || 0
  const payable = amount + shippingCost + customsDuty

  const orderDoc = R.mergeDeepRight(
    order,
    {
      amount,
      summary: `共 ${total} 個`,
      payable,
    },
  )

  return [orderDoc, itemDocs]
}
