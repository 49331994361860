import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'

import FormEditor from 'coact/editors/FormEditor'
import TableEditor from 'coact/editors/TableEditor'
import ProductReader from 'coact/components/ProductReader'
import PageActions from 'coact/pages/EditPage/PageActions'
import * as $editorSaga from 'coact/saga/editorSaga'
import createEffectAction from 'coact/pages/modules/pageAction/createEffectAction'
import Editor from 'coact/editors/Editor'

import toPath from 'coact/utils/toPath'

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'


const { object, func } = PropTypes

function InvoiceTable (props) {
  const { ui, invalidInvoice } = props

  return (
    <Editor>
      {
        (editor) => {
          const invoices = R.pathOr([], ['value', 'invoices'], editor)

          return (
            <div>
              <table>
                <thead>
                  <tr>
                    {
                      ui.fields.map(({ label, width }) => (
                        <th key={label} style={{ width }}>
                          {label}
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    invoices.map(invoice => (
                      <tr key={invoice._id}>
                        {
                          ui.fields.map(({ path }) => (
                            <td key={path}>
                              {R.path(toPath(path), invoice)}
                            </td>
                          ))
                        }
                        <td>
                          {
                            invoice.invoice.canceledAt ? '已作廢' : (
                              <button
                                type="button"
                                // eslint-disable-next-line no-alert
                                onClick={() => (window.confirm('確認作廢？') ? invalidInvoice(invoice._id) : null)}
                              >
                                作廢發票
                              </button>
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }
      }
    </Editor>
  )
}
InvoiceTable.propTypes = {
  ui: object.isRequired,
  invalidInvoice: func.isRequired,
}

function Cashier (props) {
  const { page: { schema, ui }, dispatch } = props

  // setTimeout(() => (
  //   dispatch($message.act.send(
  //     { type: 'error', message: '預計開立發票 VV12345678 已存在，不可重複開立，請聯絡管理人員檢查修正' },
  //   ))
  // ), 5000)

  const invalidInvoice = id => (
    dispatch(createEffectAction({ name: 'invalidInvoice', id }))
  )

  return (
    <React.Fragment>
      <link href="https://fonts.googleapis.com/css?family=Libre+Barcode+39&display=swap" rel="stylesheet" />
      <div><PageActions {...props} /></div>
      <div style={{ display: 'inline-block' }}>
        <FormEditor ui={ui.head} schema={schema} />
        <div
          style={{
            float: 'right',
            display: 'flex',
            marginTop: 10,
            alignItems: 'baseline',
            justifyContent: 'space-between',
            width: 650,
          }}
        >
          <Editor>
            {
              editor => (
                <FormControl style={{ flexGrow: 1 }}>
                  <RadioGroup
                    value={editor.value && editor.value.payType}
                    onChange={(e) => {
                      dispatch($editorSaga.act.editPath({ path: ['payType'], value: e.target.value }))
                    }}
                    style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
                  >
                    <FormControlLabel value="現金" control={<Radio color="primary" />} label={<span style={{ fontSize: '1.5rem' }}>現金</span>} />
                    <FormControlLabel value="信用卡" control={<Radio color="primary" />} label={<span style={{ fontSize: '1.5rem' }}>信用卡</span>} />
                    <FormControlLabel value="LINE PAY" control={<Radio color="primary" />} label={<span style={{ fontSize: '1rem' }}>LINE PAY</span>} />
                    <FormControlLabel value="街口" control={<Radio color="primary" />} label={<span style={{ fontSize: '1.5rem' }}>街口</span>} />
                  </RadioGroup>
                </FormControl>
              )
            }
          </Editor>
          <div style={{ width: 200 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                dispatch(createEffectAction({ name: 'saveAndPrintInvoice' }))
              }}
              style={{
                height: 60,
                width: 200,
                fontSize: 30,
              }}
            >
              結帳開發票
            </Button>
            {/*
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(createEffectAction({ name: 'saveAndPrintInvoiceOnPos' }))
              }}
              style={{
                marginTop: 10,
                height: 60,
                width: 200,
                fontSize: 30,
              }}
            >
              列印
            </Button>
            */}
          </div>
        </div>
        <ProductReader {...ui.productReader} />
        <TableEditor ui={ui.items} schema={schema} />
        <InvoiceTable ui={ui.invoices} invalidInvoice={invalidInvoice} />
        <div style={{ fontFamily: '\'Libre Barcode 39\', cursive', fontSize: 1, color: 'white' }}>
          *font*
        </div>
        <div><img style={{ width: 1, height: 1 }} src="invoiceLogo.png" alt="禾宏文化" /></div>
      </div>
    </React.Fragment>
  )
}

Cashier.propTypes = {
  page: object.isRequired,
  dispatch: func.isRequired,
}


export default Cashier
