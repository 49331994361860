import S from 'coact/saga/utils'

import * as $editor from 'coact/store/editor'
import * as $editorSaga from 'coact/saga/editorSaga'
import pageAct from 'coact/pages/modules/pageAction/act'

import actions from './actions'


function* fetchUnarrivedItems (supplier) {
  const api = yield S.getContext('api')
  const { data } = yield S.call(
    api.get,
    {
      url: '/api/route/arrivalProcess',
      search: {
        findUnarrivedItems: null,
        query: {
          supplierId: supplier._id,
        },
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: '',
    value: {
      supplier,
      unarrivedItems: data.items,
      show: 'unarrivedItems',
    },
  }))
}

function* findUnarrivedItems (payload) {
  const { value: supplier } = payload

  yield S.call(fetchUnarrivedItems, supplier)
}

function* findSuppliersInProgress () {
  const api = yield S.getContext('api')
  const { data } = yield S.call(
    api.get,
    {
      url: '/api/route/arrivalProcess',
      search: {
        findSuppliersInProgress: null,
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: '',
    value: {
      supplier: null,
      suppliersInProgress: data.suppliers,
      unarrivedItems: null,
      show: 'suppliersInProgress',
    },
  }))
}

function* exportInProgressList () {
  const api = yield S.getContext('api')
  const { supplier } = yield S.select($editor.select.value)
  const query = supplier ? { sellerRef: `ObjectId('${supplier._id}')` } : { }

  const { data } = yield S.call(
    api.get,
    {
      url: '/api/route/arrivalProcess',
      search: {
        exportInProgressList: null,
        query,
      },
    },
  )

  const { url } = data
  window.open(url)
}

function* clickSupplier (action) {
  const { payload: supplier } = action

  yield S.put($editorSaga.act.editPath({
    path: 'supplier',
    value: supplier,
  }))

  yield S.call(fetchUnarrivedItems, supplier)
}

function* createPurchaseOrder () {
  const { supplier, unarrivedItems, orderToAppend } = yield S.select($editor.select.value)

  const api = yield S.getContext('api')
  const { data } = yield S.call(
    api.post,
    {
      url: '/api/route/arrivalProcess',
      data: { supplier, unarrivedItems, orderToAppend },
    },
  )

  const { purchaseOrderId } = data
  if (purchaseOrderId) {
    const history = yield S.getContext('history')
    history.push(`/edit/purchaseOrders/view/order/${purchaseOrderId}`)
  }
}

function* handleEffect ({ payload }) {
  const { name } = payload
  if (name === 'findUnarrivedItems') yield S.call(findUnarrivedItems, payload)
}

export default function* salesOrderCreatorSaga () {
  yield S.takeEvery(pageAct.everyEffect, handleEffect)
  yield S.takeFirst(actions.createPurchaseOrder, createPurchaseOrder)
  yield S.takeFirst(actions.findSuppliersInProgress, findSuppliersInProgress)
  yield S.takeFirst(actions.exportInProgressList, exportInProgressList)
  yield S.takeFirst(actions.clickSupplier, clickSupplier)
}
