import { createAct } from 'coact/utils/action'


export default createAct({
  namespace: ['hehong', 'SalesOrderCreator'],
  types: [
    'findUndeliveredItems',
    'fetchUndeliveredCustomers',
    'createSalesOrder',
    'clickCustomer',
  ],
})
