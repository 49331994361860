const arrivalSchema = {
  type: 'Object',
  contents: {
    purchaseItemDoc: { type: 'Object' },
    stockInItemDocs: { type: 'Array', contents: [{ type: 'Object' }] },
    unarrivedQty: { type: 'Number', label: '未到' },
  },
}

const purchaseItemRef = { type: 'ObjectId', reference: 'purchaseItems' }
const stockInItemRef = { type: 'ObjectId', reference: 'purchaseOrderItems' }
const unarrivedQty = { type: 'Number', label: '未到' }
const onTheWayQty = { type: 'Number', label: '在途' }

const reqRef = { type: 'ObjectId', reference: 'purchaseReqs', refKeys: ['key'], label: '連結採購單' }

module.exports = {
  purchaseItemRef,
  stockInItemRef,
  reqRef,
  unarrivedQty,
  onTheWayQty,
  arrivalSchema,
}
