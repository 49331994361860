import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'

import View from './SeriesList'

const SeriesList = compose(
  withHandlers({
    chooseSeries: ({ setCurSeries, setHideEmpty, getStocks }) => series => {
      setCurSeries(series.key)
      getStocks({ series: series.series })
      setHideEmpty(series.hideEmpty)
    },
  }),
)(View)

SeriesList.displayName = 'SeriesList'

export default SeriesList
