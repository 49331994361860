async function getBody (response) {
  const text = await response.text()

  try {
    return JSON.stringify(JSON.parse(text))
  } catch (error) {
    return text
  }
}

module.exports = async function issueInvoice (doc) {
  const id = doc._id
  const res = await fetch(
    `api/route/invoice?id=${id}&action=issue`,
    {
      method: 'POST',
    },
  )

  if (res.ok) return res.json()
  throw new Error(await getBody(res))
}
