import React from 'react'
import { array, string, func, bool } from 'prop-types'

import SeriesList from './SeriesList'
import FilterList from './FilterList'
import StocksTable from './StocksTable'
import ProductsList from './ProductsList'
import LoadingIcon from '../LoadingIcon'

function Page (props) {
  const {
    stocksData, productsData, getStocks, setCurSeries,
    search, setSearch, hideEmpty, setHideEmpty, sortBy, setSortBy,
    isLoading,
  } = props
  return (
    <div style={{ position: 'absolute', left: 0 }}>
      <LoadingIcon open={isLoading} />
      <SeriesList {...props} />
      <FilterList {...{ search, setSearch, hideEmpty, setHideEmpty, sortBy, setSortBy }} />
      {(Array.isArray(stocksData) && stocksData.length > 0)
        ? <StocksTable {...{ stocksData, search, hideEmpty }} />
        : <ProductsList {...{ productsData, getStocks, setCurSeries, search }} />
      }
    </div>
  )
}
Page.propTypes = {
  stocksData: array,
  productsData: array,
  getStocks: func.isRequired,
  setCurSeries: func.isRequired,
  search: string,
  setSearch: func.isRequired,
  hideEmpty: bool.isRequired,
  setHideEmpty: func.isRequired,
  sortBy: string,
  setSortBy: func.isRequired,
  isLoading: bool.isRequired,
}

export default Page
