// const R = require('ramda')

const mergeDeep = require('coact/utils/mergeDeep')
const assocPath = require('coact/utils/assocPath')

const mergeClientRequiredChanges = require('../../../utils/schema/mergeClientRequiredChanges')

const salesReqs = require('../salesReqs')
const salesReqItems = require('../salesReqItems')


const computedItemFields = {
  contents: {
    currentStockQty: { type: 'Number' },
  },
}
const itemSchema = mergeDeep(
  mergeClientRequiredChanges(salesReqItems.schema),
  computedItemFields,
)

const schema = assocPath(
  ['contents', 'items', 'contents', 0],
  itemSchema,
  mergeClientRequiredChanges(salesReqs.schema),
)


module.exports = schema
