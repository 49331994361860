const mergeSystemFields = require('../utils/schema/mergeSystemFields')


const schema = mergeSystemFields({
  type: 'Object',
  contents: {
    date: { type: 'Date', label: '日期' },
    status: {
      type: 'String',
      label: '狀態',
      creator: '未收',
      options: ['未收', '已請款', '已收款'],
      width: 100,
    },
    account: {
      type: 'String',
      label: '科目',
      options: ['銷貨收入', '銷貨折讓', '其他'],
    },
    amount: { type: 'Number', label: '金額', width: 70 },
    content: { type: 'String', label: '內容', width: 300 },
    orderRef: {
      type: 'ObjectId',
      label: '銷貨單',
      reference: 'salesOrders',
      refKeys: ['key'],
      view: 'main',
    },
    recStatementRef: {
      type: 'ObjectId',
      label: '應收對帳單',
      reference: 'recStatements',
      refKeys: ['key'],
      view: 'main',
    },
  },
})

module.exports = {
  title: '應收帳款',
  schema,
  list: {
    ui: {
      fields: [
        { path: 'orderRef.buyerRef.key', label: '客戶' },
        'date', 'status', 'account', 'amount', 'content',
        'orderRef', 'recStatementRef',
      ],
    },
  },
  export: {
    populations: 'salesOrders,customers,recStatements',
    populationdepth: 2,
    fields: [
      'orderRef.buyerRef.key',
      'date', 'status', 'account', 'amount', 'content',
      'orderRef.key', // { path: 'orderRef.key', label: '銷貨單' },
      'recStatementRef.key', // { path: 'recStatementRef.key', label: '應收對帳單' },
    ],
  },
}
