const R = require('ramda')

const installRetailOrder = require('./retailOrder/installClient')
const installCashier = require('./cashier/installClient')


module.exports = R.pipe(
  installRetailOrder,
  installCashier,
)
