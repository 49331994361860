const computer = require('./computer')

module.exports = (deps, my) => async function runComputer (orderId) {
  const { data } = deps

  const { order, items } = await data.getOrderAndItems(orderId)

  const [computedOrder, computedItems] = computer(order, items)

  await my.updateOrder(orderId, computedOrder)
  await my.assignItems(orderId, computedItems)

  return orderId
}
