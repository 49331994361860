import React from 'react'
import { array, func, string } from 'prop-types'

import injectSheet from 'react-jss'

function foundSearch (pdt, searchOri) {
  const search = searchOri.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const re = new RegExp(search, 'i')
  const { productKey, name, pdtSerie } = pdt
  if (re.test(productKey || '')) return true
  if (re.test(name || '')) return true
  if (re.test(pdtSerie || '')) return true
  return false
}

function Tr ({ classes, children, ...others }) {
  return (
    <tr className={classes.row} {...others}>{children}</tr>
  )
}

const styles = {
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF8AF',
    },
  },
}

const StyledTr = injectSheet(styles)(Tr)

function ProductsList (props) {
  const { productsData, chooseProduct, search } = props

  return (
    <table>
      <thead>
        <tr>
          <th>型號</th>
          <th>中文品名</th>
          <th>系列</th>
        </tr>
      </thead>
      <tbody>
        {productsData.map(p => (foundSearch(p, search)
          ? (
            <StyledTr key={p.productKey} onClick={() => chooseProduct(p._id)}>
              <td>{p.productKey}</td>
              <td>{p.name}</td>
              <td>{p.pdtSerie}</td>
            </StyledTr>
          )
          : null
        ))}
      </tbody>
    </table>
  )
}
ProductsList.propTypes = {
  productsData: array.isRequired,
  chooseProduct: func.isRequired,
  search: string.isRequired,
}

export default ProductsList
