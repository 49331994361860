const R = require('ramda')

const intf = require('./interface')

const implementBy = (impl) => {
  const reqData = intf(impl)

  Object.keys(reqData).forEach((key) => {
    if (!R.has(key, impl)) {
      throw new Error(`reqData implement needs ${key} function`)
    }
  })

  return reqData
}

module.exports = {
  implementBy,
  interface: () => intf(),
  schema: require('./schema'),
}
