const buildSkuName = require('../../../../models/modules/buildSkuName')

const schema = require('./schema')
const autofill = require('./autofill')
const computer = require('./computer')
const watcherActionsEffect = require('./watcherActionsEffect')
const OrderEditor = require('./OrderEditor').default


module.exports = {
  title: '進貨單',
  schema,
  edit: {
    autofill,
    computer,
    ...watcherActionsEffect,
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'packingListNo', 'invoiceNo', 'date'],
          ['personInCharge', 'warehouseRef', 'status'],
          ['sellerRef', 'seller.contact', 'seller.phone', 'seller.fax'],
          ['seller.email', { path: 'seller.address', span: 2 }],
          [{ path: 'note', span: 3 }],
          // Issue invalid format setting?
          ['pricebookRef', { path: 'amount', format: '0[.]000' }, 'shippingCost', 'customsDuty'],
          [{ path: 'payable', format: '0[.]000' }, 'summary'],
        ],
      },
      productReader: {
        url: '/api/model/skus',
        formatter: buildSkuName,
        queryKey: 'barcode',
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        fields: [
          { path: 'skuRef', width: 350 },
          { path: 'name', width: 350 },
          { path: 'qty', width: 50 },
          // Issue invalid format setting?
          { path: 'price', width: 80, format: '0[.]000' },
          { path: 'subtotal', width: 90, format: '0[.]000' },
          { path: 'note', width: 200 },
          // { path: 'reqRef', view: 'item', formatter: R.pathOr('', ['$id', 'key']) },
          { path: 'reqRef', view: 'item', width: 150 },
          { path: 'skuRef.barcode', label: '條碼', disabled: true },
        ],
        copyable: false,
        deletable: true,
      },
    },
    populations: '*',
    populationdepth: 2,
    Components: {
      PageForm: OrderEditor,
    },
    autoSelect: {
      warehouseRef: 'HH',
    },
    required: {
      personInCharge: true,
      warehouseRef: true,
      // pricebookRef: true,
    },
    creator: {
      date: '$$NOW',
      status: '新增',
      'items.0.qty': 1,
    },
    disabled: {
      key: true,
      amount: true,
      summary: true,
      'items.0.subtotal': true,
      'items.0.reqRef': true,
      // 'items.0.skuRef.barcode': true, // BUG sku 會無法顯示，原因不明
    },
    noclone: {
      key: true,
      status: true,
      date: true,
      note: true,
      summary: true,
      'items.0.reqRef': true,
    },
  },
  list: {
    ui: {
      fields: [
        'key', 'packingListNo', 'invoiceNo', 'date', 'status', 'personInCharge',
        'sellerRef', 'warehouseRef', 'summary', { path: 'amount', format: '0[.]000' }, 'note',
      ],
    },
    // populations: 'staffers,suppliers,stocks,warehouses',
  },
}
