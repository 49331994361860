module.exports = {
  title: '價格資料',
  schema: {
    type: 'Object',
    contents: {
      productRef: {
        name: '商品',
        type: 'ObjectId',
        reference: 'products',
        required: true,
        refKeys: ['key'],
        ragicField: 1000014,
        ragicParser: v => v.replace(/\s/g, ''),
        ragicRefKeys: ['formattedKey'],
      },
      pricebookRef: {
        name: '價格表',
        type: 'ObjectId',
        reference: 'pricebooks',
        required: true,
        refKeys: ['key'],
        ragicField: 1000028,
      },
      price: {
        name: '價格',
        type: 'Number',
        // required: true,
        ragicField: 1000023,
      },
      syncAt: {
        name: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        name: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        productRef: 1,
        pricebookRef: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'productRef', width: 120 },
        { path: 'pricebookRef', width: 120 },
        { path: 'price' },
      ],
    },
  },
  ui: {
    editor: {
      config: { nameWidth: 110 },
      groups: [
        {
          name: '',
          rows: [
            [{ key: 'productRef' }, { key: 'pricebookRef' }],
            [{ key: 'price' }],
            [{ key: 'ragicId' }, { key: 'syncAt' }],
          ],
        },
      ],
    },
  },
}
