const schema = require('./schema')
const autofill = require('./autofill')
const computer = require('./computer')
const watcherActionsEffect = require('./watcherActionsEffect')

module.exports = {
  title: '採購單',
  schema,
  options: { skus: { } },
  edit: {
    autofill,
    computer,
    ...watcherActionsEffect,
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'status'],
          ['keyOfSellerSide', 'expectedArrivalDate', 'personInCharge'],
          ['sellerRef', 'seller.contact', 'seller.phone', 'seller.fax'],
          ['seller.email', { path: 'seller.address', span: 2 }],
          [{ path: 'note', span: 3, multiline: true }],
          [{ path: 'amount' }, 'summary', 'pricebookRef'],
        ],
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        fields: [
          { path: 'skuRef', width: 350 },
          { path: 'name', width: 350 },
          { path: 'qty', width: 50 },
          { path: 'unarrivedQty', width: 50 },
          { path: 'onTheWayQty', width: 50 },
          { path: 'price', width: 80 },
          { path: 'subtotal', width: 90 },
          { path: 'note', width: 200 },
        ],
        deletable: true,
        copyable: false,
      },
    },
    creator: {
      date: '$$TODAY',
      status: '新增',
      'items.0.qty': 1,
    },
    disabled: {
      key: true,
      amount: true,
      summary: true,
      'items.0.subtotal': true,
      'items.0.unarrivedQty': true,
      'items.0.onTheWayQty': true,
    },
    populations: '*',
    populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        'key',
        'keyOfSellerSide',
        'status',
        'date',
        'expectedArrivalDate',
        'sellerRef',
        'summary',
        { path: 'amount', format: '0.00' },
        'note',
      ],
    },
  },
}
