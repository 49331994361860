const R = require('ramda')
const schemaAt = require('coact/utils/schema/schemaAt')

const schemaModule = require('../../../../utils/schema')

const { reqData } = require('../../../purchaseReq')


// for populate schema
const orderSchema = R.pipe(
  R.dissocPath(['contents', 'items']),
  schemaModule.create,
)(reqData.schema)

const schema = R.pipe(
  R.assocPath(['contents', 'orderRef'], orderSchema),
  R.assocPath(['contents', 'unarrivedQty'], { type: 'Number', label: '未到' }),
  R.assocPath(['contents', 'onTheWayQty'], { type: 'Number', label: '在途' }),
  schemaModule.create,
)(schemaAt(['items', 0], reqData.schema)) // itemSchema

module.exports = schema
