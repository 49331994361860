import S from 'coact/saga/utils'

// import * as $editor from 'coact/store/editor'
import * as $editorSaga from 'coact/saga/editorSaga'
import pageAct from 'coact/pages/modules/pageAction/act'

// import actions from './actions'


function* fetchReferenceData (product) {
  if (!product) return

  const api = yield S.getContext('api')
  const { data } = yield S.call(
    api.get,
    {
      url: '/api/route/referenceQuery',
      search: {
        query: {
          productId: product._id,
        },
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: '',
    value: {
      product,
      referenceData: data,
    },
  }))
}

function* queryReferenceData (payload) {
  const { value: product } = payload

  yield S.call(fetchReferenceData, product)
}

function* handleEffect ({ payload }) {
  const { name } = payload
  if (name === 'queryReferenceData') yield S.call(queryReferenceData, payload)
}

export default function* salesOrderCreatorSaga () {
  yield S.takeEvery(pageAct.everyEffect, handleEffect)
  // yield S.takeFirst(actions.createPurchaseOrder, createPurchaseOrder)
  // yield S.takeFirst(actions.findSuppliersInProgress, findSuppliersInProgress)
  // yield S.takeFirst(actions.clickSupplier, clickSupplier)
}
