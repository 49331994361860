const S = require('coact/saga/utils')

// const $list = require('coact/store/pages/list')
const $editor = require('coact/store/editor')
// const $message = require('coact/store/message')

const buildQueryObj = require('coact/saga/buildQueryObj')


function* sendQuery ({ url, search = { } }) {
  const api = yield S.getContext('api')

  const { data } = yield S.call(api.get, {
    url,
    search,
  })

  return data
}

function buildSearchObj (findSpec) {
  const query = buildQueryObj(findSpec.filters)

  const skip = (findSpec.page - 1) * findSpec.limit
  const limit = findSpec.limit

  return {
    query,
    limit,
    skip,
  }
}

module.exports = function* exportPriceStock () {
  const findSpec = yield S.select($editor.select.value)
  const page = yield S.getContext('page')

  const search = buildSearchObj(findSpec, page)

  const exportSearch = {
    ...search,
    exportLimit: findSpec.exportLimit,
  }

  const data = yield S.call(
    sendQuery,
    {
      url: 'api/route/exportPriceStock',
      search: exportSearch,
    },
  )

  const { url } = data
  window.open(url)
}
