const mergeConfig = require('coact/config/mergeConfig')

const clientConfig = {
  title: '人員資料',
  list: {
    ui: {
      fields: [
        { path: 'key' },
        { path: 'phone', width: 120 },
        { path: 'secPhone', width: 120 },
        { path: 'email', width: 200 },
      ],
    },
  },
}

const config = {
  models: { staffers: clientConfig },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
