const R = require('ramda')

const schemaAt = require('coact/utils/schema/schemaAt')

const schemaModule = require('../../utils/schema')

const { dataAccess } = require('../purchaseOrder')


const schema = R.pipe(
  R.assocPath(
    ['contents', 'orderRef'],
    {
      type: 'ObjectId', label: '進貨單號', reference: 'purchaseOrders', refKeys: ['key'],
    },
  ),
  schemaModule.create,
)(schemaAt(['items', 0], dataAccess.schema))


module.exports = {
  schema,
}
