const { compose, withProps, withHandlers } = require('recompose')
const { connect } = require('react-redux')

const mergeDeep = require('coact/utils/mergeDeep')
const schemaAt = require('coact/utils/schema/schemaAt')

const $purchaseReq = require('../../../purchaseReq')
const actions = require('../actions')


const purchaseItemSchema = schemaAt('items.0', $purchaseReq.reqData.schema)

// TODO schema 有部份不符合回傳資料, purchaseReqId, purchaseItemId, ...
const schema = {
  type: 'Object',
  contents: {
    supplier: {
      type: 'ObjectId',
      reference: 'suppliers',
      label: '到貨供應商',
      refKeys: ['key'],
      watcher: { name: 'findUnarrivedItems' },
    },
    orderToAppend: {
      type: 'ObjectId',
      reference: 'purchaseOrders',
      label: '附加至進貨單，空白則為新增',
      refKeys: ['key', 'packingListNo', 'invoiceNo'],
    },
    unarrivedItems: {
      type: 'Array',
      contents: [
        mergeDeep(
          purchaseItemSchema,
          {
            contents: {
              arrivalQty: { type: 'Number', label: '到貨數' },
            },
          },
        ),
      ],
    },
  },
}

const container = compose(
  connect(),

  withProps({ schema }),

  withHandlers({
    findSuppliersInProgress: ({ dispatch }) => () => (
      dispatch(actions.findSuppliersInProgress())
    ),
    exportInProgressList: ({ dispatch }) => () => (
      dispatch(actions.exportInProgressList())
    ),
    createPurchaseOrder: ({ dispatch }) => () => dispatch(actions.createPurchaseOrder()),
    clickSupplier: ({ dispatch }) => supplier => (
      dispatch(actions.clickSupplier(supplier))
    ),
  }),
)

module.exports = container
