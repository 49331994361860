module.exports = function reqDataInterface (impl) {
  async function getLinkedStockInItemIds (purchaseItemId) {
    return impl.getLinkedStockInItemIds(purchaseItemId)
  }
  async function getLinkedPurchaseItemIds (stockInItemIds) {
    return impl.getLinkedPurchaseItemIds(stockInItemIds)
  }
  async function mapLinkedPurchaseItemIds (stockInItemIds) {
    return impl.mapLinkedPurchaseItemIds(stockInItemIds)
  }
  async function keepProgressInfo (purchaseItemId, progressInfo) {
    return impl.keepProgressInfo(purchaseItemId, progressInfo)
  }
  async function keepReqSummary (purchaseItemId, progressInfo) {
    return impl.keepReqSummary(purchaseItemId, progressInfo)
  }
  async function findPurchaseItemIdsWithUnarrivedQty () {
    return impl.findPurchaseItemIdsWithUnarrivedQty()
  }
  async function sumProgressInfoOfSkuIds (skuIds) {
    return impl.sumProgressInfoOfSkuIds(skuIds)
  }
  async function sumProgressInfoOfPurchaseItemIds (purchaseItemIds) {
    return impl.sumProgressInfoOfPurchaseItemIds(purchaseItemIds)
  }
  async function findActivePurchaseItems (sellerId) {
    return impl.findActivePurchaseItems(sellerId)
  }
  async function findActivePurchaseItemsOfSeller (sellerId) {
    return impl.findActivePurchaseItemsOfSeller(sellerId)
  }

  async function linkStockInItemIdToPurchaseItemId (stockInItemId, purchaseItemId) {
    return impl.linkStockInItemIdToPurchaseItemId(stockInItemId, purchaseItemId)
  }


  return {
    findPurchaseItemIdsWithUnarrivedQty,
    sumProgressInfoOfSkuIds,
    sumProgressInfoOfPurchaseItemIds,
    findActivePurchaseItemsOfSeller,
    findActivePurchaseItems,
    getLinkedStockInItemIds,
    getLinkedPurchaseItemIds,
    mapLinkedPurchaseItemIds,
    keepProgressInfo,
    keepReqSummary,
    linkStockInItemIdToPurchaseItemId,
  }
}
