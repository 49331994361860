const moment = require('moment')

const invoiceSchema = require('~/src/invoice/data/invoices/schema')

const prefix = 'RS'
const format = 'YYMM'
const digits = 3

const recStatement = {
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      label: '單號',
      counter: {
        key: () => `${prefix}${moment().format(format)}`,
        value: (key, value) => `${key}${value.toString().padStart(digits, 0)}`,
      },
      disabled: true,
    },
    date: { type: 'Date', label: '請款日期', creator: '$$TODAY' },
    dueDate: { type: 'Date', label: '付款期限' },
    start: { type: 'Date', label: '開始日期' },
    end: { type: 'Date', label: '結束日期' },
    status: {
      type: 'String',
      label: '狀態',
      creator: '未收',
      options: ['未收', '確認', '已請款', '已收款'],
    },
    amount: { type: 'Number', label: '總計' },
    note: { type: 'String', label: '備註' },
    customerRef: { type: 'ObjectId', label: '客戶', reference: 'customers', refKeys: ['key'] },
    customer: {
      type: 'Object',
      contents: {
        key: { name: '客戶名稱', type: 'String' },
        contact: { name: '聯絡人', type: 'String' },
        phone: { name: '電話', type: 'String' },
        secPhone: { name: '備用電話', type: 'String' },
        fax: { name: '傳真', type: 'String' },
        email: { name: 'Email', type: 'String' },
        zipCode: { name: '郵遞區號', type: 'String' },
        address: { name: '地址', type: 'String' },
        invoiceTitle: { name: '發票抬頭', type: 'String' },
        taxId: { name: '統編', type: 'String' },
        bankAccount: { name: '銀行帳戶', type: 'String', input: 'textarea' },
      },
    },
    descriptionOfInvoice: { type: 'String', label: '發票品項' },
    receivableRefs: {
      type: 'Array',
      contents: [{ type: 'ObjectId', reference: 'receivables' }],
    },
    invoices: {
      type: 'Array',
      contents: [invoiceSchema],
    },
  },
}

module.exports = { recStatement }
