const mergeConfig = require('coact/config/mergeConfig')

const Invoice = require('~/src/invoice/printer/Invoice').default

const TestInvoicePage = require('./TestInvoicePage').default
// const UploadInvoiceRange = require('./selfChecker/UploadInvoiceRange').default
// const InvoiceDailyReport = require('./selfChecker/InvoiceDailyReport').default


// TODO move printers to view, require('twInvoice/Invoice')
const config = {
  printers: {
    'retailOrders:cashier': Invoice,
    'salesOrders:main': Invoice,
    'recStatements:main': Invoice,
    'invoices:issuer': Invoice,
  },
  routes: {
    testInvoicePrinter: {
      title: '電子發票印表機測試',
      path: '/route/testInvoicePrinter',
      component: TestInvoicePage,
    },
  },
  // routes: {
  //   uploadInvoiceRange: {
  //     title: '上傳發票字軌號碼',
  //     path: '/route/uploadInvoiceRange',
  //     component: UploadInvoiceRange,
  //   },
  //   invoiceDailyReport: {
  //     title: '發票日報表',
  //     path: '/route/invoiceDailyReport',
  //     component: InvoiceDailyReport,
  //   },
  // },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
