const R = require('ramda')

function reduceStock (stock, ctx) {
  const { qtyBySize } = stock
  const qty = R.sum(R.map(R.nth(1), R.toPairs(qtyBySize)))

  if (ctx.hideEmpty && qty === 0) return null

  return {
    ...stock,
    span: 1,
    qty,
  }
}

function reduceColor (color, ctx) {
  if (color.stocks.length === 0) {
    if (ctx.hideEmpty) return null
    const result = { qty: 0, qtyBySize: { }, span: 1 }
    return R.mergeRight(color, result)
  }

  const result = R.reduce(
    (acc, stock) => {
      const reduced = reduceStock(stock, ctx)

      if (reduced === null) return acc

      const { qty, qtyBySize, span } = reduced

      acc.qtyBySize = R.mergeWith(R.add, acc.qtyBySize, qtyBySize)
      acc.qty += qty
      acc.span += span
      acc.stocks.push(reduced)
      return acc
    },
    { qty: 0, qtyBySize: { }, span: 0, stocks: [] },
    color.stocks,
  )

  if (ctx.hideEmpty && result.qty === 0) return null

  return R.mergeRight(color, result)
}

function reduceProduct (product, ctx) {
  if (product.colors.length === 0) {
    if (ctx.hideEmpty) return null

    const result = { qty: 0, qtyBySize: { }, span: 1 }
    return R.mergeRight(product, result)
  }

  const result = R.reduce(
    (acc, color) => {
      const reduced = reduceColor(color, ctx)

      if (reduced === null) return acc

      const { qty, qtyBySize, span } = reduced

      acc.qtyBySize = R.mergeWith(R.add, acc.qtyBySize, qtyBySize)
      acc.qty += qty
      acc.span += span
      acc.colors.push(reduced)
      return acc
    },
    { qty: 0, qtyBySize: { }, colors: [], span: 0 },
    product.colors,
  )

  if (ctx.hideEmpty && result.qty === 0) return null

  return R.mergeRight(product, result)
}

module.exports = function buildRenderData ({ product, hideEmpty }) {
  return reduceProduct(product, { hideEmpty })
}
