const mergeSystemFields = require('../../utils/schema/mergeSystemFields')

const discountParser = require('../modules/discountParser')


const schema = mergeSystemFields({
  type: 'Object',
  contents: {
    orderRef: {
      type: 'ObjectId',
      reference: 'salesOrders',
      view: 'main',
      refKeys: ['key'],
      label: '銷貨單號',
    },
    skuRef: {
      type: 'ObjectId',
      reference: 'skus',
      refKeys: ['key'],
      label: '單品',
      width: 350,
    },
    sku: {
      type: 'Object',
      contents: {
        key: { type: 'String', label: '單品編碼' },
        barcode: { type: 'String' },
      },
    },
    name: { type: 'String', label: '名稱', width: 350 },
    qty: { type: 'Number', label: '數量', width: 50 },
    price: { type: 'Number', label: '單價', width: 70 },
    subtotal: { type: 'Number', label: '小計', width: 70 },
    note: { type: 'String', label: '項目備註' },
    retailPrice: { type: 'Number', label: '建議售價', width: 70 },
    discountRate: {
      type: 'Number', label: '折扣', format: '0%', width: 70, parser: discountParser,
    },
    priceDoc: { type: 'Object' },
    stockRecordRefs: {
      type: 'Array',
      contents: [{
        type: 'ObjectId',
        label: '修改庫存紀錄',
        reference: 'stockRecords',
        refKeys: ['stockRef'],
      }],
    },
    salesReqItemRef: { type: 'ObjectId', reference: 'salesReqItems' },
  },
})

const index = {
  skuRef: {
    key: { skuRef: 1 },
  },
  salesReqItemRef: {
    key: { salesReqItemRef: 1 },
  },
}

module.exports = {
  title: '銷貨項目',
  schema,
  index,
  list: {
    ui: {
      // fields: ['orderRef', 'skuRef', 'name', 'qty', 'price', 'subtotal', 'note'],
      fields: [
        { path: 'orderRef.key', model: 'salesOrders', queryPath: 'orderRef' },
        { path: 'orderRef.date', model: 'salesOrders', queryPath: 'orderRef' },
        { path: 'orderRef.buyerRef', model: 'salesOrders', queryPath: 'orderRef' },
        'sku.key', 'name', 'qty', 'price', 'subtotal', 'note',
      ],
    },
    populations: 'salesOrders,skus,customers',
    populationdepth: 2,
  },
  edit: {
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['orderRef.key'],
          [{ path: 'sku.key', span: 3 }],
          [{ path: 'name', span: 3 }],
          ['qty', 'price', 'subtotal'],
          ['note'],
        ],
      },
    },
    // populations: 'salesOrders,skus,customers',
    // populationdepth: 2,
  },
  options: {
    customers: { },
  },
  export: {
    populations: 'salesOrders,skus,customers,products,pdtSeries,pdtTypes,skuSizes,skuColors',
    populationdepth: 3,
    fields: [
      '_id',
      'orderRef.key',
      'orderRef.date',
      'orderRef.status',
      'orderRef.buyerRef.key',
      'skuRef.key',
      'skuRef.productRef.key',
      'skuRef.productRef.pdtSerieRef.key',
      'skuRef.productRef.pdtTypeRef.key',
      'skuRef.spec.skuSizeRef.key',
      'skuRef.spec.skuColorRef.key',
      'name',
      'qty',
      'price',
      'subtotal',
      'note',
    ],
  },
}
