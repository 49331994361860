import compose from 'recompose/compose'
import withState from 'recompose/withState'
import withHandlers from 'recompose/withHandlers'
import lifecycle from 'recompose/lifecycle'

import { connect } from 'react-redux'

import { get } from 'coact/utils/api'
import * as $message from 'coact/store/message'

import * as R from 'coact/ramda'

import View from './Page'


function countSizeMap (oriSizes) {
  const fullMapData = [
    [null],
    ['UNI'],
    ['3XS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL'],
    ['EU36', 'EU37', 'EU38', 'EU39', 'EU40', 'EU41', 'EU42', 'EU43', 'EU44', 'EU45', 'EU46'],
    ['3/4Y', '4/5Y', '5/6Y', '6/7Y', '7/8Y', '8/9Y', '9/10Y', '10/11Y', '11/12Y',
      '12/13Y', '13/14Y'],
    ['26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
    ['26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46'],
    ['70mm', '80mm', '90mm', '100mm', '110mm', '120mm'],
    ['145mm', '155mm', '160mm', '165mm'],
    [null, null, null, 'JS', 'JM', 'JL'],
  ]
  const fullMap = R.transpose(fullMapData)
  const sizes = R.uniq(oriSizes) || [null]
  const sizeHead = new Array(12)
  const noPosAssignedSizes = []
  sizes.forEach((s) => {
    const pos = fullMap.findIndex(map => (map.includes(s) || map.includes(s.split('/')[0])))
    if (pos === -1) noPosAssignedSizes.push(s)
    else sizeHead[pos] = s
  })
  noPosAssignedSizes.forEach((s) => {
    const pos = sizeHead.findIndex(e => R.isNil(e))
    if (pos === -1) throw new Error('沒位置放 size 啦')
    sizeHead[pos] = s
  })
  const result = {}
  sizeHead.forEach((s, i) => {
    if (s) result[i] = s
  })
  return result
}


const StocksList = compose(
  connect(),

  withState('seriesData', 'setSeriesData', []),
  withState('productsData', 'setProductsData', []),
  withState('stocksData', 'setStocksData', []),
  withState('curSeries', 'setCurSeries', '全部系列'),
  withState('search', 'setSearch', ''),
  withState('hideEmpty', 'setHideEmpty', false),
  withState('sortBy', 'setSortBy', null),
  withState('isLoading', 'setIsLoading', true),

  withHandlers({
    setSortBy: ({ setSortBy, setStocksData }) => (sortBy) => {
      setSortBy(sortBy)
      setStocksData(data => R.sortBy(R.prop(sortBy))(data))
    },
  }),

  withHandlers({
    getStocks: props => async (query) => {
      const {
        setStocksData, productsData, setSortBy, setSearch, setIsLoading, dispatch,
      } = props
      if (!query || R.isEmpty(query) || (!query.products && !query.series)) return setStocksData([])

      setIsLoading(true)
      try {
        const { products: productsId, prices, skus, stocks } = await get({
          url: '/api/route/publicStocks',
          search: {
            query,
          },
        })
        const stocksData = productsId.map((pdtId) => {
          const pdt = productsData.find(p => p._id === pdtId)

          const pdtStocks = stocks.filter(s => s.product === pdt.productKey)
          const pdtSkus = skus.filter(s => s.productRef._id === pdtId)
          const pdtColors = R.uniq(pdtSkus.map((s) => {
            const c = s.spec && s.spec.skuColorRef
            return c ? { key: c.key, name: c.name } : { key: '' }
          }))
          const sizes = R.uniq(pdtSkus.map(s => (
            s.spec && s.spec.skuSizeRef && s.spec.skuSizeRef.key
          )))
          const stocksGroupByColor = R.groupBy(s => s.color)(pdtStocks)

          function transformStocks (colorKey) {
            const stocksArr = stocksGroupByColor[colorKey] || []
            return stocksArr.length > 0
              ? R.zipObj(stocksArr.map(s => s.size), stocksArr.map(s => s.qty))
              : {}
          }

          const price = prices.find(({ productRef }) => productRef === pdtId)
          return {
            ...pdt,
            sizeMap: countSizeMap(sizes),
            groupByColor: R.zipObj(
              pdtColors.map(c => c.key),
              pdtColors.map(c => ({
                color: c.key,
                colorName: c.name,
                stocks: transformStocks(c.key),
                subtotal: R.sum((stocksGroupByColor[c.key] || []).map(s => s.qty)),
              })),
            ),
            total: R.sum(pdtStocks.map(s => s.qty)),
            price: price && price.price,
          }
        })
        setStocksData(stocksData)
        setSearch('')
        setSortBy('pdtTypeOrder')
      } catch (response) {
        dispatch($message.act.send({ type: 'error', message: response || '讀取失敗' }))
      }
      return setIsLoading(false)
    },
  }),

  lifecycle({
    async componentDidMount () {
      const { setSeriesData, setProductsData, setIsLoading, dispatch } = this.props
      try {
        const { pdtSeries: series, products } = await get({ url: '/api/route/publicProducts' })
        // const mergeSeries = series.filter(s => s.mergeInPublicStock).map(s => s._id)

        setSeriesData([{ key: '全部系列', hideEmpty: false },
          ...series.filter(s => !s.mergeInPublicStock).map(s => ({
            ...s,
            // products: products.filter(p => p.pdtSerieId === s._id).map(p => p._id),
            series: [s._id],
            hideEmpty: false,
          })),
          {
            key: '清倉商品',
            // products: products.filter(p => mergeSeries.includes(p.pdtSerieId)).map(p => p._id),
            series: series.filter(s => s.mergeInPublicStock).map(s => s._id),
            hideEmpty: true,
          },
        ])
        setProductsData(products)
        setIsLoading(false)
      } catch (response) {
        console.log(response)
        setIsLoading(false)
        dispatch($message.act.send({ type: 'error', message: '讀取失敗' }))
      }
    },
  }),
)(View)

StocksList.displayName = 'StocksList'

export default StocksList
