const R = require('ramda')

const fetchProductPrice = require('../../../../models/modules/fetchProductPrice')
const printExcel = require('../../../../models/modules/printExcel')

// handle product price
async function findProductPrice ({ value: skuDoc, path }, ctx) {
  const doc = ctx.getDoc()
  const { pricebookRef: pricebookDoc } = doc

  const { bookPriceDoc } = await fetchProductPrice({ skuDoc, pricebookDoc }, ctx)

  const bookPrice = R.propOr(0, 'price', bookPriceDoc)
  const price = bookPrice

  const itemPath = R.dropLast(1, path)
  ctx.mergePath({ path: itemPath, value: { price } })
}
function changePricebook (spec, ctx) {
  const items = R.path(['items'], ctx.getDoc())

  // TODO fetch prices in one api.get
  ctx.resetPath({ path: ['items'], value: items })
}

function downloadExcel (s, ctx) {
  const { doc } = ctx
  const { key, sellerRef } = doc
  const filename = `${key}_${sellerRef.key}.xlsx`
  printExcel({ doc, filename, template: 'purchaseReqForm' })
}

module.exports = {
  watcher: {
    pricebookRef: { name: 'changePricebook' },
    'items.0.skuRef': { name: 'findProductPrice' },
  },
  actions: {
    downloadExcel: {
      text: '列印',
      effect: { name: 'downloadExcel' },
      props: { variant: 'outlined', color: 'primary' },
      hotkey: 'ctrl+p',
    },
    saveAndCopy: {
      authChecker: () => null,
    },
  },
  effect: {
    findProductPrice,
    changePricebook,
    downloadExcel,
  },
}
