const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const schema = require('../../utils/schema')

const purchaseReqs = require('./purchaseReqs')
const purchaseItems = require('./purchaseItems')
const purchaseOrders = require('./purchaseOrders')
const purchaseOrderItems = require('./purchaseOrderItems')


const adaptClientModel = R.over(R.lensProp('schema'), schema.adaptClient)

const config = {
  models: {
    purchaseReqs: adaptClientModel(purchaseReqs),
    purchaseItems: adaptClientModel(purchaseItems),
    purchaseOrders: adaptClientModel(purchaseOrders),
    purchaseOrderItems: adaptClientModel(purchaseOrderItems),
  },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
