const R = require('ramda')

const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const $arrivalProcessData = require('../../../arrivalProcess/dataAccess')

const $purchaseOrder = require('../../../purchaseOrder')

const schema = R.pipe(
  R.assocPath(
    toSchemaPath(['items', 0, 'reqRef']),
    $arrivalProcessData.schema.reqRef,
  ),
)($purchaseOrder.dataAccess.schema)


module.exports = schema
