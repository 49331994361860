import { compose, withProps, withHandlers } from 'recompose'

import { connect } from 'react-redux'

import * as R from 'coact/ramda'

import { createAct } from 'coact/utils/action'

import View from './StocksEditor'


export const act = createAct({
  namespace: ['hehong', 'StocksEditor'],
  types: ['findStocks', 'saveStocks'],
})

const schema = {
  type: 'Object',
  contents: {
    productRef: {
      type: 'ObjectId',
      reference: 'products',
      label: '商品型號',
      refKeys: ['key'],
    },
    warehouseRef: {
      type: 'ObjectId',
      reference: 'warehouses',
      label: '倉庫',
      refKeys: ['key'],
      autoSelect: 'HH',
    },
    stocks: {
      type: 'Array',
      contents: [{
        type: 'Object',
        computer: (value) => {
          const inStockQty = R.pathOr(0, ['inStockQty'], value)
          const qty = R.pathOr(0, ['stock', 'qty'], value)
          return { difference: inStockQty - qty }
        },
        contents: {
          spec: { type: 'Object' },
          stock: {
            type: 'Object',
            contents: {
              qty: { type: 'Number', label: '數量' },
            },
          },
          inStockQty: { type: 'Number', label: '盤點數量' },
          difference: { type: 'Number', label: '盤差' },
          note: { type: 'String', label: '備註' },
        },
      }],
    },
  },
  watcher: { name: 'findStocks' },
}

const container = compose(
  connect(),

  withProps({ schema }),

  withHandlers({
    save: ({ dispatch }) => () => dispatch(act.saveStocks()),
  }),
)

export default container(View)
