import React from 'react'
import { any, array, string, object, func, bool } from 'prop-types'

import * as R from 'ramda'

import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

import Close from '@material-ui/icons/Close'

import buildPropsOfRows from './buildPropsOfRows'
import buildRenderData from './buildRenderData'


const SIZE_COLS = R.range(0, 12)

function Cell (props) {
  const { children, header, style, ...others } = props
  const styles = {
    ...style,
    border: '1px solid gray',
    width: 'fit-content',
    textAlign: 'center',
    padding: 3,
    backgroundColor: header ? '#EAF2D3' : 'none',
  }
  return header
    ? <th style={styles} {...others}>{children}</th>
    : <td style={styles} {...others}>{children}</td>
}
Cell.propTypes = {
  children: any,
  header: bool,
}

const Row = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#FFCCFF',
    },
  },
})(TableRow)

function ImageModal (props) {
  const { imageModal, hideModal } = props
  return !imageModal ? null : (
    <Modal open={!!imageModal} onClose={hideModal}>
      <div style={{ width: 600, maxWidth: '100%', margin: '30px auto', backgroundColor: 'white' }}>
        <div style={{ display: 'flex' }}>
          <Typography variant="title" style={{ flexGrow: 1 }}>{imageModal}</Typography>
          <Close style={{ cursor: 'pointer' }} onClick={hideModal} />
        </div>
        <Divider />
        <img
          src={`http://www.hehong.com.tw/stock_img_no_space/${imageModal}.jpg`}
          alt="商品圖片整理中......"
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  )
}
ImageModal.propTypes = {
  imageModal: string,
  hideModal: func.isRequired,
}

function Header ({ sizeMap }) {
  return (
    <tr>
      <Cell header>型號</Cell>
      <Cell header>中文品名</Cell>
      <Cell header style={{ minWidth: 40 }}>類別</Cell>
      <Cell header style={{ minWidth: 40 }}>Col.</Cell>
      <Cell header style={{ minWidth: 40 }}>顏色</Cell>
      <Cell header style={{ width: 60 }}>圖片</Cell>
      <Cell header style={{ width: 60 }}>倉庫</Cell>
      {SIZE_COLS.map(index => (
        (<Cell header key={index} style={{ minWidth: 40 }}>{sizeMap[index] || ''}</Cell>)
      ))}
      <Cell header style={{ minWidth: 40 }}>小計</Cell>
      <Cell header style={{ minWidth: 40 }}>總計</Cell>
      <Cell header style={{ width: 90 }}>建議售價</Cell>
    </tr>
  )
}
Header.propTypes = {
  sizeMap: object.isRequired,
}

function renderSizeCells ({ qtyBySize, sizeMap }) {
  return SIZE_COLS.map((position) => {
    const size = sizeMap[position]
    const qty = R.pathOr(null, [size], qtyBySize)
    return <Cell key={position}>{qty}</Cell>
  })
}
function renderStockCells ({ product: p, stock: s }) {
  return {
    cells: () => [
      <Cell key="swarehouse">{s.key}</Cell>,
      ...renderSizeCells({ qtyBySize: s.qtyBySize, sizeMap: p.sizeMap }),
    ],
  }
}
function renderColorCells ({ color: c, product: p, showModal }) {
  const imgName = p.key + (c.key ? `-${c.key}` : '')

  const left = () => [
    <Cell key="ckey" rowSpan={c.span}>{c.key}</Cell>,
    <Cell key="cname" rowSpan={c.span}>{c.name}</Cell>,
    <Cell key="cpic" rowSpan={c.span}><button type="button" onClick={() => showModal(imgName)}>圖片</button></Cell>,
  ]
  const sizes = () => [
    <Cell key="cwarehouse" />,
    ...renderSizeCells({ qtyBySize: c.qtyBySize, sizeMap: p.sizeMap }),
  ]
  const right = () => [
    <Cell key="cqty" rowSpan={c.span}>{c.qty}</Cell>,
  ]

  const cells = () => [].concat(left(), sizes(), right())

  return { left, right, cells }
}
function renderProductCells ({ product: p }) {
  const left = () => [
    <Cell key="pkey" rowSpan={p.span}>{p.key}</Cell>,
    <Cell key="pname" rowSpan={p.span}>{p.name}</Cell>,
    <Cell key="ptype" rowSpan={p.span}>{p.type}</Cell>,
  ]

  const sizes = () => [
    <Cell key="ckey" />,
    <Cell key="cname" />,
    <Cell key="cpic" />,

    <Cell key="cwarehouse" />,
    ...renderSizeCells({ qtyBySize: p.qtyBySize, sizeMap: p.sizeMap }),

    <Cell key="cqty" />,
  ]

  const right = () => [
    <Cell key="pqty" rowSpan={p.span}>{p.qty}</Cell>,
    <Cell key="pprice" rowSpan={p.span}>{R.pathOr(null, ['prices', 0, 'price'], p)}</Cell>,
  ]

  const cells = () => [].concat(left(), sizes(), right())

  return { left, right, cells }
}

function renderCells ({ row, showModal }) {
  const { path, render } = row

  const rowProps = { ...path, showModal }

  const p = render.product ? renderProductCells(rowProps) : null
  const c = render.color ? renderColorCells(rowProps) : null
  const s = render.stock ? renderStockCells(rowProps) : null

  let LeftCells = []
  let RightCells = []
  if (p) {
    if (c) {
      LeftCells = [].concat(LeftCells, p.left())
      RightCells = [].concat(p.right(), RightCells)
    } else {
      return p.cells()
    }
  }

  if (c) {
    if (s) {
      LeftCells = [].concat(LeftCells, c.left())
      RightCells = [].concat(c.right(), RightCells)
    } else {
      return [].concat(LeftCells, c.cells(), RightCells)
    }
  }

  return [].concat(LeftCells, s.cells(), RightCells)
}

function ProductRow (props) {
  const { showModal, hideEmpty, product } = props

  const renderData = buildRenderData({ product, hideEmpty })

  if (renderData === null) return null

  const rows = buildPropsOfRows({ product: renderData })

  const Rows = rows.map((row) => {
    const Cells = renderCells({ row, showModal })

    const key = R.pipe(
      R.toPairs,
      R.map(pair => pair[1].key),
      R.join('-'),
    )(row.path)

    return <Row key={key}>{Cells}</Row>
  })

  if (Rows.length === 0) return null

  return (
    <React.Fragment>
      <Header sizeMap={product.sizeMap} />
      {Rows}
    </React.Fragment>
  )
}
ProductRow.propTypes = {
  product: object.isRequired,
  showModal: func.isRequired,
  hideEmpty: bool.isRequired,
}

function notFound (product, searchOri) {
  const search = searchOri.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const re = new RegExp(search, 'i')
  const { key, name, type } = product
  if (re.test(key || '')) return false
  if (re.test(name || '')) return false
  if (re.test(type || '')) return false
  return true
}
function StocksTable (props) {
  const { stocksData, showModal, search, hideEmpty } = props
  return (
    <div>
      <ImageModal {...props} />
      <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
        <tbody>
          {
            stocksData.map((product) => {
              if ((search !== null) && notFound(product, search)) return null

              return (
                <ProductRow
                  key={product.key}
                  {...{ showModal, product, hideEmpty }}
                />
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}
StocksTable.propTypes = {
  stocksData: array.isRequired,
  showModal: func.isRequired,
  search: string,
  hideEmpty: bool.isRequired,
}

export default StocksTable
