const schema = {
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      unique: true,
      label: '人員名稱',
    },
    phone: {
      type: 'String',
      label: '電話',
    },
    secPhone: {
      type: 'String',
      label: '備用電話',
    },
    email: {
      type: 'String',
      label: 'Email',
    },
    accountRef: {
      type: 'ObjectId',
      label: '使用帳號',
      reference: 'users',
      refKeys: ['account'],
    },
  },
}

module.exports = schema
