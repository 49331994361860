// const R = require('ramda')
// const moment = require('moment')

// const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const invalidInvoice = require('./invalidInvoice')
const saveAndPrintInvoice = require('./saveAndPrintInvoice')
// const dateFormatter = require('~/src/utils/format/dateFormatter')

const Issuer = require('./Issuer').default

// function computSubtotal () {
// }

module.exports = {
  ...require('./auto'),
  actions: {
    save: {
      authChecker: () => null,
      hotKey: null,
    },
    saveAndCreate: {
      authChecker: () => null,
    },
    saveAndCopy: {
      authChecker: () => null,
    },
    saveAndPrintInvoice: {
      text: '開立發票',
      effect: { name: 'saveAndPrintInvoice' },
      props: { variant: 'contained', color: 'primary' },
      hotKey: 'ctrl+enter',
    },
    invalidInvoice: {
      text: '作廢發票',
      effect: { name: 'invalidInvoice' },
      props: { variant: 'outlined', color: 'primary' },
    },
  },
  effect: {
    saveAndPrintInvoice,
    invalidInvoice,
    // invalidInvoice: function* invalidInvoiceEffect (payload, ctx) {
    //   yield ctx.S.call(invalidInvoice, payload.id)
    //   yield ctx.S.call(editEffects.save)
    // },
  },
  disabled: {
    src: true,
    'invoice.no': true,
    'invoice.yearmonth': true,
    'invoice.date': true,
    'invoice.canceledAt': true,
    'invoice.amount.total': true,
    'invoice.items.0.amount': true,
  },
  required: {
    'invoice.items.0.description': true,
    'invoice.items.0.price': true,
    'invoice.items.0.quantity': true,
  },
  creator: {
    'invoice.items.0.quantity': 1,
  },
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['invoice.no', 'invoice.yearmonth', 'src'],
        ['invoice.date', 'invoice.canceledAt', 'invoice.amount.total'],
        ['invoice.buyer.id', 'invoice.carrier.type', 'invoice.carrier.id1', 'invoice.donation'],
        [{ path: 'meta.note', span: 3, multiline: true }],
      ],
    },
    items: {
      name: 'tableEditor',
      path: 'invoice.items',
      fields: [
        { path: 'description', width: 300 },
        { path: 'price', width: 70 },
        { path: 'quantity', width: 50 },
        { path: 'amount', width: 70 },
      ],
      deletable: false,
      copyable: false,
    },
  },
  Components: {
    PageForm: Issuer,
    PageActions: () => null,
  },
}
