const salesReqs = require('./salesReqs')
const salesReqItems = require('./salesReqItems')


// export config.models
module.exports = {
  models: {
    salesReqs: {
      ...salesReqs,
      views: {
        main: {
          options: {
            skus: { },
          },
        },
      },
    },
    salesReqItems,
  },
}
