const schema = require('./schema')

const model = {
  title: '應收對帳單',
  schema: schema.recStatement,
}

module.exports = {
  models: {
    recStatements: {
      ...model,
      views: {
        main: {
          data: {
            bundle: {
              receivables: { onRemove: 'setNull' },
            },
          },
          options: {
            salesOrders: { },
          },
        },
      },
    },
  },
}
