const R = require('ramda')

const { builtinEffects: editEffects } = require('coact/saga/editSaga')


function shouldPrint (invoice) {
  const hasBuyer = R.path(['buyer', 'id'], invoice).length === 8
  const hasCarrier = !!R.path(['carrier', 'type'], invoice)
  const hasDonation = !!R.path(['donation'], invoice)

  if (hasBuyer) return true

  if (hasCarrier || hasDonation) return false

  return true
}

module.exports = function* saveAndPrintInvoice (payload, ctx) {
  // save and return invoice data
  const doc = yield ctx.S.call(editEffects.save)

  if (doc) {
    yield ctx.S.delay(1000)
    const { invoice } = doc
    if (shouldPrint(invoice)) {
      ctx.resetPath({ path: ['invoiceOfPrinting'], value: invoice })
      yield ctx.S.delay(1000)

      window.print()
    }
    ctx.history.push(`/edit/invoices/view/issuer/${doc._id}`)
  }
}
