module.exports = {
  title: '倉庫資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        label: '倉庫編號',
        type: 'String',
        unique: true,
        required: true,
        ragicField: 1000040,
      },
      name: {
        label: '倉庫名稱',
        type: 'String',
        unique: true,
        required: true,
        ragicField: 1000041,
      },
      '@order': {
        name: '排序',
        type: 'Number',
      },
      contact: { type: 'String', label: '聯絡人' },
      phone: { type: 'String', label: '電話' },
      email: { type: 'String', label: 'Email' },
      fax: { type: 'String', label: '傳真' },
      address: { type: 'String', label: '地址' },
      syncAt: {
        label: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        label: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: ['key', 'name', '@order'],
    },
  },
  ui: {
    editor: {
      config: { nameWidth: 120 },
      groups: [
        {
          name: '',
          rows: [
            [{ key: 'key' }],
            [{ key: 'name' }],
          ],
        },
      ],
    },
  },
}
