const R = require('coact/ramda')

const mergeConfig = require('coact/config/mergeConfig')
const tradingFormModule = require('coact/modules/tradingForm/main')
const stockRecorderModule = require('coact/modules/stockRecorder/main')

const buildSkuName = require('./modules/buildSkuName')
const printExcel = require('./modules/printExcel')


const orderSpec = {
  db: {
    orders: 'transferOrders',
    items: 'transferOrderItems',
    buyers: 'customers',
    sellers: 'customers',
    skus: 'skus',
  },
  project: {
    skus: ['key'],
  },
  orderNo: { prefix: 'TO', format: 'YYMMDD', digits: 3 },
  productReader: {
    formatter: buildSkuName,
    queryKey: 'barcode',
  },
}
const changeStockSpec = {
  db: {
    stocks: 'stocks',
    records: 'stockRecords',
    orders: 'transferOrders',
    items: 'transferOrderItems',
  },
  payload: {
    stock: {
      src: ({ doc, item }) => {
        if (!doc.srcWarehouseRef) return null
        if (doc.status !== '完成') return null
        return { warehouseRef: doc.srcWarehouseRef._id, skuRef: item.skuRef._id }
      },
      des: ({ doc, item }) => {
        if (!doc.desWarehouseRef) return null
        if (doc.status !== '完成') return null
        return { warehouseRef: doc.desWarehouseRef._id, skuRef: item.skuRef._id }
      },
    },
    record: {
      src: ({ doc, item }) => ({
        qty: -item.qty,
        date: doc.date,
        content: '調貨出庫',
      }),
      des: ({ doc, item }) => ({
        qty: item.qty,
        date: doc.date,
        content: '調貨入庫',
      }),
    },
  },
  refPath: {
    item: {
      record: 'stockRecordRefs',
    },
    record: {
      stock: 'stockRef',
      item: 'srcDBRef',
    },
  },
}


const mainView = {
  title: '調貨單',
  edit: {
    autofill: {
      'items.0': {
        src: 'skuRef',
        fill: sku => ({
          sku,
          name: buildSkuName(sku),
        }),
      },
    },
    actions: {
      downloadExcel: {
        text: '列印',
        effect: { name: 'downloadExcel' },
        props: { variant: 'outlined', color: 'primary' },
      },
      completeOrder: {
        text: '交易完成',
        effect: { name: 'completeOrder' },
        props: { variant: 'contained', color: 'primary' },
      },
      cancelOrder: {
        text: '交易取消',
        effect: { name: 'cancelOrder' },
      },
    },
    effect: {
      downloadExcel: (s, ctx) => {
        const { doc } = ctx
        const { key, srcWarehouseRef, desWarehouseRef } = doc
        const filename = `${key}_${srcWarehouseRef.key}_${desWarehouseRef.key}.xlsx`
        printExcel({ doc, filename, template: 'transferOrderForm' })
      },
      completeOrder: (s, ctx) => {
        ctx.editPath({ path: ['status'], value: '完成' })
        ctx.saveDoc()
      },
      cancelOrder: (s, ctx) => {
        ctx.editPath({ path: ['status'], value: '取消' })
        ctx.saveDoc()
      },
    },
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'status'],
          ['personInCharge'],
          ['srcWarehouseRef', 'desWarehouseRef'],
          [{ path: 'note', span: 3 }],
          ['summary'],
        ],
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        fields: ['skuRef', 'name', 'qty', 'note'],
        deletable: true,
        copyable: false,
      },
    },
    populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        'key', 'date', 'status', 'personInCharge',
        'srcWarehouseRef', 'desWarehouseRef', 'summary', 'note',
      ],
    },
  },
  options: {
    skus: { },
  },
}

const models = {
  transferOrders: {
    title: '調貨單',
    schema: {
      type: 'Object',
      contents: {
        personInCharge: {
          type: 'ObjectId',
          label: '負責人',
          reference: 'staffers',
          refKeys: ['key'],
        },
        srcWarehouseRef: {
          type: 'ObjectId',
          reference: 'warehouses',
          refKeys: ['key'],
          label: '出庫',
        },
        desWarehouseRef: {
          type: 'ObjectId',
          reference: 'warehouses',
          refKeys: ['key'],
          label: '入庫',
        },
        srcWarehouse: { type: 'Object' },
        desWarehouse: { type: 'Object' },
      },
    },
    views: {
      main: mainView,
    },
  },
  transferOrderItems: {
    title: '調貨項目',
    schema: {
      contents: {
        stockRecordRefs: {
          type: 'Array',
          contents: [{
            type: 'ObjectId',
            label: '修改庫存紀錄',
            reference: 'stockRecords',
            refKeys: ['stockRef'],
          }],
        },
      },
    },
    list: {
      ui: {
        fields: ['orderRef', 'skuRef', 'name', 'qty', 'subtotal', 'note'],
      },
      populations: 'staffers,stocks',
    },
  },
}


// module.exports = R.reduce(
const fullModels = R.reduce(
  mergeConfig,
  { },
  [
    tradingFormModule(orderSpec),
    stockRecorderModule(changeStockSpec),
    { models },
  ],
)

module.exports = fullModels
