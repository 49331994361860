import React from 'react'
import { string, func, bool } from 'prop-types'

function FilterList (props) {
  const { search, setSearch, hideEmpty, setHideEmpty, sortBy, setSortBy } = props
  const margin = '0 5px'

  return (
    <div>
      查詢型號:
      <div style={{ width: 200, display: 'inline-block' }}>
        <input
          type="text"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </div>
      <button
        type="button"
        onClick={() => setSortBy('pdtTypeOrder')}
        style={{ margin, color: sortBy === 'pdtTypeOrder' ? 'red' : 'inherit' }}
      >
        類別排序
      </button>
      <button
        type="button"
        onClick={() => setSortBy('name')}
        style={{ margin, color: sortBy === 'name' ? 'red' : 'inherit' }}
      >
        品名排序
      </button>
      |
      <button
        type="button"
        onClick={() => setHideEmpty(true)}
        style={{ margin, color: hideEmpty ? 'red' : 'inherit' }}
      >
        隱藏無庫存商品
      </button>
      <button
        type="button"
        onClick={() => setHideEmpty(false)}
        style={{ margin, color: !hideEmpty ? 'red' : 'inherit' }}
      >
        顯示所有商品
      </button>
    </div>
  )
}
FilterList.propTypes = {
  search: string,
  setSearch: func.isRequired,
  hideEmpty: bool,
  setHideEmpty: func.isRequired,
  sortBy: string,
  setSortBy: func.isRequired,
}

export default FilterList
