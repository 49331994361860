import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'coact/ramda'

import Button from '@material-ui/core/Button'

import Editor from 'coact/editors/Editor'
import Field from 'coact/editors/Field'
import ItemsEditor from 'coact/editors/ItemsEditor'


const renderItem = (editor) => {
  const { value = {} } = editor
  const color = R.pathOr('', ['displayColor'], value)
  const size = R.pathOr('', ['spec', 'skuSizeRef', 'key'], value)
  const qty = R.pathOr(null, ['stock', 'qty'], value)
  const difference = R.pathOr(0, ['difference'], value)

  return (
    <tr>
      <td>{color}</td>
      <td>{size}</td>
      <td>{qty}</td>
      <td><Field path="inStockQty" /></td>
      <td>{difference}</td>
      <td><Field path="note" /></td>
    </tr>
  )
}

function StocksEditor (props) {
  const { schema, save } = props
  return (
    <Editor schema={schema}>
      <React.Fragment>
        <h2>庫存盤點</h2>
        <div>
          <Field path="productRef" />
          <Field path="warehouseRef" />
          <table>
            <thead>
              <tr>
                <th style={{ width: 80 }}>顏色</th>
                <th style={{ width: 80 }}>尺寸</th>
                <th style={{ width: 80 }}>原有數量</th>
                <th style={{ width: 80 }}>盤點結果</th>
                <th style={{ width: 80 }}>盤差</th>
                <th style={{ width: 150 }}>備註</th>
              </tr>
            </thead>
            <tbody>
              <ItemsEditor path="stocks">
                {renderItem}
              </ItemsEditor>
            </tbody>
          </table>
        </div>
        <Button variant="contained" color="primary" onClick={save}>儲存</Button>
      </React.Fragment>
    </Editor>
  )
}
const { object, func } = PropTypes
StocksEditor.propTypes = {
  schema: object.isRequired,
  save: func.isRequired,
}

export default StocksEditor
