const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const itemsViewConfig = require('./itemsClient')

const config = R.assocPath(
  ['models', 'retailOrderItems'],
  itemsViewConfig,
  { },
)

module.exports = fullConfig => mergeConfig(fullConfig, config)
