const R = require('ramda')

module.exports = (deps, my) => async function insertWithPhoneOrMBarcode (doc) {
  const { phone, mobileBarcode } = doc
  if (R.isNil(phone) && R.isNil(mobileBarcode)) return null

  const { modelviews, user } = deps
  const model = modelviews.model('consumers', user)
  await my.checkUniqueFields(doc)
  return model.insert(doc)
}
