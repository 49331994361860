import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'

import Editor from 'coact/editors/Editor'
import createEffectAction from 'coact/pages/modules/pageAction/createEffectAction'
import toPath from 'coact/utils/toPath'

import FormEditor from 'coact/editors/FormEditor'
import TableEditor from 'coact/editors/TableEditor'


const { object, func } = PropTypes

function InvoiceTable (props) {
  const { ui, invalidInvoice } = props

  return (
    <Editor>
      {
        (editor) => {
          const invoices = R.pathOr([], ['value', 'invoices'], editor)

          return (
            <div>
              <table>
                <thead>
                  <tr>
                    {
                      ui.fields.map(({ label, width }) => (
                        <th key={label} style={{ width }}>
                          {label}
                        </th>
                      ))
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    invoices.map(invoice => (
                      <tr key={invoice._id}>
                        {
                          ui.fields.map(({ path }) => (
                            <td key={path}>
                              {R.path(toPath(path), invoice)}
                            </td>
                          ))
                        }
                        <td>
                          {
                            invoice.invoice.canceledAt ? '已作廢' : (
                              <button
                                type="button"
                                // eslint-disable-next-line no-alert
                                onClick={() => (window.confirm('確認作廢？') ? invalidInvoice(invoice._id) : null)}
                              >
                                作廢發票
                              </button>
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        }
      }
    </Editor>
  )
}
InvoiceTable.propTypes = {
  ui: object.isRequired,
  invalidInvoice: func.isRequired,
}
function RecStatementEditor (props) {
  const { page: { schema, ui }, dispatch } = props

  const invalidInvoice = id => (
    dispatch(createEffectAction({ name: 'invalidInvoice', id }))
  )

  return (
    <React.Fragment>
      <link href="https://fonts.googleapis.com/css?family=Libre+Barcode+39&display=swap" rel="stylesheet" />
      <FormEditor ui={ui.form} schema={schema} />
      <TableEditor ui={ui.recItems} schema={schema} />
      <InvoiceTable ui={ui.invoices} invalidInvoice={invalidInvoice} />
      <div style={{ fontFamily: '\'Libre Barcode 39\', cursive', fontSize: 1, color: 'white' }}>
        *font*
      </div>
      <div><img style={{ width: 1, height: 1 }} src="invoiceLogo.png" alt="禾宏文化" /></div>
    </React.Fragment>
  )
}

RecStatementEditor.propTypes = {
  page: object.isRequired,
  dispatch: func.isRequired,
}


export default RecStatementEditor
