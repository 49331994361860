/**
 * Data Access Interface
 */

const R = require('ramda')

const intf = require('./interface')

const implementBy = (impl) => {
  const dataAccess = intf(impl)

  Object.keys(dataAccess).forEach((key) => {
    if (!R.has(key, impl)) {
      throw new Error(`PurchaseOrderDataAccess implement needs ${key} function`)
    }
  })

  return dataAccess
}

module.exports = {
  implementBy,
  interface: () => intf(),
  schema: require('./schema'),
}
