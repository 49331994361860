import React from 'react'
import { func, object } from 'prop-types'

import Dropzone from 'coact/react-dropzone'

// TOOD wrap a Dropzone Component
const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
}
const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
}

function StreamData (props) {
  const {
    onDropAccepted, go, file,
    result,
  } = props

  const { errors = [], counter = 0 } = result

  return (
    <div>
      <h1>StreamData</h1>
      <Dropzone {...{ onDropAccepted }}>
        {
          ({ getRootProps, getInputProps, isDragActive }) => {
            const style = isDragActive ? { ...baseStyle, ...activeStyle } : baseStyle
            return (
              <div style={style} {...getRootProps()}>
                <input {...getInputProps()} />
                {file && file.name}
              </div>
            )
          }
        }
      </Dropzone>
      <div>
        <button type="button" onClick={go}> Go </button>
      </div>
      <div>
        共 {counter} 筆商品庫存修改
      </div>
      {
        errors.map((e, i) => (
          <div key={i}>{JSON.stringify(e)}</div>
        ))
      }
    </div>
  )
}
StreamData.propTypes = {
  onDropAccepted: func,
  go: func,
  file: object,
  result: object,
}

export default StreamData
