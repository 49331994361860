module.exports = {
  title: '供應商資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        name: '供應商名稱',
        type: 'String',
        unique: true,
        required: true,
      },
      code: { name: '供應商代號', type: 'String' },
      contact: { name: '聯絡人', type: 'String' },
      phone: { name: '電話', type: 'String' },
      secPhone: { name: '備用電話', type: 'String' },
      fax: { name: '傳真', type: 'String' },
      email: { name: 'Email', type: 'String' },
      zipCode: { name: '郵遞區號', type: 'String' },
      address: { name: '地址', type: 'String' },
      note: { name: '備註', type: 'String', input: 'textarea' },
      invoiceTitle: { name: '發票抬頭', type: 'String' },
      taxId: { name: '統編', type: 'String' },
      bankAccount: { name: '銀行帳戶', type: 'String', input: 'textarea' },
      paymentMethod: { name: '慣用付款方式', type: 'String' },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key', width: 500 }, 'code', 'contact', 'phone', 'email', 'address', 'note',
      ],
    },
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['key', 'code'],
          ['contact', 'phone', 'secPhone', 'fax'],
          [{ path: 'email', span: 4 }],
          ['zipCode', { path: 'address', span: 4 }],
          [{ path: 'note', span: 4 }],
          ['invoiceTitle', 'taxId'],
          ['bankAccount', 'paymentMethod'],
        ],
      },
    },
  },
}
