module.exports = {
  title: '單品尺寸資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        name: '尺寸',
        type: 'String',
        unique: true,
        required: true,
      },
      '@order': {
        name: '排序',
        type: 'Number',
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key' },
        { path: '@order' },
      ],
    },
  },
  ui: {
    editor: {
      config: {},
      groups: [
        {
          name: '',
          rows: [
            [{ key: 'key' }, { key: '@order' }],
          ],
        },
      ],
    },
  },
}
