const toId = id => `ObjectId('${id}')`

const getProductRef = (skuRef) => {
  if (typeof skuRef.productRef === 'string') {
    return toId(skuRef.productRef)
  }
  return toId(skuRef.productRef._id)
}

module.exports = async function fetchSkuPrices (spec, ctx) {
  const { skuRef } = spec
  const { api } = ctx

  const res = await api.get({
    url: api.url('prices'),
    search: {
      query: {
        productRef: getProductRef(skuRef),
      },
      populations: 'pricebooks',
      populationdepth: 1,
    },
  })

  return res.data
}
