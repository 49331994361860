const schema = {
  type: 'Object',
  contents: {
    key: {
      label: '單品編碼',
      type: 'String',
      unique: true,
      required: true,
      ragicField: 1000001,
      ragicParser: v => v.replace(/\s/g, ''),
    },
    barcode: {
      label: '單品條碼',
      type: 'String',
      ragicField: 1000002,
    },
    productRef: {
      label: '商品型號',
      type: 'ObjectId',
      reference: 'products',
      // required: true,
      refKeys: ['key'],
      ragicField: 1000014,
      ragicParser: v => v.replace(/\s/g, ''),
      ragicRefKeys: ['formattedKey'],
      view: 'main',
    },
    spec: {
      label: '規格',
      type: 'Object',
      contents: {
        skuSizeRef: {
          label: '尺寸',
          type: 'ObjectId',
          reference: 'skuSizes',
          refKeys: ['key'],
          ragicField: 1000019,
        },
        skuColorRef: {
          label: '色碼',
          type: 'ObjectId',
          reference: 'skuColors',
          refKeys: ['key', 'name'],
          ragicField: 1000142,
          ragicRefKeys: ['key'],
        },
      },
    },
    '@suspended': {
      type: 'Boolean', label: '停用',
    },
    syncAt: {
      label: '上次同步時間',
      type: 'Date',
      format: 'YYYY/MM/DD HH:mm:ss',
      disabled: true,
    },
    ragicId: {
      label: 'Ragic Id',
      type: 'Number',
      ragicField: '_ragicId',
      disabled: true,
    },
  },
}

module.exports = schema
