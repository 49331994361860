const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const formViewClientConfig = require('./formView/clientConfig')
const itemViewClientConfig = require('./itemView/clientConfig')

const config = mergeConfig(
  R.assocPath(
    ['models', 'purchaseReqs', 'views', 'form'],
    formViewClientConfig,
    { },
  ),
  R.assocPath(
    ['models', 'purchaseItems', 'views', 'item'],
    itemViewClientConfig,
    { },
  ),
)

module.exports = fullConfig => mergeConfig(fullConfig, config)
