import React from 'react'
import { array, func, string } from 'prop-types'


function SeriesList (props) {
  const { seriesData, chooseSeries, curSeries } = props
  const styleButton = {
    width: 120,
    height: 30,
    margin: 5,
  }

  return (
    <div>
      {seriesData.map((s) => {
        const color = s.key === curSeries ? 'red' : 'inherit'
        return (
          <button key={s.key} type="button" onClick={() => chooseSeries(s)} style={{ ...styleButton, color }}>
            {s.key}
          </button>
        )
      })}
    </div>
  )
}

SeriesList.propTypes = {
  chooseSeries: func.isRequired,
  seriesData: array.isRequired,
  curSeries: string.isRequired,
}

export default SeriesList
