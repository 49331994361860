const R = require('ramda')

const safeN = require('coact/utils/safeN')

module.exports = {
  '': [
    (value) => {
      const amount = R.reduce(
        (acc, { subtotal }) => (acc + subtotal),
        0,
        value.items || [],
      )
      return { amount }
    },
    (value) => {
      const total = R.reduce(
        (acc, { qty }) => acc + qty,
        0,
        value.items || [],
      )
      return { summary: `共 ${total} 個` }
    },
    (value) => {
      const receivable = R.reduce(
        (acc, { amount }) => (acc + amount),
        0,
        value.payments || [],
      )
      return { receivable }
    },
  ],
  'items.0': (value) => {
    const qty = safeN(value.qty)
    const price = safeN(value.price)
    const origin = safeN(R.path(['priceDoc', 'price'], value))
    const discountRate = origin === 0 ? null : (price / origin)

    return { subtotal: qty * price, discountRate }
  },
}
