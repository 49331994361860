const R = require('ramda')

const eqPaths = require('coact/utils/eqPaths')

const { builtinEffects: editEffects } = require('coact/saga/editSaga')
const $message = require('coact/store/message')

const fetchProductPrice = require('../../modules/fetchProductPrice')
const printExcel = require('../../modules/printExcel')

const createProductReceivable = require('./createProductReceivable')


// handle product price
async function findProductPrice ({ value: skuDoc, path }, ctx) {
  const doc = ctx.getDoc()
  const { pricebookRef: pricebookDoc } = doc

  const { retailPriceDoc, bookPriceDoc } = await fetchProductPrice({ skuDoc, pricebookDoc }, ctx)

  const retailPrice = R.prop('price', retailPriceDoc)
  // TODO rename priceDoc to bookPriceDoc
  const priceDoc = bookPriceDoc

  const discount = R.pathOr(1, ['discount'], doc)
  const bookPrice = R.propOr(0, 'price', bookPriceDoc)
  const price = Math.round(bookPrice * discount)

  const itemPath = R.dropLast(1, path)
  ctx.mergePath({ path: itemPath, value: { priceDoc, retailPrice, price } })
}
function changePricebook (spec, ctx) {
  const items = R.path(['items'], ctx.getDoc())

  // TODO fetch prices in one api.get
  ctx.resetPath({ path: ['items'], value: items })
}
function changeItemDiscount (spec, ctx) {
  const { path, value } = spec
  const discountRate = value || 0
  const itemPath = R.take(2, path)
  const bookPrice = R.pathOr(0, [...itemPath, 'priceDoc', 'price'], ctx.doc)
  const price = Math.round(bookPrice * discountRate)

  ctx.editPath({ path: [...itemPath, 'price'], value: price })
}
function changeDiscount ({ value }, ctx) {
  const items = R.path(['items'], ctx.getDoc())
  const discount = value || 0

  const itemPrices = R.map(
    (item) => {
      const origin = R.path(['priceDoc', 'price'], item)
      const price = origin ? Math.round(origin * discount) : null
      return { price }
    },
    items,
  )

  ctx.mergePath({ path: ['items'], value: itemPrices })
}

// handle receivables
function upsertProductReceivable (doc, ctx) {
  const receivableDoc = createProductReceivable(doc)
  ctx.mergePath({ path: ['payments', 0], value: receivableDoc })
}
function deleteProductReceivable (ctx) {
  ctx.editPath({ path: ['payments'], value: [] })
}
function handleReceivables (spec, ctx) {
  const { value, prevValue } = spec

  if (!eqPaths(['amount'], value, prevValue)) {
    if (value.status !== '取消') {
      upsertProductReceivable(value, ctx)
    }
  }
}
function fillDefaultReceivableDate (spec, ctx) {
  const { path, value } = spec

  if (R.isNil(value.date)) {
    const doc = ctx.getDoc()
    ctx.editPath({ path: [...path, 'date'], value: doc.date })
  }
}

function downloadExcel (s, ctx) {
  const { doc } = ctx
  const { key, buyerRef } = doc
  const filename = `${key}_${buyerRef.key}.xlsx`
  printExcel({ doc, filename, template: 'salesOrderForm' })
}

async function getBody (response) {
  const text = await response.text()

  try {
    return JSON.stringify(JSON.parse(text))
  } catch (error) {
    return text
  }
}
async function issueInvoice ({ id, invoice, ctx }) {
  const res = await ctx.api.post({
    url: 'api/route/salesInvoice',
    search: {
      action: 'issue',
      id,
      invoice,
    },
  })
  return res
  /*
  const res = await fetch(
    `api/route/salesInvoice?id=${id}&action=issue`,
    {
      method: 'POST',
    },
  )

  if (res.ok) return res.json()
  throw new Error(await getBody(res))
  */
}
function shouldPrint (invoice) {
  const hasBuyer = R.path(['buyer', 'id'], invoice).length === 8
  const hasCarrier = !!R.path(['carrier', 'type'], invoice)
  const hasDonation = !!R.path(['donation'], invoice)

  if (hasBuyer) return true

  if (hasCarrier || hasDonation) return false

  return true
}
function* saveAndPrintInvoice (payload, ctx) {
  // save and return invoice data
  // eslint-disable-next-line no-alert
  if (window.confirm('確認開立發票？')) {
    try {
      const doc = ctx.getDoc()
      const invoiceRequest = R.pick(
        [
          'buyerId', 'mobileBarcode', 'citizenBarcode',
          'donation', 'descriptionOfInvoice', 'issueAmount',
        ],
        doc,
      )

      const savedData = yield ctx.S.call(editEffects.save)

      if (savedData) {
        yield ctx.S.delay(1000)
        const res = yield ctx.S.call(
          issueInvoice,
          { id: savedData._id, invoice: invoiceRequest, ctx },
        )
        const { invoice } = res
        if (shouldPrint(invoice)) {
          ctx.resetPath({ path: ['invoiceOfPrinting'], value: invoice })
          yield ctx.S.delay(1000)

          window.print()
        }

        ctx.history.push(`/edit/salesOrders/view/main/${savedData._id}`)
        // yield ctx.S.call(editEffects.save)
      }
    } catch (error) {
      yield ctx.S.put($message.act.send({ type: 'error', message: error.message }))
    }
  }
}

async function invalidInvoice (invoiceId) {
  const res = await fetch(
    `api/route/salesInvoice?invoiceId=${invoiceId}&action=invalid`,
    {
      method: 'POST',
    },
  )

  if (res.ok) return res.json()
  throw new Error(await getBody(res))
}

module.exports = {
  watcher: {
    pricebookRef: { name: 'changePricebook' },
    discount: { name: 'changeDiscount' },
    'items.0.skuRef': { name: 'findProductPrice' },
    'items.0.discountRate': { name: 'changeItemDiscount' },

    '': { name: 'handleReceivables' },
    'payments.0': { name: 'fillDefaultReceivableDate' },
  },
  actions: {
    completeOrder: {
      text: '交易完成',
      effect: { name: 'completeOrder' },
      props: { variant: 'contained', color: 'primary' },
    },
    // confirmPayments: {
    //   text: '收款確認',
    //   effect: { name: 'confirmPayments' },
    //   props: { variant: 'contained', color: 'primary' },
    // },
    cancelOrder: {
      text: '交易取消',
      effect: { name: 'cancelOrder' },
    },

    downloadExcel: {
      text: '下載 Excel',
      effect: { name: 'downloadExcel' },
      props: { variant: 'outlined', color: 'primary' },
    },
    saveAndPrintInvoice: {
      text: '開立發票',
      effect: { name: 'saveAndPrintInvoice' },
      props: { variant: 'outlined', color: 'primary' },
    },
    saveAndCopy: {
      authChecker: () => null,
    },
  },
  effect: {
    findProductPrice,
    changeDiscount,
    changePricebook,
    changeItemDiscount,
    downloadExcel,
    saveAndPrintInvoice,

    handleReceivables,
    fillDefaultReceivableDate,

    invalidInvoice: function* invalidInvoiceEffect (payload, ctx) {
      yield ctx.S.call(invalidInvoice, payload.id)
      yield ctx.S.call(editEffects.save)
    },

    // confirmPayments: (s, ctx) => {
    //   const { payments } = ctx.getDoc()
    //   const value = R.repeat({ status: '已收款' }, payments.length)
    //   ctx.mergePath({ path: ['payments'], value })
    //   ctx.editPath({ path: ['action'], value: 'confirmPayments' })
    //   ctx.saveDoc()
    // },
    completeOrder: (s, ctx) => {
      ctx.editPath({ path: ['status'], value: '完成' })
      upsertProductReceivable(ctx.getDoc(), ctx)
      ctx.saveDoc()
    },
    cancelOrder: (s, ctx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm('確定要取消銷貨單嗎？')) {
        ctx.editPath({ path: ['status'], value: '取消' })
        deleteProductReceivable(ctx)
        ctx.saveDoc()
      }
    },
  },
}
