const R = require('ramda')


async function upsertItem ({ data, item }) {
  if (item._id) {
    await data.updateItem(item._id, item)
    return item._id
  }

  const itemId = await data.getId('item')
  await data.insertItem(itemId, item)
  return itemId
}

module.exports = ({ data }) => async function assignItems (orderId, items) {
  const prevItemIds = await data.getLinkedItemIds(orderId)

  const itemIds = await Promise.all(R.map(
    async item => upsertItem({ data, item }),
    items || [],
  ))

  const removedIds = R.differenceWith(data.eqIds, prevItemIds, itemIds)
  await Promise.all(R.map(
    async itemId => data.removeItem(itemId),
    removedIds,
  ))

  await data.assocItemIds(orderId, itemIds)

  return itemIds
}
