const mergeConfig = require('coact/config/mergeConfig')

const Page = require('./Page')

const config = {
  routes: {
    stockState: {
      title: '商品庫存價格查詢',
      path: '/route/stockState',
      component: Page,
    },
  },
  // sagas: {
  //   referenceQuerySaga: require('./saga').default,
  // },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
