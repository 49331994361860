const R = require('ramda')

const fetchProductPrice = require('../../modules/fetchProductPrice')
const printExcel = require('../../modules/printExcel')


async function findProductPrice ({ value: skuDoc, path }, ctx) {
  const doc = ctx.getDoc()
  const { pricebookRef: pricebookDoc } = doc

  const { retailPriceDoc, bookPriceDoc } = await fetchProductPrice({ skuDoc, pricebookDoc }, ctx)

  const retailPrice = R.prop('price', retailPriceDoc)
  // TODO rename priceDoc to bookPriceDoc
  const priceDoc = bookPriceDoc

  const discount = R.pathOr(1, ['discount'], doc)
  const bookPrice = R.propOr(0, 'price', bookPriceDoc)
  const price = Math.round(bookPrice * discount)

  const itemPath = R.dropLast(1, path)
  ctx.mergePath({ path: itemPath, value: { priceDoc, retailPrice, price } })
}

function changeItemDiscount (spec, ctx) {
  const { path, value } = spec
  const discountRate = value || 0
  const itemPath = R.take(2, path)
  const bookPrice = R.pathOr(0, [...itemPath, 'priceDoc', 'price'], ctx.doc)
  const price = Math.round(bookPrice * discountRate)

  ctx.editPath({ path: [...itemPath, 'price'], value: price })
}

async function changeDiscount ({ value }, ctx) {
  const items = R.path(['items'], ctx.getDoc())
  const discount = value || 0

  const itemPrices = R.map(
    (item) => {
      const origin = R.path(['priceDoc', 'price'], item)
      const price = origin ? Math.round(origin * discount) : null
      return { price }
    },
    items,
  )

  ctx.mergePath({ path: ['items'], value: itemPrices })
}

function changePricebook (spec, ctx) {
  const items = R.path(['items'], ctx.getDoc())

  // TODO fetch prices in one api.get
  console.log('changePricebook', items)
  ctx.resetPath({ path: ['items'], value: items })
}

function downloadExcel (s, ctx) {
  const { doc } = ctx
  const { key, buyerRef } = doc
  const filename = `${key}_${buyerRef.key}.xlsx`
  printExcel({ doc, filename, template: 'salesReqForm' })
}

module.exports = {
  watcher: {
    pricebookRef: { name: 'changePricebook' },
    discount: { name: 'changeDiscount' },
    'items.0.skuRef': { name: 'findProductPrice' },
    'items.0.discountRate': { name: 'changeItemDiscount' },
  },
  actions: {
    downloadExcel: {
      text: '列印',
      effect: { name: 'downloadExcel' },
      props: { variant: 'outlined', color: 'primary' },
      hotkey: 'ctrl+p',
    },
    saveAndCopy: {
      authChecker: () => null,
    },
  },
  effect: {
    findProductPrice,
    changeDiscount,
    changePricebook,
    changeItemDiscount,
    downloadExcel,
  },
}
