// TODO get from sku entity
const buildSkuName = require('../../../../models/modules/buildSkuName')

module.exports = {
  '': [
    {
      src: 'sellerRef',
      fill: (sellerRef) => {
        const { key, contact, phone, email, fax, address } = sellerRef

        const seller = { key, contact, phone, email, fax, address }

        return { seller }
      },
    },
  ],
  'items.0': {
    src: 'skuRef',
    fill: sku => ({
      sku,
      name: buildSkuName(sku),
    }),
  },
}
