const moment = require('moment')

const header = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: {
      type: 'String',
      label: '零售單號',
      counter: {
        key: () => `RO${moment().format('YYMMDD')}`,
        value: (key, value) => `${key}${value.toString().padStart(3, 0)}`,
      },
    },
    date: { type: 'Date', label: '日期', format: 'YYYY/MM/DD HH:mm' },
    status: { type: 'String', label: '狀態', options: ['新增', '完成', '取消'] },
    warehouse: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '出庫倉', reference: 'warehouses', refKeys: ['key'] },
        key: { type: 'String', label: '倉庫編號' },
        name: { type: 'String', label: '倉庫名稱' },
      },
    },
    pricebook: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '使用價格表', reference: 'pricebooks', refKeys: ['key'] },
        key: { type: 'String', label: '價格表名稱' },
      },
    },
    payType: { type: 'String', label: '付款方式', options: ['現金', '信用卡'] },

    note: { type: 'String', label: '備註' },
    amount: { type: 'Number', label: '金額' },
    summary: { type: 'String', label: '數量' },
    discount: { type: 'Number', label: '整單折扣' },

    buyerId: { type: 'String', label: '發票統編' },
    mobileBarcode: { type: 'String', label: '手機載具號碼' },
    donation: { type: 'String', label: '捐贈發票碼' },
    citizenBarcode: { type: 'String', label: '自然人憑證載具' },
    consumer: {
      type: 'Object',
      contents: {
        id: {
          type: 'ObjectId',
          label: '消費者',
          reference: 'consumers',
          refKeys: ['name', 'phone', 'mobileBarcode'],
        },
        phone: { type: 'String', label: '手機' },
        name: { type: 'String', label: '姓名' },
      },
    },
  },
}

const item = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    sku: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '單品', reference: 'skus', refKeys: ['key'] },
        key: { type: 'String', label: '單品編碼' },
        barcode: { type: 'String' },
      },
    },
    barcodeOnObject: { type: 'String', label: '單品上條碼' },
    name: { type: 'String', label: '名稱' },
    qty: { type: 'Number', label: '數量' },
    price: { type: 'Number', label: '單價' },
    subtotal: { type: 'Number', label: '小計' },
    amount: { type: 'Number', label: '計價' },
    note: { type: 'String', label: '項目備註' },
    discountRate: { type: 'Number', label: '折扣', format: '0%' },
    bookPrice: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', reference: 'prices' },
        price: { type: 'Number', label: '表價' },
      },
    },
  },
}

module.exports = {
  header,
  item,
}
