const R = require('ramda')

module.exports = function shareAmount (amount, values) {
  const total = R.sum(values)
  const max = Math.max(...values)
  const maxIndex = R.indexOf(max, values)

  const shares = values.map(
    (value, index) => {
      if (index === maxIndex) return 0
      if (value === 0) return 0
      return Math.round(amount * (value / total))
    },
  )

  const restSum = R.sum(shares)
  shares[maxIndex] = amount - restSum

  return shares
}
