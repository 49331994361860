const model = {
  title: '庫存資料',
  schema: {
    type: 'Object',
    contents: {
      skuRef: {
        name: '單品',
        type: 'ObjectId',
        reference: 'skus',
        required: true,
        refKeys: ['key'],
        // ragicField:,
      },
      warehouseRef: {
        name: '倉庫',
        type: 'ObjectId',
        reference: 'warehouses',
        required: true,
        refKeys: ['key'],
        ragicField: 1000032,
      },
      qty: {
        name: '數量',
        type: 'Number',
        ragicField: 1000034,
      },
      syncAt: {
        name: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        name: 'Ragic Id',
        type: 'Number',
        ragicRootField: 1000037,
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        skuRef: 1,
        warehouseRef: 1,
      },
      unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'skuRef', width: 200 },
        { path: 'warehouseRef', width: 150 },
        { path: 'qty' },
      ],
    },
  },
  // TO REMOVE use setting in view
  export: {
    populations:
      'skus,products,warehouses,pdtSeries,pdtTypes,skuSizes,skuColors',
    populationdepth: 3,
    fields: [
      '_id',
      'skuRef.key',
      'warehouseRef.key',
      'qty',
      'skuRef.productRef.key',
      'skuRef.productRef.pdtSerieRef.key',
      'skuRef.productRef.pdtTypeRef.key',
      'skuRef.spec.skuSizeRef.key',
      'skuRef.spec.skuColorRef.key',
    ],
  },
}

const mainView = {
  title: '庫存明細',
  data: {
    bundle: {
      stockRecords: { path: ['records'], quantity: 'many' },
    },
  },
  edit: {
    ui: [
      {
        name: 'formEditor',
        fields: [['skuRef', 'warehouseRef'], ['qty']],
      },
      {
        name: 'tableEditor',
        title: 'Records',
        path: 'records',
        exclude: ['stockRef'],
        appendEmpty: false,
        // fields: ['name', 'qty'],
      },
    ],
  },
  list: {
    ui: {
      fields: [
        { path: 'skuRef', width: 300 },
        // { path: 'skuRef.productRef.name', title: '商品名稱', width: 300 },
        { path: 'warehouseRef', width: 150 },
        { path: 'qty' },
      ],
    },
    // populations: 'skus,products,warehouses',
    // populationdepth: 3,
  },
  export: {
    populations:
      'skus,products,warehouses,pdtSeries,pdtTypes,skuSizes,skuColors',
    populationdepth: 3,
    fields: [
      '_id',
      'skuRef.key',
      'warehouseRef.key',
      'qty',
      'skuRef.productRef.key',
      'skuRef.productRef.name',
      'skuRef.productRef.pdtSerieRef.key',
      'skuRef.productRef.pdtTypeRef.key',
      'skuRef.spec.skuSizeRef.key',
      'skuRef.spec.skuColorRef.key',
    ],
  },
}

module.exports = {
  ...model,
  views: {
    main: mainView,
  },
}
