const R = require('ramda')

const schema = require('../schema')


const viewSchema = R.assocPath(
  ['contents', 'order', 'contents', 'id'],
  schema.header,
  schema.item,
)

module.exports = viewSchema
