const R = require('ramda')
const toPrintData = require('~/src/invoice/printer/toPrintData')
const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const issueInvoice = require('./issueInvoice')

function shouldPrint (invoice) {
  const hasBuyer = R.path(['buyer', 'id'], invoice).length === 8
  const hasCarrier = !!R.path(['carrier', 'type'], invoice)
  const hasDonation = !!R.path(['donation'], invoice)

  if (hasBuyer) return true

  if (hasCarrier || hasDonation) return false

  return true
}

async function printInvoice (invoice) {
  await fetch(
    'http://localhost:3000/printETicket',
    {
      body: JSON.stringify(toPrintData(invoice)),
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
    },
  )
}

module.exports = function* saveAndPrintInvoiceOnPos (payload, ctx) {
  // save and return invoice data
  const savedData = yield ctx.S.call(editEffects.save)

  if (savedData) {
    const res = yield ctx.S.call(issueInvoice, savedData)
    const { invoice } = res
    if (shouldPrint(invoice)) {
      console.log(JSON.stringify(invoice))
      yield ctx.S.call(printInvoice, invoice)
    }
  }
}
