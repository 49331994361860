module.exports = (deps, my) => async function updateNullFields (id, doc) {
  const { modelviews, user } = deps

  const model = modelviews.model('consumers', user)

  const current = await model.find(id)

  const toUpdateDoc = { }
  if (!current.name && doc.name) toUpdateDoc.name = doc.name
  if (!current.phone && doc.phone) toUpdateDoc.phone = doc.phone
  if (!current.mobileBarcode && doc.mobileBarcode) toUpdateDoc.mobileBarcode = doc.mobileBarcode

  await my.checkUniqueFields(toUpdateDoc)

  return model.update(id, toUpdateDoc)
}
