import React from 'react'
import { object } from 'prop-types'

import QRCode from 'qrcode.react'
// import Barcode from 'react-barcode'

import { withStyles } from '@material-ui/core/styles'

import toPrintData from './toPrintData'

const styles = {
  '@global': {
    '@media print': {
      '@page': {
        size: '58mm 297mm',
      },
    },
  },
  invoice_paper: {
    width: '58mm',
    textAlign: 'center',
    // overflow: 'hidden',
  },
  detail_paper: {
    width: '58mm',
    textAlign: 'center',
    borderTop: '1px dashed black',
    marginTop: '20px',
    paddingTop: '20px',
    // overflow: 'hidden',
  },

  '@global h5': {
    font: 'bold 19px/1.2 Verdana',
    margin: 0,
  },

  '@global h6': {
    font: 'bold 22px/1 Verdana',
    margin: 0,

  },
  '@global h6 span': {
    fontSize: 30,
    verticalAlign: -3,
    fontFamily: 'Times New Roman',
  },
  '@global svg': {
    display: 'block',
    margin: 2,
  },
  align_left: {
    textAlign: 'left',
    marginLeft: 20,
  },

  trade_info: {
    font: 'bold 13px/1.3 naomal, serif',
  },

  code: {
    margin: '5px 5px',
  },
  barcode: {
    margin: '5px auto',
  },
  barcode_font: {
    fontFamily: '\'Libre Barcode 39\', cursive',
    fontSize: 18,
  },

  half: {
    width: '50%',
    display: 'inline-block',
    float: 'left',
  },

  detail: {
    font: 'bold 13px/1.3 naomal, serif',
    textAlign: 'left',
    verticalAlign: 'top',
    borderCollapse: 'collapse',
    width: '90%',
    margin: '5px',
    '& td': {
      verticalAlign: 'top',
    },
  },

  itemName: {
    wordBreak: 'break-all',
  },

  itemSubtotal: {
    textAlign: 'right',
  },

  amount: {
    '& td': {
      borderTop: '1px solid black',
    },
  },
}

function Invoice (props) {
  const { classes, doc: { invoiceOfPrinting } } = props

  if (!invoiceOfPrinting) return null

  // console.log(invoiceOfPrinting)
  const inv = toPrintData(invoiceOfPrinting)
  const ifPrintDetail = !!inv.buyerId

  return (
    <React.Fragment>
      <link href="https://fonts.googleapis.com/css?family=Libre+Barcode+39&display=swap" rel="stylesheet" />
      <div className={classes.invoice_paper}>
        <div><img style={{ width: '90%' }} src="invoiceLogo.png" alt="禾宏文化" /></div>
        <h5>電子發票證明聯</h5>
        <h6>
          <span>{inv.year}</span>年
          <span>{inv.month}</span>月
        </h6>
        <h6><span>{inv.no}</span></h6>
        <div className={`${classes.trade_info} ${classes.align_left}`}>
          <span>{inv.date}</span>
          <span style={{ marginLeft: 20 }}>{inv.declarationType}</span>
        </div>
        <div className={`${classes.trade_info} ${classes.align_left}`}>
          <div className={classes.half}>隨機碼 {inv.randomNo}</div>
          <div className={classes.half}>總計 {inv.totalAmount}</div>
        </div>
        <div className={`${classes.trade_info} ${classes.align_left}`}>
          <div className={classes.half}>{inv.sellerId}</div>
          <div className={classes.half}>{inv.buyerId}</div>
        </div>
        <div className={classes.barcode}>
          <div className={classes.barcode_font}>
            {inv.barcode}
          </div>
          <div className={classes.barcode_font} style={{ marginTop: -9 }}>
            {inv.barcode}
          </div>
          <div className={classes.barcode_font} style={{ marginTop: -9 }}>
            {inv.barcode}
          </div>
        </div>
        <div className={classes.code}>
          <div className={classes.half}>
            <QRCode value={inv.qrCode1} size={90} renderAs="svg" />
          </div>
          <div className={classes.half}>
            <QRCode value={inv.qrCode2} size={90} renderAs="svg" />
          </div>
          <br style={{ clear: 'both' }} />
        </div>
      </div>
      {/*
      */}

      {
        !ifPrintDetail ? null : (
          <div className={classes.detail_paper}>
            <h5>交易明細</h5>
            <table className={classes.detail}>
              <tbody>
                {invoiceOfPrinting.items.map((item, index) => (
                  <tr key={index}>
                    <td className={classes.itemName}>{item.description} * {item.quantity}</td>
                    <td className={classes.itemSubtotal}>{item.amount}</td>
                  </tr>
                ))}
                <tr className={classes.amount}>
                  <td>應稅銷售額</td>
                  <td className={classes.itemSubtotal}>{inv.taxSales}</td>
                </tr>
                <tr>
                  <td>營業稅</td>
                  <td className={classes.itemSubtotal}>{inv.tax}</td>
                </tr>
                <tr>
                  <td>總計</td>
                  <td className={classes.itemSubtotal}>{inv.totalAmount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      }
      {/*
      */}
    </React.Fragment>
  )
}
Invoice.propTypes = {
  classes: object.isRequired,
  doc: object.isRequired,
}

export default withStyles(styles)(Invoice)
