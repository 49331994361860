module.exports = (deps, my) => async function updateWithItems (order, items) {
  const orderId = order._id
  await my.updateOrder(orderId, order)

  const itemIds = await my.assignItems(orderId, items)

  my.runComputer(orderId)

  return { orderId, itemIds }
}
