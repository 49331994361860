import * as R from 'ramda'
import coact from 'coact'

import models from './models/client'
import menu from './menu'

// My Components
import StocksEditor from './components/StocksEditor'
import StocksList from './components/StocksList'
import UploadTemplate from './components/UploadTemplate'
import ShoplineStocks from './components/ShoplineStocks'

import editStocks from './sagas/editStocks'

import SalesOrderCreator from './components/SalesOrderCreator'
import salesOrderCreatorSaga from './components/SalesOrderCreator/salesOrderCreatorSaga'

import installProduct from './product/installClient'
import installPurchase from './purchase/installClient'
import installInvoice from './invoice/installClient'
import installPos from './pos/installClient'
import installStaffer from './staffer/installClient'
import installConsumers from './consumers/installClient'


const config = {
  title: 'Hehong App',
  models,
  routes: {
    stocksEditor: {
      title: '庫存盤點',
      path: '/route/stocksEditor',
      component: StocksEditor,
    },
    deliveryProcess: {
      title: '客戶訂單出貨處理',
      path: '/route/deliveryProcess',
      component: SalesOrderCreator,
    },
    stocksList: {
      title: '庫存列表',
      path: '/route/stocksList',
      component: StocksList,
      authChecker: authority => (
        // eslint-disable-next-line operator-linebreak
        R.path(['routes', 'publicProducts'], authority) &&
        R.path(['routes', 'publicStocks'], authority)
      ),
    },
    uploadTemplate: {
      title: '上傳 Template',
      path: '/route/uploadTemplate',
      component: UploadTemplate,
    },
    shoplineStocks: {
      title: 'Shopline 資料同步',
      path: '/route/shoplineStocks',
      component: ShoplineStocks,
    },
  },
  menu,
  sagas: {
    editStocks,
    salesOrderCreatorSaga,
  },
}

const fullConfig = R.pipe(
  installProduct,
  installPurchase,
  installInvoice,
  installPos,
  installStaffer,
  installConsumers,
)(config)

coact(fullConfig)
