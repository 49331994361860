const R = require('ramda')
const moment = require('moment')

function parseYearmonth (invoice) {
  const { yearmonth } = invoice
  const yyy = yearmonth.slice(0, 3)
  const mm = yearmonth.slice(3, 5)
  const evenM = +mm
  const oddM = evenM - 1
  const pad = n => `${n}`.padStart(2, '0')

  return {
    year: yyy, // e.g. 108
    month: `${pad(oddM)}-${pad(evenM)}`, // e.g. 11-12
  }
}
function parseNo (invoice) {
  const { no } = invoice
  const track = no.slice(0, 2)
  const number = no.slice(2)
  return { no: `${track}-${number}` }
}
function parseDate (invoice) {
  const { date } = invoice
  return { date: moment(date).format('YYYY-MM-DD HH:mm:ss') }
}
function parseConsumerOrCompany (invoice) {
  const buyerId = invoice.buyer.id
  if (buyerId === '0000000000') {
    return { declarationType: '', buyerId: '' }
  }
  return { declarationType: '格式 25', buyerId: `買方 ${buyerId}` }
}
function buildBarcode (invoice) {
  const { yearmonth, no, randomNo } = invoice

  return {
    barcode: `*${yearmonth}${no}${randomNo}*`, // e.g. 11-12
  }
}

function mapFields (invoice) {
  return {
    ...R.pick(['randomNo'], invoice),
    totalAmount: invoice.amount.total,
    tax: invoice.amount.tax,
    taxSales: invoice.amount.taxSales,
    sellerId: `賣方 ${invoice.account}`,
    qrCode1: invoice.QRCode1,
    qrCode2: invoice.QRCode2,
  }
}

module.exports = function toPrintData (invoice) {
  return Object.assign(
    { },
    parseYearmonth(invoice),
    parseNo(invoice),
    parseDate(invoice),
    parseConsumerOrCompany(invoice),
    buildBarcode(invoice),
    mapFields(invoice),
  )
}
