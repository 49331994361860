module.exports = [
  {
    category: '客戶供應商',
    features: ['customers', 'staffers', 'suppliers'],
  },
  {
    category: '商品資料管理',
    features: [
      'products:main',
      'pdtSeries',
      'pdtTypes',
      'skus:main',
      'skuColors',
      'skuSizes',
      'sizeSets',
      'prices',
      'pricebooks',
      'stockState',
    ],
  },
  {
    category: '進銷表單',
    features: [
      'salesOrders:main', 'salesOrderItems',
      'purchaseOrders:order', 'purchaseOrderItems',
      'transferOrders:main', 'transferOrderItems',
      // 'retailOrders:main', 'retailOrderItems',
    ],
  },
  {
    category: '庫存管理',
    features: ['stocks:main', 'stockRecords', 'stocksEditor', 'stocksList', 'warehouses'],
  },
  {
    category: '帳務管理',
    features: [
      'receivables', 'recStatements:main',
    ],
  },
  {
    category: '訂單管理',
    features: [
      'salesReqs:main', 'salesReqItems', 'deliveryProcess',
    ],
  },
  {
    category: '採購管理',
    features: [
      'purchaseReqs:form',
      'purchaseItems:item',
      'arrivalProcess',
      'referenceQuery',
    ],
  },
  {
    category: '檔案管理 / 資料串接',
    features: ['shoplineStocks', 'uploadTemplate'],
  },
  {
    category: '據點銷售',
    features: [
      'retailOrders:cashier',
      'retailOrderItems',
      'consumers',
    ],
  },
  {
    category: '發票管理',
    features: [
      'testInvoicePrinter',
      'invoices:issuer',
      // 'uploadInvoiceRange',
      // 'invoiceDailyReport',
    ],
  },
]
