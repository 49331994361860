const R = require('ramda')

const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const issueInvoice = require('./issueInvoice')

function shouldPrint (invoice) {
  const hasBuyer = R.path(['buyer', 'id'], invoice).length === 8
  const hasCarrier = !!R.path(['carrier', 'type'], invoice)
  const hasDonation = !!R.path(['donation'], invoice)

  if (hasBuyer) return true

  if (hasCarrier || hasDonation) return false

  return true
}

module.exports = function* saveAndPrintInvoice (payload, ctx) {
  // save and return invoice data
  const savedData = yield ctx.S.call(editEffects.save)

  if (savedData) {
    yield ctx.S.delay(1000)
    const res = yield ctx.S.call(issueInvoice, savedData)
    const { invoice } = res
    if (shouldPrint(invoice)) {
      ctx.resetPath({ path: ['invoiceOfPrinting'], value: invoice })
      yield ctx.S.delay(1000)

      window.print()
    }

    // Go To Next One
    ctx.history.push('/edit/retailOrders/view/cashier')
  }
}
