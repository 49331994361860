import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'coact/ramda'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Editor from 'coact/editors/Editor'
import Field from 'coact/editors/Field'
import ItemsEditor from 'coact/editors/ItemsEditor'


function UndeliveredItems () {
  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: 200 }}>單品</th>
          <th style={{ width: 300 }}>名稱</th>
          <th style={{ width: 80 }}>訂購數</th>
          <th style={{ width: 80 }}>未出數</th>
          <th style={{ width: 80 }}>現有數</th>
          <th style={{ width: 80 }}>出貨數</th>
          <th style={{ width: 80 }}>單價</th>
          <th style={{ width: 80 }}>小計</th>
          <th style={{ width: 150 }}>備註</th>
        </tr>
      </thead>
      <tbody>
        <ItemsEditor path="undeliveredItems">
          {(editor) => {
            const getter = R.path(R.__, editor.value)
            return (
              <tr>
                <td>{getter(['sku', 'key'])}</td>
                <td>{getter(['name'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['qty'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['undeliveredQty'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['currentStockQty'])}</td>
                <td><Field path="toDeliverQty" /></td>
                <td style={{ textAlign: 'center' }}>{getter(['price'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['subtotal'])}</td>
                <td>{getter(['note'])}</td>
              </tr>
            )
          }}
        </ItemsEditor>
      </tbody>
    </table>
  )
}

function Customers (props) {
  const { data, clickCustomer } = props
  return (
    <div>
      <List component="nav">
        {
          data.map(customer => (
            <ListItem key={customer.key} button onClick={() => clickCustomer(customer)}>
              <ListItemText primary={customer.key} />
            </ListItem>
          ))
        }
      </List>
    </div>
  )
}
const { array, object, func } = PropTypes
Customers.propTypes = {
  data: array.isRequired,
  clickCustomer: func.isRequired,
}

function SalesOrderCreator (props) {
  const { schema, createSalesOrder, fetchUndeliveredCustomers } = props
  return (
    <Editor schema={schema}>
      {(editor) => {
        const { show, undeliveredCustomers } = editor.value
        return (
          <React.Fragment>
            <div>
              <Field path="customer" />
            </div>
            { show === 'undeliveredItems' ? <UndeliveredItems /> : null }
            { show === 'undeliveredCustomers' ? <Customers data={undeliveredCustomers} {...props} /> : null }
            <Button variant="contained" color="primary" onClick={createSalesOrder}>產生銷貨單</Button>
            <Button variant="outlined" color="primary" onClick={fetchUndeliveredCustomers}>未出貨客戶列表</Button>
          </React.Fragment>
        )
      }}
    </Editor>
  )
}
SalesOrderCreator.propTypes = {
  schema: object.isRequired,
  createSalesOrder: func.isRequired,
  fetchUndeliveredCustomers: func.isRequired,
}

export default SalesOrderCreator
