const R = require('ramda')

module.exports = (data) => {
  const sizeList = R.pathOr([], ['specLists', 'sizeList'], data)
  const colorList = R.pathOr([], ['specLists', 'colorList'], data)
  const skus = R.prop('skus', data)

  const skuDict = R.ifElse(
    R.isNil,
    R.always(null),
    R.pipe(
      R.groupBy(R.pathOr('UNI', ['spec', 'color', 'key'])),
      R.map(R.indexBy(R.path(['spec', 'size', 'key']))),
      R.map(R.map(R.prop('_id'))),
    ),
  )(skus)

  const stockDict = R.prop('stockDict', data)
  const progressDict = R.prop('progressDict', data)
  const salesDict = R.prop('salesDict', data)
  const retailDict = R.prop('retailDict', data)

  const qtyDict = R.pipe(
    // add data
    R.map(
      color => R.map(
        (size) => {
          const skuId = skuDict[color.key][size.key]
          const stockQty = R.propOr(null, 'qty', stockDict[skuId])
          const unarrivedQty = R.propOr(null, 'unarrivedQty', progressDict[skuId])
          const onTheWayQty = R.propOr(null, 'onTheWayQty', progressDict[skuId])
          const salesQty = R.propOr(null, 'qty', salesDict[skuId])
          const retailQty = R.propOr(null, 'qty', retailDict[skuId])
          return {
            skuId,
            stockQty,
            unarrivedQty,
            onTheWayQty,
            salesQty,
            retailQty,
            sumQty: stockQty + unarrivedQty + onTheWayQty + salesQty + retailQty,
          }
        },
        sizeList,
      ),
    ),
    // calc subtotal of color
    R.map(
      sizeQtyDict => R.append(
        R.reduce(
          (acc, val) => ({
            skuId: acc.skuId + val.skuId,
            stockQty: acc.stockQty + val.stockQty,
            unarrivedQty: acc.unarrivedQty + val.unarrivedQty,
            onTheWayQty: acc.onTheWayQty + val.onTheWayQty,
            salesQty: acc.salesQty + val.salesQty,
            retailQty: acc.retailQty + val.retailQty,
            sumQty: acc.sumQty + val.sumQty,
          }),
          {
            skuId: 'sum',
            stockQty: 0,
            unarrivedQty: 0,
            onTheWayQty: 0,
            salesQty: 0,
            retailQty: 0,
            sumQty: 0,
          },
          sizeQtyDict,
        ),
        sizeQtyDict,
      ),
    ),
  )(colorList)

  return {
    sizeList,
    colorList,
    qtyDict,
  }
}
