module.exports = {
  title: '單品顏色資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        label: '色碼',
        type: 'String',
        // unique: true,
        required: true,
      },
      nameOfSupplierSide: {
        label: '供應端名稱',
        type: 'String',
      },
      name: {
        label: '顏色名稱',
        type: 'String',
      },
      syncAt: {
        label: '上次同步時間',
        type: 'Date',
        format: 'YYYY/MM/DD HH:mm:ss',
        disabled: true,
      },
      ragicId: {
        label: 'Ragic Id',
        type: 'Number',
        ragicField: '_ragicId',
        disabled: true,
      },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      // unique: true,
    },
  },
  list: {
    ui: {
      fields: [
        { path: 'key' },
        { path: 'nameOfSupplierSide', width: 200 },
        { path: 'name', width: 200 },
      ],
    },
  },
  ui: {
    editor: {
      config: { nameWidth: 110 },
      groups: [
        {
          name: '',
          rows: [
            [{ key: 'key' }],
            [{ key: 'nameOfSupplierSide' }],
            [{ key: 'name' }],
            [{ key: 'ragicId' }, { key: 'syncAt' }],
          ],
        },
      ],
    },
  },
}
