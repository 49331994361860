const R = require('ramda')

const buildSkuName = require('../../modules/buildSkuName')

module.exports = {
  '': [
    {
      src: 'buyerRef',
      fill: (buyerRef) => {
        const buyer = R.pick(['_id', 'key', 'contact', 'phone', 'fax', 'email'], buyerRef)

        return { buyer }
      },
    },
    {
      src: 'stafferRef',
      fill: (stafferRef) => {
        const staffer = R.pick(['_id', 'key', 'phone', 'email'], stafferRef)

        return { staffer }
      },
    },
  ],
  'items.0': {
    src: 'skuRef',
    fill: sku => ({
      sku,
      name: buildSkuName(sku),
    }),
  },
}
