const R = require('ramda')

const { builtinEffects: editEffects } = require('coact/saga/editSaga')

const printExcel = require('../../modules/printExcel')

const getDefaultDueDate = require('../modules/getDefaultDueDate')

const RecStatementEditor = require('./RecStatementEditor').default


async function getBody (response) {
  const text = await response.text()

  try {
    return JSON.stringify(JSON.parse(text))
  } catch (error) {
    return text
  }
}
async function issueInvoice (doc) {
  const id = doc._id
  const res = await fetch(
    `api/route/receivableInvoice?id=${id}&action=issue`,
    {
      method: 'POST',
    },
  )

  if (res.ok) return res.json()
  throw new Error(await getBody(res))
}
function shouldPrint (invoice) {
  const hasBuyer = R.path(['buyer', 'id'], invoice).length === 8
  const hasCarrier = !!R.path(['carrier', 'type'], invoice)
  const hasDonation = !!R.path(['donation'], invoice)

  if (hasBuyer) return true

  if (hasCarrier || hasDonation) return false

  return true
}
function* saveAndPrintInvoice (payload, ctx) {
  // save and return invoice data
  const savedData = yield ctx.S.call(editEffects.save)

  if (savedData) {
    yield ctx.S.delay(1000)
    const res = yield ctx.S.call(issueInvoice, savedData)
    const { invoice } = res
    if (shouldPrint(invoice)) {
      ctx.resetPath({ path: ['invoiceOfPrinting'], value: invoice })
      yield ctx.S.delay(1000)

      window.print()
    }

    yield ctx.S.call(editEffects.save)
  }
}

async function invalidInvoice (invoiceId) {
  const res = await fetch(
    `api/route/receivableInvoice?invoiceId=${invoiceId}&action=invalid`,
    {
      method: 'POST',
    },
  )

  if (res.ok) return res.json()
  throw new Error(await getBody(res))
}

const view = {
  title: '應收對帳單',
  list: {
    ui: {
      fields: [
        'key', 'status', 'customerRef', 'amount',
        'customer.contact', 'customer.taxId', 'customer.bankAccount',
        'start', 'end', 'customer.email', 'customer.address',
      ],
    },
    actions: {
      createRecs: {
        text: '新增當月對帳單',
        effect: { name: 'createRecs' },
        props: { variant: 'outlined', color: 'primary' },
      },
    },
    effect: {
      createRecs: async (effect, ctx) => {
        const res = await ctx.api.get({ url: '/api/route/recStatements', search: { action: 'createRecs' } })
        ctx.reloadData()
        setTimeout(() => ctx.sendMessage({ type: 'success', message: res.message }), 5000)
      },
    },
    populations: 'customers',
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        fields: [
          ['key', 'status', 'date', 'dueDate'],
          ['customerRef', 'customer.contact', 'customer.phone', 'customer.fax'],
          ['start', { c: 2, span: 2, path: 'customer.email' }],
          ['end', { c: 2, path: 'customer.zipCode' }, { span: 2, path: 'customer.address' }],
          ['amount', { c: 2, path: 'customer.taxId' }, 'customer.invoiceTitle', 'customer.bankAccount'],
          [{ span: 2, path: 'note' }, 'descriptionOfInvoice'],
        ],
      },
      recItems: {
        title: '應收明細',
        name: 'tableEditor',
        path: ['receivableRefs'],
        exclude: ['recStatementRef'],
        appendEmpty: false,
      },
      invoices: {
        name: 'tableEditor',
        path: 'invoices',
        fields: [
          { path: 'invoice.yearmonth', label: '期別', width: 50 },
          { path: 'invoice.no', label: '號碼', width: 100 },
          { path: 'invoice.date', label: '日期', width: 200 },
          { path: 'invoice.amount.total', label: '金額', width: 70 },
          { path: 'invoice.carrier.id1', label: '載具', width: 70 },
        ],
        deletable: false,
        copyable: false,
        appendEmpty: false,
      },
      // orderItems: {
      //   title: '銷售明細',
      //   name: 'tableEditor',
      //   path: ['orderItems'],
      //   appendEmpty: false,
      // },
    },
    autofill: {
      '': {
        src: 'customerRef',
        fill: [{ from: '', to: 'customer', or: { } }],
      },
    },
    watcher: {
      end: { name: 'fillDueDate' },
    },
    actions: {
      downloadExcel: {
        text: '輸出',
        effect: { name: 'downloadExcel' },
        props: { variant: 'outlined', color: 'primary' },
      },
      confirm: {
        text: '確認',
        effect: { name: 'changeStatus', status: '確認' },
        props: { variant: 'contained', color: 'primary' },
      },
      request: {
        text: '請款',
        effect: { name: 'changeStatementAndRecsStatus', status: '已請款' },
        props: { variant: 'contained', color: 'primary' },
      },
      receive: {
        text: '收款',
        effect: { name: 'changeStatementAndRecsStatus', status: '已收款' },
        props: { variant: 'contained', color: 'primary' },
      },
      saveAndPrintInvoice: {
        text: '開立發票',
        effect: { name: 'saveAndPrintInvoice' },
        props: { variant: 'outlined', color: 'primary' },
      },
    },
    effect: {
      fillDueDate: (s, ctx) => {
        const { doc } = ctx
        if (doc.dueDate) return

        const dueDate = getDefaultDueDate(doc)
        ctx.editPath({ path: ['dueDate'], value: dueDate })
      },
      changeStatus: (s, ctx) => {
        ctx.editPath({ path: ['status'], value: s.status })
        ctx.saveDoc()
      },
      changeStatementAndRecsStatus: (s, ctx) => {
        ctx.editPath({ path: ['status'], value: s.status })
        const value = R.map(() => ({ status: s.status }), R.pathOr([], ['receivableRefs'], ctx.doc))
        ctx.mergePath({ path: ['receivableRefs'], value })
        ctx.saveDoc()
      },
      downloadExcel: (s, ctx) => {
        const { doc } = ctx
        const { key, customer } = doc
        const filename = `${key}_${customer.key}.xlsx`
        printExcel({ doc, filename, template: 'recStatement' })
      },

      saveAndPrintInvoice,
      invalidInvoice: function* invalidInvoiceEffect (payload, ctx) {
        yield ctx.S.call(invalidInvoice, payload.id)
        yield ctx.S.call(editEffects.save)
      },
    },
    noclone: {
      key: true,
      receivableRefs: true,
      customerRef: true,
      customer: true,
      note: true,
      amount: true,
      status: true,
      date: true,
      dueDate: true,
    },
    Components: {
      PageForm: RecStatementEditor,
    },
  },
}

module.exports = view
