/*
 * 第 1 碼為 /
 * 其餘 7 碼為 0-9 A-Z . - +
 */

module.exports = (value) => {
  // - 需放最後，不然要會跟 0-9 的 - 混淆
  const patt = /^\/[0-9A-Z.+-]{7}$/

  if (patt.test(value)) return null

  return '手機載具碼錯誤'
}
