const R = require('ramda')

const mergeDeep = require('coact/utils/mergeDeep')

const getDefaultItemDoc = require('./getDefaultItemDoc')

module.exports = (deps, my) => async function createWithItems (order, items) {
  const orderDoc = await my.createOrder(order)
  const orderId = orderDoc._id

  const defaultItemDoc = await getDefaultItemDoc()
  const itemDocs = R.map(mergeDeep(defaultItemDoc), items || [])
  const itemIds = await my.assignItems(orderId, itemDocs)

  my.runComputer(orderId)

  return { orderId, itemIds }
}
