const R = require('ramda')

const extendsSchema = require('coact/utils/schema/extendsSchema')
const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const invoiceSchema = require('~/src/invoice/data/invoices/schema')
const twInvoiceSchema = require('~/src/invoice/twInvoice/data/schema')

const formatterAndViewOfSrc = require('./formatterAndViewOfSrc')


const putTwInvoiceSchema = R.assocPath(
  toSchemaPath('invoice'),
  twInvoiceSchema,
)
const addFormatterAndViewOfSrc = R.over(
  R.lensPath(toSchemaPath('src')),
  f => ({ ...f, ...formatterAndViewOfSrc }),
)
const addOptinosOfCarrier = R.over(
  R.lensPath(toSchemaPath('invoice.carrier.type')),
  f => ({
    ...f,
    options: ['mobile', 'CPC'],
    formatter: (value) => {
      const dict = {
        mobile: '手機條碼',
        CPC: '自然人憑證',
      }
      return dict[value] || value
    },
  }),
)
const addDateFormat = schema => extendsSchema(
  schema,
  ({ type }) => (type === 'Date' ? { format: 'YYYY-MM-DD HH:mm:ss' } : { }),
)

const schema = R.pipe(
  putTwInvoiceSchema,
  addFormatterAndViewOfSrc,
  addOptinosOfCarrier,
  addDateFormat,
)(invoiceSchema)


module.exports = schema
