const R = require('ramda')
const moment = require('moment')

const orderSchema = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    status: { type: 'String', label: '狀態', options: ['新增', '完成', '取消'] },
    key: {
      type: 'String',
      label: '進貨單號',
      counter: {
        key: () => `PO${moment().format('YYMMDD')}`,
        value: (key, value) => `${key}${value.toString().padStart(3, 0)}`,
      },
    },
    packingListNo: { type: 'String', label: 'Packing List No.' },
    invoiceNo: { type: 'String', label: 'Invoice No.' },
    date: { type: 'Date', label: '建立日期' },
    personInCharge: {
      type: 'ObjectId', label: '負責人', reference: 'staffers', refKeys: ['key'],
    },
    sellerRef: {
      type: 'ObjectId', label: '供應商', reference: 'suppliers', refKeys: ['key'],
    },
    seller: {
      type: 'Object',
      contents: {
        key: { type: 'String', label: '名稱' },
        contact: { type: 'String', label: '聯絡人' },
        phone: { type: 'String', label: '電話' },
        email: { type: 'String', label: 'Email' },
        fax: { type: 'String', label: '傳真' },
        address: { type: 'String', label: '地址' },
      },
    },
    amount: { type: 'Number', label: '總金額', format: '0[.]000' },
    summary: { type: 'String', label: '總數量' },
    warehouseRef: {
      type: 'ObjectId', label: '入庫', reference: 'warehouses', refKeys: ['key'],
    },
    warehouse: { type: 'Object' },
    pricebookRef: {
      type: 'ObjectId', label: '使用價格表', reference: 'pricebooks', refKeys: ['key'],
    },
    shippingCost: { type: 'Number', label: '運費' },
    customsDuty: { type: 'Number', label: '關稅' },
    payable: { type: 'Number', label: '應付', format: '0[.]000' },
    note: { type: 'String', label: '備註' },
  },
}

const itemSchema = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    skuRef: {
      type: 'ObjectId', label: '品項', reference: 'skus', refKeys: ['key'],
    },
    sku: {
      type: 'Object',
      contents: {
        key: { type: 'String', label: '單品編碼' },
        barcode: { type: 'String' },
      },
    },
    name: { type: 'String', label: '名稱' },
    qty: { type: 'Number', label: '數量' },
    price: { type: 'Number', label: '單價', format: '0[.]000' },
    subtotal: { type: 'Number', label: '小計', format: '0[.]000' },
    note: { type: 'String', label: '項目備註' },
  },
}

module.exports = R.assocPath(
  ['contents', 'items'],
  { type: 'Array', contents: [itemSchema] },
  orderSchema,
)
