module.exports = function reqDataInterface (impl) {
  async function getId (spec) {
    return impl.getId(spec)
  }
  function eqIds (a, b) {
    return impl.eqIds(a, b)
  }

  async function insertOrder (orderId, orderDoc) {
    return impl.insertOrder(orderId, orderDoc)
  }
  async function updateOrder (orderId, orderDoc) {
    return impl.updateOrder(orderId, orderDoc)
  }

  async function insertItem (itemId, itemDoc) {
    return impl.insertItem(itemId, itemDoc)
  }
  async function updateItem (itemId, itemDoc) {
    return impl.updateItem(itemId, itemDoc)
  }
  async function removeItem (itemId, itemDoc) {
    return impl.removeItem(itemId, itemDoc)
  }

  async function assocItems (orderId, itemIds) {
    return impl.assocItems(orderId, itemIds)
  }

  async function getItemDoc (itemId) {
    return impl.getItemDoc(itemId)
  }
  async function getDocById (orderId) {
    return impl.getDocById(orderId)
  }
  async function getDocsByItemIds (itemIds) {
    return impl.getDocsByItemIds(itemIds)
  }
  async function getLinkedItemIds (orderId) {
    return impl.getLinkedItemIds(orderId)
  }

  async function mapReqOfItem (itemIds) {
    return impl.mapReqOfItem(itemIds)
  }

  return {
    getId,
    eqIds,

    insertOrder,
    updateOrder,

    insertItem,
    updateItem,
    removeItem,

    assocItems,

    getItemDoc,
    getDocsByItemIds,
    getDocById,
    getLinkedItemIds,
    mapReqOfItem,
  }
}
