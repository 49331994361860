// const R = require('ramda')
const moment = require('moment')

const mobileBarcodeValidator = require('~/src/invoice/twInvoice/validator/mobileBarcode')

const buildSkuName = require('../../modules/buildSkuName')

const SalesOrderEditor = require('./SalesOrderEditor').default

const schema = require('./schema')
const autofill = require('./autofill')
const computer = require('./computer')
const watcherActionsEffect = require('./watcherActionsEffect')


module.exports = {
  title: '銷貨單',
  schema,
  edit: {
    creator: {
      date: '$$NOW',
      status: '新增',
      checkoutDate: () => moment().add(1, 'months').startOf('month').toDate(),
      // eslint-disable-next-line newline-per-chained-call
      paymentDueDate: () => moment().add(1, 'months').startOf('month').date(8).toDate(),
      'items.0.qty': 1,
    },
    validator: {
      mobileBarcode: mobileBarcodeValidator,
    },
    autofill,
    computer,
    ...watcherActionsEffect,
    ui: {
      order: {
        name: 'formEditor',
        fields: [
          ['key', 'date', 'checkoutDate', 'paymentDueDate'],
          ['personInCharge', 'warehouseRef', 'status'],
          ['buyerRef', 'buyer.contact', 'buyer.phone', 'buyer.fax'],
          ['buyer.email', { path: 'buyer.address', span: 2 }],
          [{ path: 'note', span: 3 }],
          ['amount', 'receivable', 'summary', 'pricebookRef', 'discount'],
          ['citizenBarcode', 'donation', 'buyerId', 'mobileBarcode', 'descriptionOfInvoice', 'issueAmount'],
        ],
      },
      productReader: {
        url: '/api/model/skus',
        queryKey: 'barcode',
        formatter: buildSkuName,
      },
      items: {
        name: 'tableEditor',
        path: 'items',
        fields: [
          'skuRef', 'name', 'qty', 'price', 'subtotal',
          'discountRate',
          { path: 'priceDoc.price', label: '表價', width: 70, disabled: true },
          'retailPrice', 'note',
          { path: 'skuRef.barcode', label: '條碼', disabled: true },
          {
            path: 'salesReqItemRef.orderRef',
            disabled: true,
            type: 'ObjectId',
            reference: 'salesReqs',
            view: 'main',
            refKeys: ['key'],
            label: '訂購單號',
          },
        ],
        deletable: true,
        copyable: false,
      },
      payments: {
        title: '應收款項',
        name: 'tableEditor',
        path: 'payments',
        exclude: ['orderRef'],
        deletable: false,
        copyable: false,
      },
      invoices: {
        name: 'tableEditor',
        path: 'invoices',
        fields: [
          { path: 'invoice.yearmonth', label: '期別', width: 50 },
          { path: 'invoice.no', label: '號碼', width: 100 },
          { path: 'invoice.date', label: '日期', width: 200 },
          { path: 'invoice.amount.total', label: '金額', width: 70 },
          { path: 'invoice.carrier.id1', label: '載具', width: 70 },
        ],
        deletable: false,
        copyable: false,
        appendEmpty: false,
      },
    },
    disabled: {
      key: true,
      status: true,
      amount: true,
      summary: true,
      receivable: true,
      'items.0.subtotal': true,
      'items.0.retailPrice': true,
      'items.0.undeliveredQty': true,
      'items.0.currentStockQty': true,
      'items.0.priceDoc.price': true,
    },
    noclone: {
      key: true,
      status: true,
      date: true,
      checkoutDate: true,
      paymentDueDate: true,
      note: true,
      summary: true,
      'items.0.salesReqItemRef': true,
    },
    Components: {
      PageForm: SalesOrderEditor,
    },
    populations: 'staffers,customers,warehouses,pricebooks,skus,salesReqs,salesReqItems',
    populationdepth: 2,
  },
  list: {
    ui: {
      fields: [
        'key',
        'date',
        { path: 'status', width: 30 },
        { path: 'personInCharge', width: 45 },
        { path: 'buyerRef', width: 150 },
        { path: 'warehouseRef', width: 30 },
        { path: 'summary', width: 50 },
        { path: 'amount', width: 50 },
        { path: 'receivable', width: 50 },
        'note',
      ],
    },
    populations: 'staffers,customers,stocks,warehouses',
  },
}
