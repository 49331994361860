const R = require('ramda')

const installModel = require('./db/installClient')
const installUI = require('./ui/installClient')


module.exports = R.pipe(
  installModel,
  installUI,
)
