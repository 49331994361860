const createWithItems = require('./createWithItems')
const updateWithItems = require('./updateWithItems')
const makeAssignItems = require('./assignItems')

module.exports = function setupPurchaseReqEntity ({ data }) {
  const my = { }

  async function createOrder (doc) {
    const orderId = await data.getId('order')
    await data.insertOrder(orderId, doc)
    return orderId
  }
  my.createOrder = createOrder

  async function updateOrder (doc) {
    const orderId = doc._id
    await data.updateOrder(orderId, doc)
    return orderId
  }
  my.updateOrder = updateOrder

  const assignItems = makeAssignItems({ data })
  my.assignItems = assignItems


  const deps = { data, my }

  return {
    getDocById: data.getDocById,
    getItemDoc: data.getItemDoc,
    getDocsByItemIds: data.getDocsByItemIds,
    getLinkedItemIds: data.getLinkedItemIds,
    mapReqOfItem: data.mapReqOfItem,
    createWithItems: createWithItems(deps),
    updateWithItems: updateWithItems(deps),
  }
}
