import compose from 'recompose/compose'
import withHandlers from 'recompose/withHandlers'

import View from './ProductsList'

const ProductsList = compose(
  withHandlers({
    chooseProduct: ({ getStocks, setCurSeries }) => (product) => {
      setCurSeries('')
      getStocks({ products: [product] })
    },
  }),
)(View)

ProductsList.displayName = 'ProductsList'

export default ProductsList
