import S from 'coact/saga/utils'

// import * as R from 'coact/ramda'

// import shallowEqual from 'coact/utils/shallowEqual'

import * as $editor from 'coact/store/editor'
// import * as $message from 'coact/store/message'
import * as $editorSaga from 'coact/saga/editorSaga'
import pageAct from 'coact/pages/modules/pageAction/act'


import actions from './actions'


function* fetchUndeliveredItems (customer) {
  const api = yield S.getContext('api')
  const { data: undeliveredItems } = yield S.call(
    api.get,
    {
      url: '/api/route/deliveryProcess',
      search: {
        findUndeliveredItems: null,
        query: {
          customerId: customer._id,
        },
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: '',
    value: {
      customer,
      undeliveredItems,
      show: 'undeliveredItems',
    },
  }))
}

function* findUndeliveredItems (payload) {
  const { value: customer } = payload

  yield S.call(fetchUndeliveredItems, customer)
}

function* fetchUndeliveredCustomers () {
  const api = yield S.getContext('api')
  const { data: undeliveredCustomers } = yield S.call(
    api.get,
    {
      url: '/api/route/deliveryProcess',
      search: {
        fetchUndeliveredCustomers: null,
      },
    },
  )

  yield S.put($editorSaga.act.editPath({
    path: '',
    value: {
      customer: null,
      undeliveredCustomers,
      undeliveredItems: null,
      show: 'undeliveredCustomers',
    },
  }))
}

function* clickCustomer (action) {
  const { payload: customer } = action

  yield S.put($editorSaga.act.editPath({
    path: 'customer',
    value: customer,
  }))

  yield S.call(fetchUndeliveredItems, customer)
}

function* createSalesOrder () {
  const { customer, undeliveredItems } = yield S.select($editor.select.value)

  const api = yield S.getContext('api')
  const { id } = yield S.call(
    api.post,
    {
      url: '/api/route/deliveryProcess',
      data: { customer, undeliveredItems },
    },
  )

  if (id) {
    const history = yield S.getContext('history')
    history.push(`/edit/salesOrders/view/main/${id}`)
  }
}

function* handleEffect ({ payload }) {
  const { name } = payload
  if (name === 'findUndeliveredItems') yield S.call(findUndeliveredItems, payload)
}

export default function* salesOrderCreatorSaga () {
  yield S.takeEvery(pageAct.everyEffect, handleEffect)
  yield S.takeFirst(actions.createSalesOrder, createSalesOrder)
  yield S.takeFirst(actions.fetchUndeliveredCustomers, fetchUndeliveredCustomers)
  yield S.takeFirst(actions.clickCustomer, clickCustomer)
}
