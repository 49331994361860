const R = require('ramda')

const fetchProductPrice = require('../../../../models/modules/fetchProductPrice')
const printExcel = require('../../../../models/modules/printExcel')

function round (value, precision) {
  const multiplier = 10 ** (precision || 0)
  return Math.round(value * multiplier) / multiplier
}

// handle product price
async function findProductPrice ({ value: skuDoc, path }, ctx) {
  const doc = ctx.getDoc()
  const { pricebookRef: pricebookDoc } = doc

  const { retailPriceDoc, bookPriceDoc } = await fetchProductPrice({ skuDoc, pricebookDoc }, ctx)

  const retailPrice = R.prop('price', retailPriceDoc)
  // TODO rename priceDoc to bookPriceDoc
  const priceDoc = bookPriceDoc

  const discount = R.pathOr(1, ['discount'], doc)
  const bookPrice = R.propOr(0, 'price', bookPriceDoc)
  const price = round(bookPrice * discount, 2)

  const itemPath = R.dropLast(1, path)
  ctx.mergePath({ path: itemPath, value: { priceDoc, retailPrice, price } })
}
function changePricebook (spec, ctx) {
  const items = R.path(['items'], ctx.getDoc())

  // TODO fetch prices in one api.get
  ctx.resetPath({ path: ['items'], value: items })
}

module.exports = {
  watcher: {
    'items.0.skuRef': { name: 'findProductPrice' },
    pricebookRef: { name: 'changePricebook' },
  },
  actions: {
    downloadExcel: {
      text: '列印',
      effect: { name: 'downloadExcel' },
      props: { variant: 'outlined', color: 'primary' },
    },
    completeOrder: {
      text: '交易完成',
      effect: { name: 'completeOrder' },
      props: { variant: 'contained', color: 'primary' },
    },
    cancelOrder: {
      text: '交易取消',
      effect: { name: 'cancelOrder' },
    },
    saveAndCopy: {
      authChecker: () => null,
    },
  },
  effect: {
    findProductPrice,
    changePricebook,
    downloadExcel: (s, ctx) => {
      const { doc } = ctx
      const { key, sellerRef } = doc
      const filename = `${key}_${sellerRef.key}.xlsx`
      printExcel({ doc, filename, template: 'purchaseOrderForm' })
    },
    completeOrder: (s, ctx) => {
      ctx.editPath({ path: ['status'], value: '完成' })
      ctx.saveDoc()
    },
    cancelOrder: (s, ctx) => {
      // eslint-disable-next-line no-alert
      if (window.confirm('確定要取消進貨單嗎？')) {
        ctx.editPath({ path: ['status'], value: '取消' })
        ctx.saveDoc()
      }
    },
  },
}
