const R = require('ramda')
const mergeDeep = require('coact/utils/mergeDeep')
const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const { reqData } = require('../../../purchaseReq')
const $arrivalProcessData = require('../../../arrivalProcess/dataAccess')

const schema = mergeDeep(
  R.pipe(
    R.assocPath(
      toSchemaPath('items.0.unarrivedQty'),
      $arrivalProcessData.schema.unarrivedQty,
    ),
    R.assocPath(
      toSchemaPath('items.0.onTheWayQty'),
      $arrivalProcessData.schema.onTheWayQty,
    ),
  )({ }),
  reqData.schema,
)

module.exports = schema
