import React from 'react'
import { any, array, string, number, object, func, bool } from 'prop-types'

import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'

import Close from '@material-ui/icons/Close'

import { range, sum } from 'coact/ramda'

const SIZE_COLS = range(0, 12)

function Cell (props) {
  const { children, header, style, ...others } = props
  const styles = {
    ...style,
    border: '1px solid gray',
    width: 'fit-content',
    textAlign: 'center',
    padding: 3,
    backgroundColor: header ? '#EAF2D3' : 'none',
  }
  return header
    ? <th style={styles} {...others}>{children}</th>
    : <td style={styles} {...others}>{children}</td>
}
Cell.propTypes = {
  children: any,
  header: bool,
}

const Row = withStyles({
  root: {
    '&:hover': {
      backgroundColor: '#FFCCFF',
    },
  },
})(TableRow)

function ImageModal (props) {
  const { imageModal, hideModal } = props
  return !imageModal ? null : (
    <Modal open={!!imageModal} onClose={hideModal}>
      <div style={{ width: 600, maxWidth: '100%', margin: '30px auto', backgroundColor: 'white' }}>
        <div style={{ display: 'flex' }}>
          <Typography variant="title" style={{ flexGrow: 1 }}>{imageModal}</Typography>
          <Close style={{ cursor: 'pointer' }} onClick={hideModal} />
        </div>
        <Divider />
        <img
          src={`http://www.hehong.com.tw/stock_img_no_space/${imageModal}.jpg`}
          alt="商品圖片整理中......"
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  )
}
ImageModal.propTypes = {
  imageModal: string,
  hideModal: func.isRequired,
}

function Header ({ sizeMap }) {
  return (
    <tr>
      <Cell header>型號</Cell>
      <Cell header>中文品名</Cell>
      <Cell header style={{ minWidth: 40 }}>類別</Cell>
      <Cell header style={{ minWidth: 40 }}>Col.</Cell>
      <Cell header style={{ minWidth: 40 }}>顏色</Cell>
      <Cell header style={{ width: 60 }}>圖片</Cell>
      {SIZE_COLS.map(index => (
        (<Cell header key={index} style={{ minWidth: 40 }}>{sizeMap[index] || ''}</Cell>)
      ))}
      <Cell header style={{ minWidth: 40 }}>小計</Cell>
      <Cell header style={{ minWidth: 40 }}>總計</Cell>
      <Cell header style={{ width: 90 }}>建議售價</Cell>
    </tr>
  )
}
Header.propTypes = {
  sizeMap: object.isRequired,
}

function FullRow (props) {
  const {
    productKey,
    name, pdtType, color, colorName, sizeMap, stocks, subtotal, total, price,
    showModal,
    rowSpan,
  } = props
  const imgName = productKey + (color ? `-${color}` : '')
  return (
    <Row>
      <Cell rowSpan={rowSpan}>{productKey}</Cell>
      <Cell rowSpan={rowSpan}>{name}</Cell>
      <Cell rowSpan={rowSpan}>{pdtType}</Cell>
      <Cell>{color}</Cell>
      <Cell>{colorName}</Cell>
      <Cell><button type="button" onClick={() => showModal(imgName)}>圖片</button></Cell>
      {SIZE_COLS.map(index => (
        <Cell key={index}>{sizeMap[index] && stocks[sizeMap[index]]}</Cell>
      ))}
      <Cell>{subtotal}</Cell>
      <Cell rowSpan={rowSpan}>{total}</Cell>
      <Cell rowSpan={rowSpan}>{price}</Cell>
    </Row>
  )
}
FullRow.propTypes = {
  rowSpan: number.isRequired,
  productKey: string.isRequired,
  name: string,
  pdtType: string,
  color: string.isRequired,
  colorName: string,
  showModal: func.isRequired,
  sizeMap: object.isRequired,
  stocks: object,
  subtotal: number,
  total: number,
  price: number,
}

function ShortRow (props) {
  const { productKey, color, colorName, sizeMap, stocks, subtotal, showModal } = props
  return (
    <Row>
      <Cell>{color}</Cell>
      <Cell>{colorName}</Cell>
      <Cell>
        <button type="button" onClick={() => showModal(`${productKey}-${color}`)}>圖片</button>
      </Cell>
      {SIZE_COLS.map(index => (
        <Cell key={index}>{sizeMap[index] && stocks[sizeMap[index]]}</Cell>
      ))}
      <Cell>{subtotal}</Cell>
    </Row>
  )
}
ShortRow.propTypes = {
  productKey: string.isRequired,
  color: string.isRequired,
  colorName: string,
  sizeMap: object.isRequired,
  stocks: object,
  subtotal: number,
  showModal: func.isRequired,
}

function notFound (product, searchOri) {
  const search = searchOri.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const re = new RegExp(search, 'i')
  const { productKey, name, pdtType, color, colorName, price } = product
  if (re.test(productKey || '')) return false
  if (re.test(name || '')) return false
  if (re.test(pdtType || '')) return false
  if (re.test(color || '')) return false
  if (re.test(colorName || '')) return false
  if (re.test(price)) return false
  return true
}

function StocksTable (props) {
  const { stocksData, showModal, search, hideEmpty } = props
  return (
    <div>
      <ImageModal {...props} />
      <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
        <tbody>
          {stocksData.map((product, pIdx) => {
            const { groupByColor } = product
            const emptyColors = Object.values(groupByColor).filter(c => c.subtotal <= 0)
            const rowsEmpty = emptyColors.length
            const rowSpan = Object.keys(groupByColor).length - (hideEmpty ? rowsEmpty : 0)
            const total = product.total - (hideEmpty ? sum(emptyColors.map(c => c.subtotal)) : 0)
            let emptyIdx = 0

            if (rowSpan === 0) return null

            return Object.keys(product.groupByColor).map((color, cIdx) => {
              if (hideEmpty && groupByColor[color].subtotal <= 0) {
                emptyIdx += 1
                return null
              }
              if ((search !== null) && notFound(product, search)) return null
              return (
                (cIdx - emptyIdx)
                  ? (
                    <ShortRow
                      {...{ showModal, ...product, ...groupByColor[color] }}
                      key={`${pIdx}-${cIdx}-s`}
                    />
                  )
                  : (
                    <React.Fragment key={`${pIdx}-${cIdx}-f`}>
                      <Header sizeMap={product.sizeMap} />
                      <FullRow
                        {...{ showModal, ...product, ...groupByColor[color] }}
                        rowSpan={rowSpan}
                        total={total}
                      />
                    </React.Fragment>
                  )
              )
            })
          })}
        </tbody>
      </table>
    </div>
  )
}
StocksTable.propTypes = {
  stocksData: array.isRequired,
  showModal: func.isRequired,
  search: string,
  hideEmpty: bool.isRequired,
}

export default StocksTable
