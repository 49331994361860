module.exports = function setupPurchaseOrderEntity ({ data }) {
  const deps = { data }
  const my = { }

  async function updateOrder (orderId, doc) {
    const savedDoc = await data.updateOrder(orderId, doc)
    return savedDoc
  }

  async function getOrderAndItems (orderId) {
    return data.getOrderAndItems(orderId)
  }

  async function getItemDocs (itemIds) {
    return data.getItemDocs(itemIds)
  }

  async function sumQtyByStatus (itemIds) {
    return data.sumQtyByStatus(itemIds)
  }

  async function getLinkedItemIds (orderId) {
    return data.getLinkedItemIds(orderId)
  }

  const createOrder = require('./createOrder')(deps, my)
  const runComputer = require('./runComputer')(deps, my)
  const assignItems = require('./assignItems')(deps, my)
  const appendItems = require('./appendItems')(deps, my)
  const complementDoc = require('./complementDoc')(deps, my)
  const createWithItems = require('./createWithItems')(deps, my)
  const updateWithItems = require('./updateWithItems')(deps, my)

  my.createOrder = createOrder
  my.updateOrder = updateOrder
  my.getOrderAndItems = getOrderAndItems
  my.runComputer = runComputer
  my.assignItems = assignItems
  my.appendItems = appendItems
  my.complementDoc = complementDoc
  my.createWithItems = createWithItems
  my.updateWithItems = updateWithItems

  return {
    getOrderAndItems,
    getItemDocs,
    sumQtyByStatus,
    getLinkedItemIds,
    createOrder,
    appendItems,
    createWithItems,
    updateWithItems,
  }
}
