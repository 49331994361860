import compose from 'recompose/compose'

import View from './FilterList'

const FilterList = compose(
)(View)

FilterList.displayName = 'FilterList'

export default FilterList
