const R = require('ramda')

const computer = {
  '': (doc) => {
    const items = R.pathOr([], ['invoice', 'items'], doc)
    const total = R.sum(R.map(R.prop('amount'), items))
    return { invoice: { amount: { total } } }
  },
  'invoice.items.0': (item) => {
    const qty = item.quantity || 0
    const price = item.price || 0

    return { amount: Math.round(qty * price) }
  },
}

module.exports = {
  computer,
}
