const R = require('coact/ramda')

const fetchSkuPrices = require('~/src/models/modules/fetchSkuPrices')

module.exports = async function findProductPrice ({ value: skuRef, path }, ctx) {
  const itemPath = R.dropLast(2, path)
  const doc = ctx.getDoc()

  try {
    if (!skuRef) {
      // ctx.sendMessage({ type: 'error', message: '請填入商品' })
      throw new Error('Price Not Found')
    }

    const pricebookRef = R.path(['pricebook', 'id'], doc)
    if (!pricebookRef) {
      // ctx.sendMessage({ type: 'error', message: '請填入價格表' })
      throw new Error('Price Not Found')
    }

    const prices = await fetchSkuPrices({ skuRef }, ctx)

    let bookPrice = null
    let price = null
    prices.forEach((p) => {
      if (p.pricebookRef._id === pricebookRef._id) bookPrice = p
    })

    if (bookPrice === null) {
      ctx.sendMessage({ type: 'error', message: `查無 ${pricebookRef.key}` })
    } else {
      price = bookPrice.price
    }

    ctx.mergePath({ path: itemPath, value: { bookPrice, price } })
  } catch (error) {
    if (error.message === 'Price Not Found') {
      ctx.mergePath({
        path: itemPath,
        value: { price: null, bookPrice: null },
      })
      return
    }
    throw error
  }
}
