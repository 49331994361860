const R = require('ramda')

const mergeDeep = require('coact/utils/mergeDeep')

const getDefaultOrderDoc = require('./getDefaultOrderDoc')

module.exports = (deps, my) => async function createOrder (inputDoc) {
  const { data } = deps

  const defaultOrderDoc = await getDefaultOrderDoc()

  const orderDoc = await R.pipe(
    mergeDeep(defaultOrderDoc),
    my.complementDoc,
  )(inputDoc)

  const orderId = await data.getId('order')
  const savedDoc = await data.insertOrder(orderId, orderDoc)

  return savedDoc
}
