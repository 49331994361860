import React from 'react'
import { bool } from 'prop-types'

// import { Modal, CircularProgress, withStyles } from 'coact/@material-ui/core'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

const Progress = withStyles({
  root: {
    color: 'white',
  },
})(CircularProgress)

function LoadingIcon ({ open }) {
  const styleDiv = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '50%',
    position: 'relative',
  }
  return (
    <Modal open={open} disableBackdropClick disableAutoFocus>
      <div style={styleDiv}>
        <Progress style={{ margin: 'auto' }} />
      </div>
    </Modal>
  )
}
LoadingIcon.propTypes = {
  open: bool.isRequired,
}
export default LoadingIcon
