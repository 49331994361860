import React from 'react'
import PropTypes from 'prop-types'

import FormEditor from 'coact/editors/FormEditor'
import TableEditor from 'coact/editors/TableEditor'
import ProductReader from 'coact/components/ProductReader'

function OrderEditor (props) {
  const { page: { schema, ui } } = props

  return (
    <React.Fragment>
      <FormEditor ui={ui.order} schema={schema} />
      <ProductReader {...ui.productReader} />
      <TableEditor ui={ui.items} schema={schema} />
    </React.Fragment>
  )
}

const { object } = PropTypes
OrderEditor.propTypes = {
  page: object.isRequired,
}


export default OrderEditor
