const mergeConfig = require('coact/config/mergeConfig')

const clientConfig = {
  title: '消費者資料',
  list: {
    ui: {
      fields: [
        { path: 'name', width: 120 },
        { path: 'phone', width: 120 },
        { path: 'mobileBarcode', width: 120 },
        { path: 'secPhone', width: 120 },
        { path: 'email', width: 200 },
      ],
    },
  },
}

const config = {
  models: { consumers: clientConfig },
}

module.exports = fullConfig => mergeConfig(fullConfig, config)
