const R = require('coact/ramda')

const mergeConfig = require('coact/config/mergeConfig')
const stockRecorderModule = require('coact/modules/stockRecorder/main')

const salesOrders = require('./salesOrders')
const salesOrderItems = require('./salesOrderItems')


const changeStockSpec = {
  db: {
    stocks: 'stocks',
    records: 'stockRecords',
    orders: 'salesOrders',
    items: 'salesOrderItems',
  },
  payload: {
    stock: {
      src: ({ doc, item }) => {
        if (!item.skuRef) return null
        if (!doc.warehouseRef) return null
        if (doc.status !== '完成') return null
        return { warehouseRef: doc.warehouseRef._id, skuRef: item.skuRef._id }
      },
      des: () => null,
    },
    record: {
      src: ({ doc, item }) => ({
        qty: -item.qty,
        date: doc.date,
        content: '銷貨出庫',
      }),
      des: () => null,
    },
  },
  refPath: {
    item: {
      record: 'stockRecordRefs',
    },
    record: {
      stock: 'stockRef',
      item: 'srcDBRef',
    },
  },
}

const models = {
  salesOrders: {
    ...salesOrders,
    views: {
      main: { },
    },
  },
  salesOrderItems,
}


const fullModels = R.reduce(
  mergeConfig,
  { },
  [
    // tradingFormModule(salesOrderSpec),
    stockRecorderModule(changeStockSpec),
    { models },
  ],
)

module.exports = fullModels
