const R = require('ramda')

const buildSkuName = require('../../modules/buildSkuName')


module.exports = {
  '': [
    {
      src: 'buyerRef',
      fill: (buyerRef) => {
        const buyer = R.pick(
          ['key', 'contact', 'phone', 'fax', 'email', 'address'],
          buyerRef,
        )

        return { buyer }
      },
    },
  ],
  'items.0': {
    src: 'skuRef',
    fill: sku => ({
      sku,
      name: buildSkuName(sku),
    }),
  },
}
