const errors = require('http-errors')

module.exports = deps => async function checkUniqueFields (doc) {
  const { modelviews, user } = deps

  const model = modelviews.model('consumers', user)

  const { phone, mobileBarcode } = doc
  if (phone) {
    const docs = await model.select({ phone })
    if (docs.length !== 0) {
      throw new errors.NotAcceptable(`手機 ${phone} 已存在`)
    }
  }
  if (mobileBarcode) {
    const docs = await model.select({ mobileBarcode })
    if (docs.length !== 0) {
      throw new errors.NotAcceptable(`手機載具 ${mobileBarcode} 已存在`)
    }
  }
}
