async function getBody (response) {
  const text = await response.text()

  try {
    return JSON.stringify(JSON.parse(text))
  } catch (error) {
    return text
  }
}

module.exports = async function invalidInvoice (invoiceId) {
  const res = await fetch(
    `api/route/invoice?invoiceId=${invoiceId}&action=invalid`,
    {
      method: 'POST',
    },
  )

  if (res.ok) return res.json()
  throw new Error(await getBody(res))
}
