const { createAct } = require('coact/utils/action')


module.exports = createAct({
  namespace: ['hehong', 'ArrivalProcess'],
  types: [
    'findUnarrivedItems',
    'findSuppliersInProgress',
    'exportInProgressList',
    'createPurchaseOrder',
    'clickSupplier',
  ],
})
