/**
 * [spec doc](/docs/based-data/customers-data.mkd)
 */

module.exports = {
  title: '客戶資料',
  schema: {
    type: 'Object',
    contents: {
      key: {
        name: '客戶名稱',
        type: 'String',
        unique: true,
        required: true,
      },
      code: { name: '客戶代號', type: 'String' },
      group: {
        name: '群組',
        type: 'String',
        options: [
          '台灣車店',
          '中國車店',
          '韻律森活',
          '網路經銷',
          '戶外用品店',
          '工作室',
          '大使',
          '直客',
          '海外車店',
          '網路直客',
          '特賣會',
          '批發商',
          '公司使用',
          '行銷用途',
          '書籍客戶',
          '其他',
          '訂製',
        ],
        // required: true,
      },
      contact: { name: '聯絡人', type: 'String' },
      phone: { name: '電話', type: 'String' },
      secPhone: { name: '備用電話', type: 'String' },
      fax: { name: '傳真', type: 'String' },
      email: { name: 'Email', type: 'String' },
      zipCode: { name: '郵遞區號', type: 'String' },
      address: { name: '地址', type: 'String' },
      deliveryZip: { name: '發貨郵遞區號', type: 'String' },
      deliveryAddress: { name: '發貨地址', type: 'String' },
      closedInfo: { name: '店休日', type: 'String' },
      note: { name: '備註', type: 'String', inputType: 'textarea' },
      invoiceTitle: { name: '發票抬頭', type: 'String' },
      taxId: { name: '統編', type: 'String' },
      bankAccount: { name: '銀行帳戶', type: 'String', input: 'textarea' },
      paymentMethod: { name: '慣用付款方式', type: 'String' },
    },
  },
  index: {
    primary: {
      key: {
        key: 1,
      },
      unique: true,
    },
  },
  edit: {
    ui: {
      form: {
        name: 'formEditor',
        // cols: { default: 500 },
        fields: [
          ['key', 'code', 'group'],
          ['contact', 'phone', 'secPhone', 'fax'],
          [{ path: 'email', span: 3 }],
          ['zipCode', { path: 'address', span: 3 }],
          ['deliveryZip', { path: 'deliveryAddress', span: 3 }],
          ['closedInfo', { path: 'note', span: 3 }],
          ['invoiceTitle', 'taxId'],
          ['bankAccount', 'paymentMethod'],
        ],
      },
    },
  },
  list: {
    ui: {
      fields: [
        'key', 'group', 'contact', 'phone', 'address', 'note',
      ],
    },
  },
}
