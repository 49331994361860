import compose from 'recompose/compose'
import withState from 'recompose/withState'
import withProps from 'recompose/withProps'
import withHandlers from 'recompose/withHandlers'

import View from './StocksTable'


const StocksTable = compose(
  withState('imageModal', 'setImageModal', null),
  withHandlers({
    showModal: ({ setImageModal }) => imageName => setImageModal(imageName),
    hideModal: ({ setImageModal }) => () => setImageModal(null),
  }),
  withProps({
    productsData: [],
  }),
)(View)

StocksTable.displayName = 'StocksTable'

export default StocksTable
