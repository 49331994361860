const R = require('ramda')

module.exports = (deps, my) => async function appendItems (orderId, items) {
  const { data } = deps

  const itemIds = await Promise.all(R.map(
    async (item) => {
      const itemId = await data.getId('item')
      await data.insertItem(itemId, item)
      return itemId
    },
    items,
  ))

  await data.concatItemIds(orderId, itemIds)

  // run computer
  await my.runComputer(orderId)

  return itemIds
}
