const schema = {
  type: 'Object',
  contents: {
    key: { // no white space between parts
      label: '商品型號',
      type: 'String',
      unique: true,
      required: true,
      disabled: true,
    },
    formattedKey: {
      label: '顯示型號',
      type: 'String',
      unique: true,
      required: true,
      ragicField: 1000007,
    },
    keyOfSupplierSide: {
      label: '供應端商品碼',
      unique: true,
      type: 'String',
    },
    name: {
      label: '商品名稱',
      type: 'String',
      unique: true,
      required: true,
      ragicField: 1000008,
    },
    pdtSerieRef: {
      label: '系列',
      type: 'ObjectId',
      required: true,
      reference: 'pdtSeries',
      refKeys: ['key'], // TODO add code?
      ragicField: 1000149,
    },
    pdtTypeRef: {
      label: '類別',
      type: 'ObjectId',
      required: true,
      reference: 'pdtTypes',
      refKeys: ['key'], // TODO add code?
      ragicField: 1000018,
    },
    supplierRef: {
      label: '供應商',
      type: 'ObjectId',
      reference: 'suppliers',
      refKeys: ['key', 'code'],
    },
    circulationLevel: {
      label: '流通等級',
      type: 'String',
      // required: true,
      options: ['A', 'B', 'C', 'D'],
      ragicField: 1000254,
    },
    weightRatio: {
      label: '運費分攤比',
      type: 'Number',
      ragicField: 1000013,
    },
    description: {
      label: '商品簡介',
      type: 'String',
      input: 'textarea',
      ragicField: 1000015,
    },
    '@suspended': {
      type: 'Boolean', label: '停用',
    },
    syncAt: {
      label: '上次同步時間',
      type: 'Date',
      format: 'YYYY/MM/DD HH:mm:ss',
      disabled: true,
    },
    ragicId: {
      label: 'Ragic Id',
      type: 'Number',
      ragicField: '_ragicId',
      disabled: true,
    },
  },
}

module.exports = schema
