import { compose, withProps, withHandlers } from 'recompose'

import { connect } from 'react-redux'

// import * as R from 'coact/ramda'

import mergeDeep from 'coact/utils/mergeDeep'

import salesReqItemModel from '../../models/salesReqs/salesReqItems'

import actions from './actions'

import View from './SalesOrderCreator'


const schema = {
  type: 'Object',
  contents: {
    customer: {
      type: 'ObjectId',
      reference: 'customers',
      label: '出貨客戶',
      refKeys: ['key'],
      watcher: { name: 'findUndeliveredItems' },
    },
    undeliveredItems: {
      type: 'Array',
      contents: [
        mergeDeep(
          salesReqItemModel.schema,
          {
            contents: {
              currentStockQty: { type: 'Number' },
              toDeliverQty: { type: 'Number', label: '出貨數' },
            },
          },
        ),
      ],
    },
  },
}

const container = compose(
  connect(),

  withProps({ schema }),

  withHandlers({
    createSalesOrder: ({ dispatch }) => () => dispatch(actions.createSalesOrder()),
    fetchUndeliveredCustomers: ({ dispatch }) => () => (
      dispatch(actions.fetchUndeliveredCustomers())
    ),
    clickCustomer: ({ dispatch }) => customer => (
      dispatch(actions.clickCustomer(customer))
    ),
  }),
)

export default container(View)
