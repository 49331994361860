const { compose, withProps } = require('recompose')

const { connect } = require('react-redux')

// const R = require('ramda')

// const actions = require('../actions')

const schema = {
  type: 'Object',
  contents: {
    product: {
      type: 'ObjectId',
      reference: 'products',
      label: '商品型號',
      refKeys: ['key'],
      watcher: { name: 'queryReferenceData' },
    },
  },
}

const container = compose(
  connect(),

  withProps({ schema }),
)

module.exports = container
