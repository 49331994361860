import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'coact/ramda'

import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Editor from 'coact/editors/Editor'
import Field from 'coact/editors/Field'
import ItemsEditor from 'coact/editors/ItemsEditor'


function UnarrivedItems () {
  return (
    <table>
      <thead>
        <tr>
          <th style={{ width: 200 }}>單品</th>
          <th style={{ width: 300 }}>名稱</th>
          <th style={{ width: 100 }}>賣方單號</th>
          <th style={{ width: 80 }}>採購數</th>
          <th style={{ width: 80 }}>未到數</th>
          <th style={{ width: 80 }}>到貨數</th>
          <th style={{ width: 80 }}>單價</th>
          <th style={{ width: 80 }}>小計</th>
          <th style={{ width: 150 }}>項目備註</th>
          <th style={{ width: 150 }}>採購備註</th>
        </tr>
      </thead>
      <tbody>
        <ItemsEditor path="unarrivedItems">
          {(editor) => {
            const getter = R.path(R.__, editor.value)
            return (
              <tr>
                <td>{getter(['sku', 'key'])}</td>
                <td>{getter(['name'])}</td>
                <td>{getter(['keyOfSellerSide'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['qty'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['unarrivedQty'])}</td>
                <td><Field path="arrivalQty" /></td>
                <td style={{ textAlign: 'center' }}>{getter(['price'])}</td>
                <td style={{ textAlign: 'center' }}>{getter(['subtotal'])}</td>
                <td>{getter(['itemNote'])}</td>
                <td>{getter(['note'])}</td>
              </tr>
            )
          }}
        </ItemsEditor>
      </tbody>
    </table>
  )
}

function SupplierList (props) {
  const { data, clickSupplier } = props
  return (
    <div>
      <List component="nav">
        {
          data.map(supplier => (
            <ListItem key={supplier.key} button onClick={() => clickSupplier(supplier)}>
              <ListItemText primary={supplier.key} />
            </ListItem>
          ))
        }
      </List>
    </div>
  )
}
const { array, object, func } = PropTypes
SupplierList.propTypes = {
  data: array.isRequired,
  clickSupplier: func.isRequired,
}

function ArrivalProcessPage (props) {
  const { schema, createPurchaseOrder, findSuppliersInProgress, exportInProgressList } = props
  return (
    <Editor schema={schema}>
      {(editor) => {
        const { show, suppliersInProgress } = editor.value
        return (
          <React.Fragment>
            <div>
              <Field path="supplier" />
            </div>
            <div>
              <Field path="orderToAppend" />
            </div>
            {
              show === 'unarrivedItems'
                ? <UnarrivedItems />
                : null
            }
            {
              show === 'suppliersInProgress'
                ? <SupplierList data={suppliersInProgress} {...props} />
                : null
            }
            <Button
              variant="contained"
              color="primary"
              onClick={createPurchaseOrder}
            >
              產生進貨單
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={findSuppliersInProgress}
            >
              列出待貨供應商
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={exportInProgressList}
            >
              輸出待貨清單到 Sheets
            </Button>
          </React.Fragment>
        )
      }}
    </Editor>
  )
}
ArrivalProcessPage.propTypes = {
  schema: object.isRequired,
  createPurchaseOrder: func.isRequired,
  findSuppliersInProgress: func.isRequired,
  exportInProgressList: func.isRequired,
}

export default ArrivalProcessPage
