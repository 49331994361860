const moment = require('moment')

const mergeSystemFields = require('../../utils/schema/mergeSystemFields')

const discountParser = require('../modules/discountParser')


const schema = mergeSystemFields({
  type: 'Object',
  contents: {
    key: {
      type: 'String',
      label: '銷貨單號',
      counter: {
        key: () => `SO${moment().format('YYMMDD')}`,
        value: (key, value) => `${key}${value.toString().padStart(3, 0)}`,
      },
    },
    date: { type: 'Date', label: '日期' },
    status: { type: 'String', label: '狀態', options: ['新增', '完成', '取消'] },
    checkoutDate: { type: 'Date', label: '結帳日期' },
    paymentDueDate: { type: 'Date', label: '付款截止日' },
    personInCharge: {
      type: 'ObjectId',
      label: '負責人',
      reference: 'staffers',
      refKeys: ['key'],
      // required: true,
    },
    buyerRef: {
      type: 'ObjectId',
      label: '客戶',
      reference: 'customers',
      refKeys: ['key'],
      initFocus: true,
    },
    buyer: {
      type: 'Object',
      contents: {
        key: { type: 'String', label: '名稱' },
        contact: { type: 'String', label: '聯絡人' },
        phone: { type: 'String', label: '電話' },
        email: { type: 'String', label: 'Email' },
        fax: { type: 'String', label: '傳真' },
        address: { type: 'String', label: '地址' },
      },
    },
    warehouseRef: {
      type: 'ObjectId',
      reference: 'warehouses',
      refKeys: ['key'],
      label: '出庫',
      // required: true,
      autoSelect: 'HH',
    },
    warehouse: { type: 'Object' },
    pricebookRef: {
      type: 'ObjectId',
      reference: 'pricebooks',
      refKeys: ['key'],
      label: '使用價格表',
      // required: true,
    },
    discount: {
      type: 'Number',
      label: '價格折扣',
      format: '0%',
      // required: true,
      parser: discountParser,
    },
    amount: { type: 'Number', label: '總金額' },
    summary: { type: 'String', label: '總數量' },
    receivable: { type: 'Number', label: '應收', disabled: true },
    note: { type: 'String', label: '備註', multiline: true },
    items: {
      type: 'Array',
      label: '項目',
      contents: [{ type: 'ObjectId', reference: 'salesOrderItems' }],
    },
    payments: {
      type: 'Array',
      label: '款項',
      contents: [{ type: 'ObjectId', reference: 'receivables' }],
    },

    buyerId: { type: 'String', label: '發票統編' },
    mobileBarcode: { type: 'String', label: '手機載具號碼' },
    donation: { type: 'String', label: '捐贈發票碼' },
    citizenBarcode: { type: 'String', label: '自然人憑證載具' },
    descriptionOfInvoice: { type: 'String', label: '發票品項' },
  },
})

const index = {
  buyerRef: {
    key: { buyerRef: 1 },
  },
  note: {
    key: { note: 1 },
  },
}

module.exports = {
  title: '銷貨單',
  schema,
  index,
}
