const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    staffers: {
      schema: require('./staffers/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
