import React from 'react'
import PropTypes from 'prop-types'

// import * as R from 'ramda'

import FormEditor from 'coact/editors/FormEditor'
import TableEditor from 'coact/editors/TableEditor'
import PageActions from 'coact/pages/EditPage/PageActions'
// import * as $editorSaga from 'coact/saga/editorSaga'
// import createEffectAction from 'coact/pages/modules/pageAction/createEffectAction'
// import Editor from 'coact/editors/Editor'

// import toPath from 'coact/utils/toPath'

// import Button from '@material-ui/core/Button'
// import FormControl from '@material-ui/core/FormControl'
// import RadioGroup from '@material-ui/core/RadioGroup'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Radio from '@material-ui/core/Radio'


// const { object, func } = PropTypes
const { object } = PropTypes

function Issuer (props) {
  // const { page: { schema, ui }, dispatch } = props
  const { page: { schema, ui } } = props

  // const invalidInvoice = id => (
  //   dispatch(createEffectAction({ name: 'invalidInvoice', id }))
  // )

  return (
    <React.Fragment>
      <link href="https://fonts.googleapis.com/css?family=Libre+Barcode+39&display=swap" rel="stylesheet" />
      <div><PageActions {...props} /></div>
      <div style={{ display: 'inline-block' }}>
        <FormEditor ui={ui.main} schema={schema} />
        <TableEditor ui={ui.items} schema={schema} />
        <div style={{ fontFamily: '\'Libre Barcode 39\', cursive', fontSize: 1, color: 'white' }}>
          *prefetch*
        </div>
        <div><img style={{ width: 1, height: 1 }} src="invoiceLogo.png" alt="禾宏文化" /></div>
      </div>
    </React.Fragment>
  )
}

Issuer.propTypes = {
  page: object.isRequired,
  // dispatch: func.isRequired,
}


export default Issuer
