const mergeConfig = require('coact/config/mergeConfig')
const salesOrdersConfig = require('./salesOrders/salesOrders')
const salesReqsConfig = require('./salesReqs/salesReqs')

const models = {
  salesReqs: {
    views: {
      main: require('./salesReqs/mainView'),
    },
  },
  salesOrders: {
    views: {
      main: require('./salesOrders/mainView'),
    },
  },
  recStatements: {
    views: {
      main: require('./recStatements/mainView'),
    },
  },
  salesOrderItems: {
    schema: {
      contents: {
        orderRef: salesOrdersConfig.schema,
      },
    },
  },
  salesReqItems: {
    schema: {
      contents: {
        orderRef: salesReqsConfig.schema,
      },
    },
  },
}

module.exports = mergeConfig(require('./'), models)
